import styled, { css } from 'styled-components'

export const HeaderLayout = styled.header<{
  $isHeaderHovered: boolean
  $borderColor: string
}>`
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: calc(100% - 4rem);
  padding: 0px 20px;
  height: 80px;
  z-index: 10;
  border-bottom: 1px solid ${({ $borderColor, $isHeaderHovered }) => ($isHeaderHovered ? 'unset' : $borderColor)};

  .header_content_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;
    width: 100%;
    max-width: 1280px;
    height: 100%;
  }
  @media ${({ theme }) => theme.device.pc} {
    border-bottom: unset;
  }

  @media ${({ theme }) => theme.device.mobile} {
    height: 54px;
    width: 100vw;
    .header_content_box {
      padding: 0;
    }
  }
`

export const HeaderNav = styled.nav<{
  $isHeaderHovered: boolean
  $textColor: string
}>`
  width: 100%;
  /* max-width: 620px; */
  width: ${({ $isHeaderHovered }) => ($isHeaderHovered ? '840px' : '620px')};
  transition: width 0.3s ease; /* 너비 변화에 대한 transition 설정 */
  height: 100%;
  color: ${({ $textColor, $isHeaderHovered, theme }) => ($isHeaderHovered ? theme.wev_color.text : $textColor)};
  > ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    height: 100%;
    ${({ theme }) => theme.wev_font.sBold_16};
  }
`
export const NavItem = styled.li<{
  $isHeaderHovered: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: default;
  position: relative;
  padding-bottom: 3px;
  text-align: center;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: ${({ $isHeaderHovered, theme }) => ($isHeaderHovered ? theme.wev_color.primary : 'transparent')};
    transition: background-color 0.3s ease;
  }
`

export const AuthMenu = styled.div<{ isTablet: boolean; $textColor?: string; $isHeaderHovered?: boolean }>`
  width: 130px;
  display: flex;
  gap: 5px;
  color: ${({ theme, isTablet, $isHeaderHovered, $textColor }) =>
    isTablet ? 'rgba(255, 255, 255, 0.50)' : $isHeaderHovered ? theme.wev_color.text : $textColor};
  white-space: nowrap;
  ${({ theme }) => theme.wev_font.sBold_16};
  > a:hover {
    text-decoration: underline;
    text-decoration-thickness: 1.5px;
    text-underline-offset: 3px;
    text-decoration-color: inherit;
  }
`
