import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { paymentApis } from '@src/api/management/payment'
import { IKakaoDetailData } from '@src/type/management/kakaoPaymentHistory'

const initialState = {
  kakaoPaymentHistoryDetail: {} as IKakaoDetailData,
}
export const kakaoPaymentHistorySlice = createSlice({
  name: 'kakaoPaymentHistory',
  initialState,
  reducers: {
    setKakaoPaymentHistoryDetail: (state, action) => {
      state.kakaoPaymentHistoryDetail = action.payload
    },
  },
})
export const { setKakaoPaymentHistoryDetail } = kakaoPaymentHistorySlice.actions

export default kakaoPaymentHistorySlice.reducer

// -------------------------------------- //

export const getKakaoPaymentHistoryDetail = createAsyncThunk(
  '/management/kakaoPaymentHistory/getKakaoPaymentHistoryDetail',
  async (data: string, thunkAPI) => {
    try {
      await paymentApis.getKakaoPaymentDetail(data).then((res) => {
        const data = res.data.result_data
        thunkAPI.dispatch(setKakaoPaymentHistoryDetail(data))
      })
    } catch (err: any) {}
  },
)
