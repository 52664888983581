import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { BusinessAuthApis } from '@src/api/business/auth'
import { ICorporateInfo, IRegisterAgreement } from '@src/type/business'

const initialState = {
  registerAgreement: [] as string[],
  corporateInfo: {} as ICorporateInfo,
}
export const BusinessJoinSlice = createSlice({
  name: 'businessJoin',
  initialState,
  reducers: {
    setRegisterAgreement: (state, action) => {
      state.registerAgreement = action.payload
    },
    setCorporateInfo: (state, action) => {
      state.corporateInfo = action.payload
    },
  },
})

export const { setRegisterAgreement, setCorporateInfo } = BusinessJoinSlice.actions

export default BusinessJoinSlice.reducer
