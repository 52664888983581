import instance from '@src/api/axios'
import { ILoginHistoryParams, ISingleClaimCharge, IUserChargeHistoryParams, IUserPaymentHistoryParams } from '../types'

export const userHistoryApis = {
  getChargeHistoryList: (data: IUserChargeHistoryParams) =>
    instance.get(
      `/user/charge/list?list_num=10&is_biz=false&p=${data.page}` +
        `${data.cardNumber ? `&card_number=${data.cardNumber}` : ''}` +
        `${data.start ? `&start_date_time=${data.start}` : ''}` +
        `${data.end ? `&end_date_time=${data.end}` : ''}` +
        `${data.end ? `&category=charging_end_date_time` : ''}`,
    ),
  getChargeHistoryUnit: (data: string) => instance.get(`/user/charge/list?transaction_id=${data}`),
  singleClaimCharge: (data: ISingleClaimCharge) => instance.post('/user/charge/claim-charge', data),

  getCheckPerClaim: () => instance.get(`/user/payment/pre-check-claim`),
  getPaymentHistoryList: (data: IUserPaymentHistoryParams) =>
    instance.get(
      `/user/payment/history?list_num=10&is_biz=false&p=${data.page}` +
        `${data.cardNumber ? `&membership_card=${data.cardNumber}` : ''}` +
        `${data.start ? `&start_date_time=${data.start}` : ''}` +
        `${data.end ? `&end_date_time=${data.end}` : ''}` +
        `${data.tid ? `&tid=${data.tid}` : ''}`,
    ),
  getPaymentHistoryDetail: (data: string) => instance.get(`/user/payment/select-history?id=${data}`),
  getPaymentHistoryUnit: (data: string) => instance.get(`/user/payment/history?transaction_id=${data}`),
  getLoginHistoryList: (data: ILoginHistoryParams) =>
    instance.get(
      `/user/login-log?list_num=10&p=${data.page}` +
        `${data.start ? `&startDateTime=${data.start}` : ''}` +
        `${data.end ? `&endDateTime=${data.end}` : ''}` +
        `${data.result ? `&result=${data.result}` : ''}`,
    ),
}
