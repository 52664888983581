import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { managementMemberApis } from '@src/api/management/member'
import { IMemberCopyList, IMemberList, IMemberSearchParam } from '@src/type/management/memberMain'
import { datePrintFormatDate } from '@src/utils/dateFormat'
import { convertUtcToZone } from '@src/utils/timeCalculator'

const initialState = {
  managementMemberList: {} as IMemberList,
  managementMemberCopyList: [] as IMemberCopyList[],
  managementMemberListFilter: {
    page: 1,
    list: 15,
    policy1: 0,
    policy2: 0,
    query: '',
    category: '',
    role: null,
    suspend: null,
  },
}
export const memberMainSlice = createSlice({
  name: 'memberMain',
  initialState,
  reducers: {
    setManagementMemberList: (state, action) => {
      state.managementMemberList = action.payload
    },
    setManagementMemberCopyList: (state, action) => {
      state.managementMemberCopyList = action.payload
    },
    setManagementMemberListFilter: (state, action) => {
      state.managementMemberListFilter = action.payload
    },
  },
})
export const { setManagementMemberList, setManagementMemberListFilter, setManagementMemberCopyList } =
  memberMainSlice.actions

export default memberMainSlice.reducer

// -------------------------------------- //

export const getMemberList = createAsyncThunk(
  '/menagement/main/getMemberListt',
  async (data: IMemberSearchParam, thunkAPI) => {
    try {
      await managementMemberApis.getMemberList(data).then((res) => {
        const data = res.data.result_data
        thunkAPI.dispatch(setManagementMemberList(data))

        let copy = []
        for (let i = 0; i < res.data?.result_data.records.length; i += 1) {
          copy.push({
            이름: res.data?.result_data.records[i]?.lastname + res.data?.result_data.records[i]?.firstName,
            이메일: res.data?.result_data.records[i]?.email,
            성별: Number(res.data?.result_data.records[i]?.gender) ? '여성' : '남성',
            생년월일: res.data?.result_data.records[i]?.birthday,
            휴대폰번호: res.data?.result_data.records[i]?.phoneNumber,
            가입타입: res.data?.result_data.records[i]?.registerType ? '카카오' : '자사',
            상태:
              res.data?.result_data.records[i]?.emailVerify &&
              (!res.data?.result_data.records[i]?.suspended ||
                String(res.data?.result_data.records[i]?.suspended) === '0') &&
              res.data?.result_data.records[i]?.loginFailCount < 5
                ? '정상'
                : !res.data?.result_data.records[i]?.emailVerify &&
                  res.data?.result_data.records[i]?.loginFailCount >= 5 &&
                  String(res.data?.result_data.records[i]?.suspended) === '1'
                ? '이메일 인증X, 계정잠김, 이용정지'
                : !res.data?.result_data.records[i]?.emailVerify &&
                  res.data?.result_data.records[i]?.loginFailCount >= 5 &&
                  (!res.data?.result_data.records[i]?.suspended ||
                    String(res.data?.result_data.records[i]?.suspended) === '0')
                ? '이메일 인증x, 계정잠김'
                : !res.data?.result_data.records[i]?.emailVerify &&
                  res.data?.result_data.records[i]?.loginFailCount < 5 &&
                  String(res.data?.result_data.records[i]?.suspended) === '1'
                ? '이메일 인증x, 이용정지'
                : res.data?.result_data.records[i]?.emailVerify &&
                  res.data?.result_data.records[i]?.loginFailCount >= 5 &&
                  String(res.data?.result_data.records[i]?.suspended) === '1'
                ? '계정잠김, 이용정지'
                : !res.data?.result_data.records[i]?.emailVerify &&
                  res.data?.result_data.records[i]?.loginFailCount < 5 &&
                  (!res.data?.result_data.records[i]?.suspended ||
                    String(res.data?.result_data.records[i]?.suspended) === '0')
                ? '이메일 인증x'
                : res.data?.result_data.records[i]?.emailVerify &&
                  res.data?.result_data.records[i]?.loginFailCount >= 5 &&
                  (!res.data?.result_data.records[i]?.suspended ||
                    String(res.data?.result_data.records[i]?.suspended) === '0')
                ? '계정잠김'
                : '이용정지',
            등급: res.data?.result_data.records[i]?.role,
            주소: res.data?.result_data.records[i]?.address + ' ' + res.data?.result_data.records[i]?.addressDetail,
            가입일자: `${convertUtcToZone(res.data?.result_data.records[i]?.registerDateTime)}`,
          })
        }
        thunkAPI.dispatch(setManagementMemberCopyList(copy))
      })
    } catch (err) {}
  },
)
