import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { managementMemberApis } from '@src/api/management/member'

import {
  IMemberActiveLogList,
  IMemberActiveLogParams,
  IMemberChargingHistoryList,
  IMemberChargingHistoryParams,
  IMemberLoginLogList,
  IMemberLoginLogParams,
  IMemberPaymentList,
  IMemberPaymentParams,
  IMemberPersionalizeSet,
  IMemberPointHistoryList,
  IMemberPointHistoryParams,
  IMemberSelectMember,
  IMemberSelectMembershipList,
  IMemberSelectParams,
} from '@src/type/management/selectMember'

const initialState = {
  loading: false,
  memberSelectMemberList: {} as IMemberSelectMember,
  memberSelectMemberShipList: {} as IMemberSelectMembershipList,
  memberChargingHistoryList: {} as IMemberChargingHistoryList,
  memberPaymentList: {} as IMemberPaymentList,
  memberPointHistoryList: {} as IMemberPointHistoryList,
  memberActiveLogList: {} as IMemberActiveLogList,
  memberSelectActiveLog: {},
  memberPersonalizeSet: [] as IMemberPersionalizeSet[],
  memberLoginLogList: {} as IMemberLoginLogList,
}

export const selectMemberSlice = createSlice({
  name: 'selectMember',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setMemberSelectMemberList: (state, action) => {
      state.memberSelectMemberList = action.payload
    },
    setMemberSelectMemberShipList: (state, action) => {
      state.memberSelectMemberShipList = action.payload
    },
    setMemberChargingHistoryList: (state, action) => {
      state.memberChargingHistoryList = action.payload
    },
    setMemberPaymentList: (state, action) => {
      state.memberPaymentList = action.payload
    },
    setMemberPointHistoryList: (state, action) => {
      state.memberPointHistoryList = action.payload
    },
    setMemberActiveLogList: (state, action) => {
      state.memberActiveLogList = action.payload
    },
    setMemberSelectActiveLog: (state, action) => {
      state.memberSelectActiveLog = action.payload
    },
    setMemberPersonalizeSet: (state, action) => {
      state.memberPersonalizeSet = action.payload
    },
    setMemberLoginLogList: (state, action) => {
      state.memberLoginLogList = action.payload
    },
  },
})
export const {
  setLoading,
  setMemberSelectMemberList,
  setMemberSelectMemberShipList,
  setMemberChargingHistoryList,
  setMemberPaymentList,
  setMemberPointHistoryList,
  setMemberActiveLogList,
  setMemberSelectActiveLog,
  setMemberPersonalizeSet,
  setMemberLoginLogList,
} = selectMemberSlice.actions

export default selectMemberSlice.reducer

// -------------------------------------- //
export const getMemberSelectList = createAsyncThunk(
  '/management/selectMember/getMemberSelectList',
  async (data: IMemberSelectParams, thunkAPI) => {
    try {
      await managementMemberApis.getMemberSelectV2(data).then((res) => {
        thunkAPI.dispatch(setMemberSelectMemberList(res.data.result_data.records.member))
        thunkAPI.dispatch(setMemberSelectMemberShipList(res.data.result_data.records.membership))
      })
    } catch (err) {}
  },
)

export const getMemberPaymentList = createAsyncThunk(
  '/management/selectMember/getMemberPayment',
  async (data: IMemberPaymentParams, thunkAPI) => {
    try {
      await managementMemberApis.getMemberPayment(data).then((res) => {
        const data = res.data.result_data
        thunkAPI.dispatch(setMemberPaymentList(data))
      })
    } catch (err) {}
  },
)
export const getMemberChargingHistoryList = createAsyncThunk(
  '/management/selectMember/getMemberChargingHistory',
  async (data: IMemberChargingHistoryParams, thunkAPI) => {
    try {
      await managementMemberApis.getMemberChargingHistory(data).then((res) => {
        const data = res.data.result_data
        thunkAPI.dispatch(setMemberChargingHistoryList(data))
      })
    } catch (err) {}
  },
)
export const getMemberPointHistoryList = createAsyncThunk(
  '/management/selectMember/getMemberPointHistory',
  async (data: IMemberPointHistoryParams, thunkAPI) => {
    try {
      await managementMemberApis.getMemberPointHistory(data).then((res) => {
        const data = res.data.result_data
        thunkAPI.dispatch(setMemberPointHistoryList(data))
      })
    } catch (err) {}
  },
)
export const getMemberActiveLogList = createAsyncThunk(
  '/management/selectMember/getMemberActiveLogList',
  async (data: IMemberActiveLogParams, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true))
      await managementMemberApis.getMemberActiveLog(data).then((res) => {
        const data = res.data.result_data
        thunkAPI.dispatch(setMemberActiveLogList(data))
      })
    } catch (err) {
    } finally {
      thunkAPI.dispatch(setLoading(false))
    }
  },
)

export const getMemberSelectActiveLog = createAsyncThunk(
  '/management/selectMember/getMemberSelectActiveLog',
  async (data: number, thunkAPI) => {
    try {
      await managementMemberApis.getMemberSelectActiveLog(data).then((res) => {
        const data = res.data.result_data
        thunkAPI.dispatch(setMemberSelectActiveLog(data))
      })
    } catch (err) {}
  },
)
export const getMemberPersonalizeSet = createAsyncThunk(
  '/management/selectMember/getMemberPersonalizeSet',
  async (data: number, thunkAPI) => {
    try {
      await managementMemberApis.getMemberPersonalizeSet(data).then((res) => {
        const data = res.data.result_data.filter((el: IMemberPersionalizeSet) => !el.data_key.includes('BIZ'))

        thunkAPI.dispatch(setMemberPersonalizeSet(data))
      })
    } catch (err) {}
  },
)

export const getMemberLoginLogList = createAsyncThunk(
  '/management/selectMember/setMemberLoginLogList,',
  async (data: IMemberLoginLogParams, thunkAPI) => {
    try {
      await managementMemberApis.getMemberLoginLog(data).then((res) => {
        const data = res.data.result_data
        thunkAPI.dispatch(setMemberLoginLogList(data))
      })
    } catch (err) {}
  },
)
