import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { memberApis } from '@src/api/memberApi'
import {
  IMemberCardSelect,
  IMemberList,
  IMemberPersonalize,
  IMemberSearchParam,
  IMembershipSelect,
} from '@src/type/memberType'
import Router from 'next/router'
import Swal from 'sweetalert2'

export interface MemberRating {
  role: string
  id: number
}

export interface MemberListPaging {
  currentPage: number
  endPage: number
  nowBlock: number
  startPage: string
  totalBlock: number
  totalPage: number
}

const initialState = {
  memberList: {} as IMemberList,
  memberSelect: [],
  memberCardSelect: {} as IMemberCardSelect,
  memberPersonalize: {} as IMemberPersonalize,
  memberListPaging: {},
  membershipSelect: {} as IMembershipSelect,
  totalPage: 0,
  currentPage: 1,
  currentCategory: null,
  currentRole: null,
  searchCategory: null,
  searchKeyword: null,
  maxPageNumberLimit: 5,
  minPageNumberLimit: 0,
  cardNumber: '',
  cardStatus: null,
  cardReason: '',
  cardType: '',
  currentID: '',
  memberCardID: '',
  messageOK: '',
  pageList: 15,
  roleLen: [],
  memberCopyList: [],
}

export const memberListData = createAsyncThunk('/member/list', async (PageNumber: IMemberSearchParam, thunkAPI) => {
  try {
    await memberApis.memberList(PageNumber).then((res) => {
      thunkAPI.dispatch(setMemberList(res.data?.result_data))
      thunkAPI.dispatch(setTotalPage(res.data?.result_data.total_page))
    })
  } catch (err) {}
})

export const memberSelectData = createAsyncThunk('/member/select', async (id: number, thunkAPI) => {
  try {
    await memberApis.memberSelect(id).then((res) => {
      thunkAPI.dispatch(setMemberCardSelect(res.data?.result_data.member))
      thunkAPI.dispatch(setMemberSelect(res.data?.result_data.membership))
    })
  } catch (err) {}
})

export const membershipSelect = createAsyncThunk('/membership/select', async (id: number, thunkAPI) => {
  try {
    await memberApis.memberSelect(id).then((res) => {
      thunkAPI.dispatch(setMemberSelect(res.data?.result_data.member))
    })
  } catch (err) {}
})
export const memberPersonaltData = createAsyncThunk('/member/PersonalizeSelect', async (id: number, thunkAPI) => {
  try {
    await memberApis.memberSelect(id).then((res) => {
      thunkAPI.dispatch(setMemberPersonalize(res.data?.result_data))
    })
  } catch (err) {}
})

export const membershipSelectData = createAsyncThunk('/membership/select', async (id: number, thunkAPI) => {
  try {
    await memberApis.membershipSelect(id).then((res) => {
      const data = {
        id: res.data.result_data.id,
        card_number: res.data.result_data.card_number,
        card_status: res.data.result_data.card_status,
        is_parent: res.data.result_data.is_parent,
        card_type: res.data.result_data.card_type,
        card_alias: res.data.result_data.card_alias,
        reason: res.data.result_data.reason,
      }
      thunkAPI.dispatch(setMemberShipSelect(res.data.result_data))
    })
  } catch (err) {}
})
// ------------
export const memberSearchData = createAsyncThunk('/member/search', async (data: IMemberSearchParam, thunkAPI) => {
  try {
    await memberApis.memberSearchList(data).then((res) => {
      thunkAPI.dispatch(setMemberList(res.data?.result_data))
      thunkAPI.dispatch(setTotalPage(res.data?.result_data.total_page))

      let copy = []
      for (let i = 0; i < res.data?.result_data.records.length; i += 1) {
        copy.push({
          이름: res.data?.result_data.records[i]?.lastname + res.data?.result_data.records[i]?.firstName,
          이메일: res.data?.result_data.records[i]?.email,
          성별: Number(res.data?.result_data.records[i]?.gender) ? '여성' : '남성',
          생년월일: res.data?.result_data.records[i]?.birthday,
          휴대폰번호: res.data?.result_data.records[i]?.phoneNumber,
          가입타입: res.data?.result_data.records[i]?.registerType ? '카카오' : '자사',
          상태:
            res.data?.result_data.records[i]?.emailVerify &&
            (!res.data?.result_data.records[i]?.suspended ||
              String(res.data?.result_data.records[i]?.suspended) === '0') &&
            res.data?.result_data.records[i]?.loginFailCount < 5
              ? '정상'
              : !res.data?.result_data.records[i]?.emailVerify &&
                res.data?.result_data.records[i]?.loginFailCount >= 5 &&
                String(res.data?.result_data.records[i]?.suspended) === '1'
              ? '이메일 인증X, 계정잠김, 이용정지'
              : !res.data?.result_data.records[i]?.emailVerify &&
                res.data?.result_data.records[i]?.loginFailCount >= 5 &&
                (!res.data?.result_data.records[i]?.suspended ||
                  String(res.data?.result_data.records[i]?.suspended) === '0')
              ? '이메일 인증x, 계정잠김'
              : !res.data?.result_data.records[i]?.emailVerify &&
                res.data?.result_data.records[i]?.loginFailCount < 5 &&
                String(res.data?.result_data.records[i]?.suspended) === '1'
              ? '이메일 인증x, 이용정지'
              : res.data?.result_data.records[i]?.emailVerify &&
                res.data?.result_data.records[i]?.loginFailCount >= 5 &&
                String(res.data?.result_data.records[i]?.suspended) === '1'
              ? '계정잠김, 이용정지'
              : !res.data?.result_data.records[i]?.emailVerify &&
                res.data?.result_data.records[i]?.loginFailCount < 5 &&
                (!res.data?.result_data.records[i]?.suspended ||
                  String(res.data?.result_data.records[i]?.suspended) === '0')
              ? '이메일 인증x'
              : res.data?.result_data.records[i]?.emailVerify &&
                res.data?.result_data.records[i]?.loginFailCount >= 5 &&
                (!res.data?.result_data.records[i]?.suspended ||
                  String(res.data?.result_data.records[i]?.suspended) === '0')
              ? '계정잠김'
              : '이용정지',
          등급: res.data?.result_data.records[i]?.role,
          주소: res.data?.result_data.records[i]?.address + ' ' + res.data?.result_data.records[i]?.addressDetail,
          가입일자:
            new Date(
              `${res.data?.result_data.records[i]?.registerDateTime?.replace('T', ' ')?.split('.')[0]}+00:00`,
            ).toLocaleString('sv') + '+09:00',
        })
      }
      thunkAPI.dispatch(setMemberCopyList(copy))
    })
  } catch (err) {}
})

export const memberRatingData = createAsyncThunk('/member/rating', async (data: any, thunkAPI) => {
  try {
    await memberApis.memberRating(data).then((res) => {
      if (res.data.result_code === 'OK') {
        Swal.fire({
          icon: 'success',
          text: '변경되었습니다!',
          showConfirmButton: false,
          timer: 2000,
        })
      }
    })
    await memberApis.memberSearchList(data).then((res) => {
      thunkAPI.dispatch(setMemberList(res.data?.result_data))
      thunkAPI.dispatch(setTotalPage(res.data?.result_data.total_page))

      let copy = []
      for (let i = 0; i < res.data?.result_data.records.length; i += 1) {
        copy.push({
          이름: res.data?.result_data.records[i]?.lastname + res.data?.result_data.records[i]?.firstName,
          이메일: res.data?.result_data.records[i]?.email,
          성별: Number(res.data?.result_data.records[i]?.gender) ? '여성' : '남성',
          생년월일: res.data?.result_data.records[i]?.birthday,
          휴대폰번호: res.data?.result_data.records[i]?.phoneNumber,
          가입타입: res.data?.result_data.records[i]?.registerType ? '카카오' : '자사',
          상태:
            res.data?.result_data.records[i]?.emailVerify &&
            (!res.data?.result_data.records[i]?.suspended ||
              String(res.data?.result_data.records[i]?.suspended) === '0') &&
            res.data?.result_data.records[i]?.loginFailCount < 5
              ? '정상'
              : !res.data?.result_data.records[i]?.emailVerify &&
                res.data?.result_data.records[i]?.loginFailCount >= 5 &&
                String(res.data?.result_data.records[i]?.suspended) === '1'
              ? '이메일 인증X, 계정잠김, 이용정지'
              : !res.data?.result_data.records[i]?.emailVerify &&
                res.data?.result_data.records[i]?.loginFailCount >= 5 &&
                (!res.data?.result_data.records[i]?.suspended ||
                  String(res.data?.result_data.records[i]?.suspended) === '0')
              ? '이메일 인증x, 계정잠김'
              : !res.data?.result_data.records[i]?.emailVerify &&
                res.data?.result_data.records[i]?.loginFailCount < 5 &&
                String(res.data?.result_data.records[i]?.suspended) === '1'
              ? '이메일 인증x, 이용정지'
              : res.data?.result_data.records[i]?.emailVerify &&
                res.data?.result_data.records[i]?.loginFailCount >= 5 &&
                String(res.data?.result_data.records[i]?.suspended) === '1'
              ? '계정잠김, 이용정지'
              : !res.data?.result_data.records[i]?.emailVerify &&
                res.data?.result_data.records[i]?.loginFailCount < 5 &&
                (!res.data?.result_data.records[i]?.suspended ||
                  String(res.data?.result_data.records[i]?.suspended) === '0')
              ? '이메일 인증x'
              : res.data?.result_data.records[i]?.emailVerify &&
                res.data?.result_data.records[i]?.loginFailCount >= 5 &&
                (!res.data?.result_data.records[i]?.suspended ||
                  String(res.data?.result_data.records[i]?.suspended) === '0')
              ? '계정잠김'
              : '이용정지',
          등급: res.data?.result_data.records[i]?.role,
          가입일자:
            new Date(
              `${res.data?.result_data.records[i]?.registerDateTime?.replace('T', ' ')?.split('.')[0]}+00:00`,
            ).toLocaleString('sv') + '+09:00',
        })
      }

      thunkAPI.dispatch(setMemberCopyList(copy))
    })
  } catch (err: any) {
    if (err.response.data.result_code === 'NOT_AUTHORIZED') {
      alert(err.response.data.result_message)
      window.location.reload()
    }
  }
})

export const memberPersonalizeData = createAsyncThunk('/member/personalize', async (data: any, thunkAPI) => {
  try {
    await memberApis.memberPersonalize(data).then((res) => {
      if (res.data.result_code === 'OK') {
        Swal.fire({
          icon: 'success',
          text: '변경되었습니다!',
          showConfirmButton: false,
          timer: 2000,
        })
      }
    })
    await memberApis.memberSearchList(data).then((res) => {
      thunkAPI.dispatch(setMemberList(res.data?.result_data.records))
      thunkAPI.dispatch(setTotalPage(res.data?.result_data.total_page))

      let copy = []
      for (let i = 0; i < res.data?.result_data.records.length; i += 1) {
        copy.push({
          이름: res.data?.result_data.records[i]?.lastname + res.data?.result_data.records[i]?.firstName,
          이메일: res.data?.result_data.records[i]?.email,
          성별: Number(res.data?.result_data.records[i]?.gender) ? '여성' : '남성',
          생년월일: res.data?.result_data.records[i]?.birthday,
          휴대폰번호: res.data?.result_data.records[i]?.phoneNumber,
          가입타입: res.data?.result_data.records[i]?.registerType ? '카카오' : '자사',
          상태:
            res.data?.result_data.records[i]?.emailVerify &&
            (!res.data?.result_data.records[i]?.suspended ||
              String(res.data?.result_data.records[i]?.suspended) === '0') &&
            res.data?.result_data.records[i]?.loginFailCount < 5
              ? '정상'
              : !res.data?.result_data.records[i]?.emailVerify &&
                res.data?.result_data.records[i]?.loginFailCount >= 5 &&
                String(res.data?.result_data.records[i]?.suspended) === '1'
              ? '이메일 인증X, 계정잠김, 이용정지'
              : !res.data?.result_data.records[i]?.emailVerify &&
                res.data?.result_data.records[i]?.loginFailCount >= 5 &&
                (!res.data?.result_data.records[i]?.suspended ||
                  String(res.data?.result_data.records[i]?.suspended) === '0')
              ? '이메일 인증x, 계정잠김'
              : !res.data?.result_data.records[i]?.emailVerify &&
                res.data?.result_data.records[i]?.loginFailCount < 5 &&
                String(res.data?.result_data.records[i]?.suspended) === '1'
              ? '이메일 인증x, 이용정지'
              : res.data?.result_data.records[i]?.emailVerify &&
                res.data?.result_data.records[i]?.loginFailCount >= 5 &&
                String(res.data?.result_data.records[i]?.suspended) === '1'
              ? '계정잠김, 이용정지'
              : !res.data?.result_data.records[i]?.emailVerify &&
                res.data?.result_data.records[i]?.loginFailCount < 5 &&
                (!res.data?.result_data.records[i]?.suspended ||
                  String(res.data?.result_data.records[i]?.suspended) === '0')
              ? '이메일 인증x'
              : res.data?.result_data.records[i]?.emailVerify &&
                res.data?.result_data.records[i]?.loginFailCount >= 5 &&
                (!res.data?.result_data.records[i]?.suspended ||
                  String(res.data?.result_data.records[i]?.suspended) === '0')
              ? '계정잠김'
              : '이용정지',
          등급: res.data?.result_data.records[i]?.role,
          가입일자:
            new Date(
              `${res.data?.result_data.records[i]?.registerDateTime?.replace('T', ' ')?.split('.')[0]}+00:00`,
            ).toLocaleString('sv') + '+09:00',
        })
      }

      thunkAPI.dispatch(setMemberCopyList(copy))
    })
    // if (data.role === 'ALL') {
    //   await memberApis.memberList(data.list).then((res) => {
    //     thunkAPI.dispatch(setMemberList(res.data?.result_data.records))
    //     thunkAPI.dispatch(setTotalPage(res.data?.result_data.total_page))
    //   })
    // } else if (data.role === '이용정지') {
    //   await memberApis.memberSuspendPaging(data.list.page, data.suspend, data.list.list).then((res) => {
    //     thunkAPI.dispatch(setMemberList(res.data?.result_data.records))
    //     thunkAPI.dispatch(setTotalPage(res.data?.result_data.total_page))
    //   })
    // } else {
    //   await memberApis.memberCategoryPaging(data.list.page, data.role, data.list.list).then((res) => {
    //     thunkAPI.dispatch(setMemberList(res.data?.result_data.records))
    //     thunkAPI.dispatch(setTotalPage(res.data?.result_data.total_page))
    //   })
    // }
  } catch (err: any) {
    if (err.response.data.result_code === 'NOT_AUTHORIZED') {
      alert(err.response.data.result_message)
      window.location.reload()
    }
  }
})

export const memberSetPasswordData = createAsyncThunk('/member/setPassword', async (data: any, thunkAPI) => {
  try {
    await memberApis.memberSetPassword(data).then((res) => {
      if (res.data.result_code === 'OK') {
        Swal.fire({
          icon: 'success',
          text: '변경되었습니다!',
          showConfirmButton: false,
          timer: 2000,
        })
      }
    })
    // await memberApis.memberSelect(data.id).then((res) => {
    //   thunkAPI.dispatch(setMemberPersonalize(res.data?.result_data))
    // })
    // await memberApis.memberList(1).then((res) => {
    //   thunkAPI.dispatch(setMemberList(res.data?.result_data.records))
    //   thunkAPI.dispatch(setTotalPage(res.data?.result_data.total_page))
    // })
  } catch (err) {}
})

export const membershipCardModify = createAsyncThunk('/member/membership', async (data, thunkAPI) => {
  try {
    await memberApis.membership(data).then((res) => {
      if (res.data.result_code === 'OK') {
        thunkAPI.dispatch(setMessageOK('OK'))
        Swal.fire({
          icon: 'success',
          text: '변경되었습니다!',
        }).then(() => {
          Router.back()
        })
      } else {
        Swal.fire({
          icon: 'warning',
          text: `${res.data.result_message}`,
        })
      }
    })
  } catch (err: any) {
    Swal.fire({
      icon: 'warning',
      text: `${err.response.data?.result_message}`,
    })
  }
})

export const MemberSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    setMemberList: (state, action) => {
      state.memberList = action.payload
    },

    setMemberCopyList: (state, action) => {
      state.memberCopyList = action.payload
    },
    setMemberPaging: (state, action) => {
      state.memberListPaging = action.payload
    },
    setTotalPage: (state, action) => {
      state.totalPage = action.payload
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setCurrentCategory: (state, action) => {
      state.currentCategory = action.payload
    },
    setCurrentRole: (state, action) => {
      state.currentRole = action.payload
    },
    setSearchCategory: (state, action) => {
      state.searchCategory = action.payload
    },
    setSearchKeyword: (state, action) => {
      state.searchKeyword = action.payload
    },
    setMaxPageNumberLimit: (state, action) => {
      state.maxPageNumberLimit = action.payload
    },
    setMinPageNumberLimit: (state, action) => {
      state.minPageNumberLimit = action.payload
    },
    setMemberSelect: (state, action) => {
      state.memberSelect = action.payload
    },
    setMemberPersonalize: (state, action) => {
      state.memberPersonalize = action.payload
    },
    setCardNumber: (state, action) => {
      state.cardNumber = action.payload
    },
    setCardStatus: (state, action) => {
      state.cardStatus = action.payload
    },
    setCardReason: (state, action) => {
      state.cardReason = action.payload
    },
    setCardType: (state, action) => {
      state.cardType = action.payload
    },
    setCurrentID: (state, action) => {
      state.currentID = action.payload
    },
    setMemberCardID: (state, action) => {
      state.memberCardID = action.payload
    },
    setMemberShipSelect: (state, action) => {
      state.membershipSelect = action.payload
    },
    setMessageOK: (state, action) => {
      state.messageOK = action.payload
    },
    setPageList: (state, action) => {
      state.pageList = action.payload
    },
    setRoleLen: (state, action) => {
      state.roleLen = action.payload
    },
    setMemberCardSelect: (state, action) => {
      state.memberCardSelect = action.payload
    },
  },
})

export const {
  setMemberList,
  setMemberCopyList,
  setTotalPage,
  setMemberPaging,
  setCurrentPage,
  setCurrentCategory,
  setCurrentRole,
  setSearchCategory,
  setSearchKeyword,
  setMaxPageNumberLimit,
  setMinPageNumberLimit,
  setMemberSelect,
  setCardNumber,
  setCardStatus,
  setCardReason,
  setCardType,
  setCurrentID,
  setMemberCardID,
  setMemberShipSelect,
  setMessageOK,
  setMemberPersonalize,
  setPageList,
  setRoleLen,
  setMemberCardSelect,
} = MemberSlice.actions
export default MemberSlice.reducer
