import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { userInquiryApis } from '@src/features/user/inquiry/api'
import { IFile, IInquiry, IInquiryDetail, IInquiryList } from '@src/features/user/inquiry/types'

const initialState = {
  userInquiryList: null as null | IInquiryList,
  CUMInquiryList: null as null | IInquiry[],
  clickedInquiryId: null as null | number,
  userInquiryDetail: null as null | IInquiryDetail,
}
export const userInquirySlice = createSlice({
  name: 'userInquirySlice',
  initialState,
  reducers: {
    setUserInquiryList: (state, action) => {
      state.userInquiryList = action.payload
    },
    setCUMInquiryList: (state, action) => {
      state.CUMInquiryList = action.payload
    },
    setUserInquiryDetail: (state, action) => {
      state.userInquiryDetail = action.payload
    },

    setClickedInquiryId: (state, action) => {
      state.clickedInquiryId = action.payload
    },
  },
})
export const { setUserInquiryList, setCUMInquiryList, setUserInquiryDetail, setClickedInquiryId } =
  userInquirySlice.actions

export default userInquirySlice.reducer

// -------------------------------------- //

export const getUserInquiryList = createAsyncThunk(
  '/user/inquiry/getUserInquiryList',
  async (data: number, thunkAPI) => {
    try {
      await userInquiryApis.getUserInquiryList(data).then((res) => {
        const data = res.data.result_data
        if (!data) {
          thunkAPI.dispatch(setUserInquiryList(null))
        } else {
          thunkAPI.dispatch(setUserInquiryList(data))
          thunkAPI.dispatch(setCUMInquiryList(data.records))
        }
      })
    } catch (err) {}
  },
)
export const getUserInquiryDetail = createAsyncThunk(
  '/user/inquiry/getUserInquiryDetail',
  async (data: number, thunkAPI) => {
    try {
      await userInquiryApis.getUserInquiryDetail(data).then((res) => {
        const data = res.data.result_data
        if (!data) {
          thunkAPI.dispatch(setUserInquiryDetail(null))
        } else {
          const filteredData = {
            ...data,
            files: data.files.filter((file: IFile) => file.fileId !== null),
          }
          thunkAPI.dispatch(setUserInquiryDetail(filteredData))
        }
      })
    } catch (err) {}
  },
)
