import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { managementMemberApis } from '@src/api/management/member'
import { IWithDrawMemberList } from '@src/type/management/withDrawMember'

const initialState = {
  withDrawMemberList: {} as IWithDrawMemberList,
}
export const withDrawMemberSlice = createSlice({
  name: 'withDrawMember',
  initialState,
  reducers: {
    setWithDrawMemberList: (state, action) => {
      state.withDrawMemberList = action.payload
    },
  },
})
export const { setWithDrawMemberList } = withDrawMemberSlice.actions

export default withDrawMemberSlice.reducer

// -------------------------------------- //

export const getWithDrawList = createAsyncThunk(
  '/menagement/withDrawMember/getWithDrawList',
  async (data: number, thunkAPI) => {
    try {
      await managementMemberApis.getWithDrawList(data).then((res) => {
        const data = res.data.result_data
        thunkAPI.dispatch(setWithDrawMemberList(data))
      })
    } catch (err) {}
  },
)
