import { CounselingList } from '@src/store/modules/counseling'
import instance from './axios'
import { counselingAnswerData } from '@src/components/counseling/detail'

export interface CounselingType {
  counsel_category: string
  applicant_classification: string
  applicant_name: string
  corporate_name: string
  ceo_name: string
  email: string
  phone_number: string
  address: string
  address_detail: string
  content: string
}

export const counselingApis = {
  getCounseling: (data: any) =>
    instance.get(
      `/management/counsel/list?p=${data.page}&answer=${data.answer}${
        data.applicant_classification ? `&applicant_classification=${data.applicant_classification}` : ''
      }
      ${data.counsel_category_id ? `&counsel_category_id=${data.counsel_category_id}` : ''}
      ${data.phone_number ? `&phone_number=${data.phone_number}` : ''}
      ${data.name ? `&name=${data.name}` : ''}
      `,
    ),
  createCounseling: (data: CounselingType) => instance.post('/counsel/add', data),
  answerCounseling: (data: counselingAnswerData) => instance.post('/management/counsel/answer', data),
}
