import { MemberSearchCategory } from '@src/store/modules/member'
import instance from './axios'
import { IMembershipSearchParam } from '@src/type/manageMembership'

export const manageMembershipApis = {
  membershipList: (page: number) => instance.get(`/management/membership/list?p=${page}`),
  membershipCategoryPaging: (page: number, role: string) =>
    instance.get(`/management/membership/list?role=${role}&p=${page}`),
  membershipSearchCategoryList: (data: MemberSearchCategory) =>
    instance.get(`/management/membership/list?query=${data.query}&category=${data.category}&p=${data.page}`),
  membershipSearchStatusCategoryList: (data: MemberSearchCategory) =>
    instance.get(
      `/management/membership/list?status=${data.status}&query=${data.query}&category=${data.category}&p=${data.page}`,
    ),
  membershipSearchStatusList: (data) =>
    instance.get(`/management/membership/list?status=${data.status}&p=${data.page}`),

  // 조건에 맞춰 멤버십 리스트를 조회하는 api
  membershipSearchList: (data: IMembershipSearchParam) =>
    instance.get(
      `/management/membership/list?p=${data.page}&list_num=${data.listNum}` +
        `${data.query ? `&query=${data.query}` : ''}` +
        `${data.category ? `&category=${data.category}` : ''}` +
        `${data.isAll ? `` : `&status=${data.status}`}`,
    ),

  memberSelect: (id: number) => instance.get(`/management/select-member?id=${id}`),
  memberMeUpdate: (data: any) => instance.post('/management/membership/me-update', data),
}
