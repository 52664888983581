import getConfigs from './config.common'

const publicUrl = 'https://dev.wev-charger.com/'
const baseUrl = 'https://dev.wev-charger.com:11050/v1/'
const mode = 'development'
const recaptcha = '6Le4KEEiAAAAAFiiNgiaM4-s8Z--y4DR-Q9DZUwg'

const configDevelopment = getConfigs({
  publicUrl,
  baseUrl,
  mode,
  recaptcha,
})

export default configDevelopment
