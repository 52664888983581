import { ICouponHistoryParams } from '@src/type/management/coupon'
import instance from '../axios'

export const managementCouponApis = {
  getCouponUsedHistory: (data: ICouponHistoryParams) =>
    instance(`/management/coupon/history?p=${data.page}&listNum=${data.list}`),
  getPointUsedHistory: (data: ICouponHistoryParams) =>
    instance(`/management/point/history?p=${data.page}&listNum=${data.list}`),
  putPoint: (id: number, data: any) => instance.put(`/management/coupon/${id}`, data),
}
