export const wev_color = {
  white: '#FFFFFF',
  black: '#000000',
  primary: '#583EEE',
  text: '#1E1E1E',
  outline: '#EDEDED',
  hint: '#A9A9A9',
  menu_bg: '#ECE9FF',
  sub_btn_bg: '#F9F8FF',
  sub_menu_text: '#868591',
  delete: '#ED2424',
  sub_btn_hover: '#F3F1FF',
  info_bg: '#F6F6F6',
  delete_hover: '#FDE6E6',
  placeholder: '#cccccc',
  success: '#13D460',
  success_bg: '#EAF9F0',
  // 그라데이션 요소
  gradation_l: '#2beede',
  gradation_r: '#583eee',
} as const

export type ColorType = typeof wev_color
