import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { userInfoApis } from '@src/features/user/info/api'
import { ISocial, IUserDevice, IUserInfoMasking } from '@src/features/user/info/types'

const initialState = {
  userInfoMasking: {} as IUserInfoMasking,
  userInfo: {} as IUserInfoMasking,
  userDevice: [] as IUserDevice[],
  userSocial: null as ISocial[] | null,
}
export const userInfoSlice = createSlice({
  name: 'userInfoSlice',
  initialState,
  reducers: {
    setUserInfoMasking: (state, action) => {
      state.userInfoMasking = action.payload
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload
    },
    setUserDevice: (state, action) => {
      state.userDevice = action.payload
    },
    setUserSocial: (state, action) => {
      state.userSocial = action.payload
    },
  },
})
export const { setUserInfoMasking, setUserInfo, setUserDevice, setUserSocial } = userInfoSlice.actions

export default userInfoSlice.reducer

export const getUserMaskingInfo = createAsyncThunk('/user/info/getUserMaskingInfo', async (data, thunkAPI) => {
  try {
    await userInfoApis.getUserMaskingInfo().then((res) => {
      const data = res.data.result_data
      thunkAPI.dispatch(setUserInfoMasking(data))
    })
  } catch (err) {}
})

export const getUserInfo = createAsyncThunk('/user/info/getUserInfo ', async (data, thunkAPI) => {
  try {
    await userInfoApis.getUserInfo().then((res) => {
      const data = res.data.result_data
      thunkAPI.dispatch(setUserInfo(data))
    })
  } catch (err) {}
})

export const getUserDevice = createAsyncThunk('/user/info/getUserDevice ', async (data, thunkAPI) => {
  try {
    await userInfoApis.getUserDevice().then((res) => {
      const data = res.data.result_data
      thunkAPI.dispatch(setUserDevice(data))
    })
  } catch (err) {}
})

export const getUserSocial = createAsyncThunk('/user/info/getUserSocial', async (data, thunkAPI) => {
  try {
    await userInfoApis.getUserSocial().then((res) => {
      if (res.data.result_code === 'OK') {
        const data = res.data.result_data
        thunkAPI.dispatch(setUserSocial(data))
      }
    })
  } catch (err) {}
})
