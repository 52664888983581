import instance from '@src/api/axios'
import { IInfoEditParams } from '../types'

export const userInfoApis = {
  getUserSocial: () => instance.get(`/user/social/list`),
  unLinkSocial: (data: { platformType: number }) => instance.post(`/user/social/unlink`, data),
  getUserDevice: () => instance.get(`/user/device`),
  deleteDevice: (data: number) => instance.delete(`/user/device/${data}`),
  getUserInfo: () => instance.get('/user/info'),
  getUserMaskingInfo: () => instance.get('/user/info-masking'),
  editInfo: (data: IInfoEditParams) => instance.put(`/user/edit/info`, data),
  editLocale: (data: string) => instance.post('/personalize', { data_key: 'LOCALE', data_value: data }),
  editTimeZone: (data: string) => instance.post('/personalize', { data_key: 'TIME_ZONE', data_value: data }),
}
