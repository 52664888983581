import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { BusinessInfoApis } from '@src/api/business/info'
import { IBusinessInfo, IBusinessInfoDetail } from '@src/type/business'

const initialState = {
  businessInfo: {} as IBusinessInfo,
  businessInfoDetail: {} as IBusinessInfoDetail,
  businessRole: '' as String,
  businessHasClaim: false as boolean,
}

export const BusinessInfoSlice = createSlice({
  name: 'businessInfo',
  initialState,
  reducers: {
    setBusinessInfo: (state, action) => {
      state.businessInfo = action.payload
    },
    setBusinessInfoDetail: (state, action) => {
      state.businessInfoDetail = action.payload
    },
    setBusinessRole: (state, action) => {
      state.businessRole = action.payload
    },
    setBusinessHasClaim: (state, action) => {
      state.businessHasClaim = action.payload
    },
  },
})

export const { setBusinessInfo, setBusinessInfoDetail, setBusinessRole, setBusinessHasClaim } =
  BusinessInfoSlice.actions

export default BusinessInfoSlice.reducer

export const getBusinessInfo = createAsyncThunk('/business/info/getBusinessInfo', async (data, thunkAPI) => {
  try {
    await BusinessInfoApis.getBusinessInfo().then((res) => {
      if (res.data.result_code === 'OK') {
        const data = res.data.result_data
        thunkAPI.dispatch(setBusinessInfo(data))
      } else {
        thunkAPI.dispatch(setBusinessInfo({}))
      }
    })
  } catch (err) {}
})

export const getBusinessInfoDetail = createAsyncThunk(
  '/business/info/getBusinessInfoDetail',
  async (data, thunkAPI) => {
    try {
      await BusinessInfoApis.getBusinessInfoDetail().then((res) => {
        if (res.data.result_code === 'OK') {
          const data = res.data.result_data
          thunkAPI.dispatch(setBusinessInfoDetail(data))
        } else {
          thunkAPI.dispatch(setBusinessInfoDetail({}))
        }
      })
    } catch (err) {}
  },
)

export const getBusinessCheck = createAsyncThunk('/business/info/getBusinessCheck', async (data, thunkAPI) => {
  try {
    await BusinessInfoApis.getBusinessCheck().then((res) => {
      if (res.data.result_code === 'OK') {
        const data = res.data.result_data
        thunkAPI.dispatch(setBusinessRole(data))
        thunkAPI.dispatch(setBusinessHasClaim(false))
      } else if (res.data.result_code === 'EXIST_CLAIM_CHARGE') {
        const data = res.data.result_data
        thunkAPI.dispatch(setBusinessRole(data))
        thunkAPI.dispatch(setBusinessHasClaim(true))
      } else {
      }
    })
  } catch (err) {}
})
