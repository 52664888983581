import { createSlice } from '@reduxjs/toolkit'
import { IQRPaymentInitData } from '@src/type/s/unique'

const initialState = {
  qrPaymentInitData: {
    qr_type: 2,
  } as IQRPaymentInitData,
}
export const uniqueSlice = createSlice({
  name: 'uniqueSlice',
  initialState,
  reducers: {
    setQrPaymentInitData: (state, action) => {
      state.qrPaymentInitData = action.payload
    },
  },
})
export const { setQrPaymentInitData } = uniqueSlice.actions

export default uniqueSlice.reducer
