import { LIGHT_BACKGROUND_ROUTES, LIGHT_BACKGROUND_ROUTES_EXCEPT } from '../layouts/header/constants'
import { wev_color } from '../styles/property/color'

export const routeBgColorCheck = (pathname: string) => {
  if (
    LIGHT_BACKGROUND_ROUTES_EXCEPT.includes(pathname) ||
    !LIGHT_BACKGROUND_ROUTES.some((route: string) => route === pathname || pathname.startsWith(route))
  ) {
    return '#fff'
  }
  return '#1E1E1E'
}
