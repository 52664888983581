import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { BusinessMemberApis } from '@src/api/business/member'
import { BusinessMembershipApis } from '@src/api/business/membership'
import { IBusinessMemberList, IBusinessMemberListParam } from '@src/type/business'
import {
  IBusinessMembership,
  IBusinessMembershipList,
  IBusinessMembershipListParam,
  IBusinessMembershipReciptParam,
} from '@src/type/business/businessMembership'

const initialState = {
  businessMembershipList: {} as IBusinessMembershipList,
  businessMembershipFilter: {
    page: 1,
    recordSize: 15,
    pageSize: 5,
    cardStatus: null,
    keyword: '',
  } as IBusinessMembershipListParam,
  businessMembershipInfo: {} as IBusinessMembership,
  businessMembershipReciptParam: {} as IBusinessMembershipReciptParam,
  businessMembershipMemberList: {} as IBusinessMemberList,
  businessMembershipNotMemberList: {} as IBusinessMemberList,
}

export const BusinessMembershipSlice = createSlice({
  name: 'businessMembership',
  initialState,
  reducers: {
    setBusinessMembershipList: (state, action) => {
      state.businessMembershipList = action.payload
    },
    setBusinessMembershipFilter: (state, action) => {
      state.businessMembershipFilter = action.payload
    },
    setBusinessMembershipInfo: (state, action) => {
      state.businessMembershipInfo = action.payload
    },
    setBusinessMembershipReciptParam: (state, action) => {
      state.businessMembershipReciptParam = action.payload
    },

    setBusinessMembershipMemberList: (state, action) => {
      state.businessMembershipMemberList = action.payload
    },
    setBusinessMembershipNotMemberList: (state, action) => {
      state.businessMembershipNotMemberList = action.payload
    },
  },
})

export const {
  setBusinessMembershipList,
  setBusinessMembershipFilter,
  setBusinessMembershipInfo,
  setBusinessMembershipReciptParam,
  setBusinessMembershipMemberList,
  setBusinessMembershipNotMemberList,
} = BusinessMembershipSlice.actions

export default BusinessMembershipSlice.reducer

export const getBusinessMembershipList = createAsyncThunk(
  '/business/menagement/membership/getBusinessMembershipList',
  async (data: IBusinessMembershipListParam, thunkAPI) => {
    try {
      await BusinessMembershipApis.getMembershipList(data).then((res) => {
        const businessMembershipData = res.data.result_data
        thunkAPI.dispatch(setBusinessMembershipList(businessMembershipData))
      })
    } catch (err) {}
  },
)

export const getMembershipInfo = createAsyncThunk(
  '/business/menagement/membership/getMembershipInfo',
  async (data: string, thunkAPI) => {
    try {
      await BusinessMembershipApis.getMembershipInfo(data).then((res) => {
        const businessMembershipInfoData = res.data.result_data
        thunkAPI.dispatch(setBusinessMembershipInfo(businessMembershipInfoData))
      })
    } catch (err) {}
  },
)

export const getBusinessMembershipMemberList = createAsyncThunk(
  '/business/menagement/membership/getBusinessMembershipMemberList',
  async (data: IBusinessMemberListParam, thunkAPI) => {
    try {
      await BusinessMemberApis.getMemberList(data).then((res) => {
        const businessMembershipMemberData = res.data.result_data
        thunkAPI.dispatch(setBusinessMembershipMemberList(businessMembershipMemberData))
      })
    } catch (err) {}
  },
)

export const getBusinessMembershipNotMemberList = createAsyncThunk(
  '/business/menagement/membership/getBusinessMembershipNotMemberList',
  async (data: IBusinessMemberListParam, thunkAPI) => {
    try {
      await BusinessMemberApis.getMemberList(data).then((res) => {
        const businessMembershipNotMemberData = res.data.result_data
        thunkAPI.dispatch(setBusinessMembershipNotMemberList(businessMembershipNotMemberData))
      })
    } catch (err) {}
  },
)
