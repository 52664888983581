import { useEffect, useState } from 'react'

export const useResize = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [isTablet, setIsTablet] = useState<boolean>(false)

  useEffect(() => {
    const init = () => {
      if (639 > window.innerWidth) {
        setIsMobile(true)
      } else if (1024 > window.innerWidth && window.innerWidth > 639) {
        setIsTablet(true)
      } else {
        setIsTablet(false)
        setIsMobile(false)
      }
    }
    init()
    window.addEventListener('resize', init)
    return () => window.removeEventListener('resize', init)
  }, [])

  return { isMobile, isTablet }
}
