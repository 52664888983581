import { useRouter } from 'next/router'

/* 
SideBar가 숨겨지는 페이지인지 판단하는 함수 
return type : boolean
*/

const HIDE_PAGE = []

const isSideBarHidePage = () => {
  const router = useRouter()
  const currentPath = router.asPath

  if (
    currentPath.includes('/billing_card/result') ||
    currentPath.includes('/security/activate') ||
    currentPath.includes('/security/deactivate') ||
    currentPath.startsWith('/user/linked')
  )
    return true
  else {
    return false
  }
}

export default isSideBarHidePage
