import { Menu, MenuItem } from '@mui/material'
import { LOCALE_TYPE_LIST } from '@src/constant/commons/internationalization'
import { useAppSelector } from '@src/hooks/redux'
import { wev_color } from '@src/styles/property'
import checkAccessToken from '@src/utils/auth/checkAccessToken'
import { getBrowserLanguageCode } from '@src/utils/languageCode'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import Cookies from 'universal-cookie'

interface IProps {
  anchorEl: null | HTMLElement
  open: boolean
  handleClose: VoidFunction
}

const cookies = new Cookies()

const LanguageSettings = (props: IProps) => {
  const { anchorEl, open, handleClose } = props
  const router = useRouter()
  const { asPath, pathname, query } = router
  const newUserInfo = useAppSelector((state) => state.userInfo.userInfoMasking)
  const hasAccessToken = checkAccessToken()

  const handleChangeLocale = (locale: string) => {
    sessionStorage.setItem('wev.internationalization.language', locale)
    cookies.set('wev.internationalization.language', locale)
    router.push({ pathname, query }, asPath, { locale })
    handleClose()
  }

  useEffect(() => {
    const tempLanguage = sessionStorage.getItem('wev.internationalization.language')
    if (tempLanguage) {
      const locale = tempLanguage
      cookies.set('wev.internationalization.language', tempLanguage, { expires: undefined, path: '/' })
      router.replace(router.pathname, router.asPath, { locale })
      return
    }
  }, [])

  useEffect(() => {
    const tempLanguage = sessionStorage.getItem('wev.internationalization.language')
    // 사용자 브라우저 판단
    if (!tempLanguage && !hasAccessToken) {
      const locale = getBrowserLanguageCode()
      router.replace(router.pathname, router.asPath, { locale })
      cookies.set('wev.internationalization.language', locale, { expires: undefined, path: '/' })
    } else {
      if (!tempLanguage && newUserInfo.locale) {
        const locale = newUserInfo.locale
        router.replace(router.pathname, router.asPath, { locale })
        cookies.set('wev.internationalization.language', newUserInfo.locale, { expires: undefined, path: '/' })
      }
    }
  }, [newUserInfo.locale])

  return (
    <Menu
      disableScrollLock={true}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: 4,
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25) !important',
        },
        '& .MuiList-root': { py: '0 !important' },
      }}
    >
      {LOCALE_TYPE_LIST.map(({ label, value }) => (
        <MenuItem
          key={`language-settings-${label}`}
          onClick={() => handleChangeLocale(value)}
          sx={{
            justifyContent: 'center',
            color: wev_color.text,
            '&:hover': { color: wev_color.primary },
            height: 'fit-content',
            px: 4,
            py: 2,
            borderBottom: `1px solid ${wev_color.outline}`,
            fontFamily: 'Pretendard, sans-serif',
          }}
        >
          {label}
        </MenuItem>
      ))}
    </Menu>
  )
}

export default LanguageSettings
