export const LOCALE_TYPE_LIST = [
  { id: 1, name: 'KO', value: 'ko', unavailable: false, label: '한국어' },
  { id: 2, name: 'EN', value: 'en', unavailable: false, label: 'English' },
  { id: 3, name: 'JP', value: 'jp', unavailable: false, label: '日本語' },
]

export const TIME_ZONE_TYPE_LIST = [
  { id: 1, name: '한국/서울', value: 'Asia/Seoul', unavailable: false },
  { id: 2, name: '미국/로스앤젤레스', value: 'America/Los_Angeles', unavailable: false },
  { id: 3, name: '카타르', vaule: 'Asia/Qatar', unavailable: false },
]
