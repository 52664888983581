import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { paymentApis } from '@src/api/management/payment'
import { INotUsedUidList, INotUsedUidParams } from '@src/type/management/guestPayment'

const initialState = {
  notUsedUidList: {} as INotUsedUidList,
  notUsedUidListFilter: {
    page: 1,
    list: 15,
    refund: 0,
  } as INotUsedUidParams,
}
export const GuestPaymentSlice = createSlice({
  name: 'guestPayment',
  initialState,
  reducers: {
    setNotUsedUidList: (state, action) => {
      state.notUsedUidList = action.payload
    },
    setNotUsedUidListFilter: (state, action) => {
      state.notUsedUidListFilter = action.payload
    },
  },
})
export const { setNotUsedUidList, setNotUsedUidListFilter } = GuestPaymentSlice.actions

export default GuestPaymentSlice.reducer

// -------------------------------------- //

export const getNotUsedUidList = createAsyncThunk(
  '/management/guestPayment/getNotUsedUidList',
  async (data: INotUsedUidParams, thunkAPI) => {
    try {
      await paymentApis.getNotUsedUidList(data).then((res) => {
        const data = res.data.result_data

        thunkAPI.dispatch(setNotUsedUidList({ ...data }))
      })
    } catch (err) {}
  },
)
