import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { managementCouponApis } from '@src/api/management/coupon'
import { ICouponHistoryList, ICouponHistoryParams, IPointHistoryList } from '@src/type/management/coupon'

const initialState = {
  couponHistoryList: {} as ICouponHistoryList,
  pointHistoryList: {} as IPointHistoryList,
}
export const managementCoupon = createSlice({
  name: 'coupon',
  initialState,
  reducers: {
    setCouponHistoryList: (state, action) => {
      state.couponHistoryList = action.payload
    },
    setPointHistoryList: (state, action) => {
      state.pointHistoryList = action.payload
    },
  },
})
export const { setCouponHistoryList, setPointHistoryList } = managementCoupon.actions

export default managementCoupon.reducer

// -------------------------------------- //

export const getCouponHistoryList = createAsyncThunk(
  '/management/coupon/getCouponHistoryList',
  async (data: ICouponHistoryParams, thunkAPI) => {
    try {
      await managementCouponApis.getCouponUsedHistory(data).then((res) => {
        const data = res.data.result_data
        thunkAPI.dispatch(setCouponHistoryList(data))
      })
    } catch (err) {}
  },
)
export const getPointHistoryList = createAsyncThunk(
  '/management/coupon/getPointHistoryList',
  async (data: ICouponHistoryParams, thunkAPI) => {
    try {
      await managementCouponApis.getPointUsedHistory(data).then((res) => {
        const data = res.data.result_data
        thunkAPI.dispatch(setPointHistoryList(data))
      })
    } catch (err) {}
  },
)
