// todo double check
// * light background with dark text routes
export const LIGHT_BACKGROUND_ROUTES = [
  '/business',
  '/user',
  '/membership',
  '/billing',
  '/terms',
  '/management',
  '/wev',
  '/customer',
]

export const LIGHT_BACKGROUND_ROUTES_EXCEPT = ['/wev', '/wev/partnership']
