import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { employeeApis } from '@src/api/management/employee'
import { IAuthorizationChargeBoxList, IAuthorizationChargeBoxParams } from '@src/type/management/employee'

const initialState = {
  authorizationChargeBoxList: {} as IAuthorizationChargeBoxList,
}
export const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    setAuthorizationChargeBoxList: (state, action) => {
      state.authorizationChargeBoxList = action.payload
    },
  },
})
export const { setAuthorizationChargeBoxList } = employeeSlice.actions

export default employeeSlice.reducer

// -------------------------------------- //

export const getAuthorizationChargeBoxList = createAsyncThunk(
  '/menagement/employee/getAuthorizationChargeBoxList',
  async (data: IAuthorizationChargeBoxParams, thunkAPI) => {
    try {
      await employeeApis.getAuthorizationChargeBox(data).then((res) => {
        const data = res.data.result_data
        thunkAPI.dispatch(setAuthorizationChargeBoxList(data))
      })
    } catch (err) {}
  },
)
