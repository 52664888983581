import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { BusinessUsageHistoryApis } from '@src/api/business/usageHistory'
import {
  IUsageHistoryFilter,
  IUsageHistoryGraph,
  IUsageHistoryInfo,
  IUsageHistoryList,
  IUsageHistoryListParam,
} from '@src/type/business'
import { getMonthsAgo } from '@src/utils/dateCalculater'

const initialState = {
  usageHistoryGraph: [] as IUsageHistoryGraph[],
  usageHistoryList: {} as IUsageHistoryList,
  usageHistoryInfo: {} as IUsageHistoryInfo,
  usageHistoryFilter: {
    maxChargingAmount: 0,
    maxChargingKwh: 0,
    minChargingAmount: 0,
    minChargingKwh: 0,
    startDate: '',
    endDate: '',
    keyword: '',
    page: 1,
    pageSize: 5,
    recordSize: 15,
  } as IUsageHistoryFilter,
}
export const BusinessUsageHistorySlice = createSlice({
  name: 'BusinessUsageHistory',
  initialState,
  reducers: {
    setUsageHistoryGraph: (state, action) => {
      state.usageHistoryGraph = action.payload
    },
    setUsageHistoryList: (state, action) => {
      state.usageHistoryList = action.payload
    },
    setUsageHistoryInfo: (state, action) => {
      state.usageHistoryInfo = action.payload
    },
    setUsageHistoryFilter: (state, action) => {
      state.usageHistoryFilter = action.payload
    },
  },
})

export const { setUsageHistoryGraph, setUsageHistoryList, setUsageHistoryInfo, setUsageHistoryFilter } =
  BusinessUsageHistorySlice.actions

export default BusinessUsageHistorySlice.reducer

export const getUsageHistoryGraph = createAsyncThunk(
  '/business/management/usageHistory/getCorporateInfo',
  async (data: number, thunkAPI) => {
    try {
      await BusinessUsageHistoryApis.getUsageHistoryGraph(data).then((res) => {
        if (res.data.result_code === 'OK') {
          const data = res.data.result_data
          thunkAPI.dispatch(setUsageHistoryGraph(data))
        }
      })
    } catch (err) {}
  },
)
export const getUsageHistoryList = createAsyncThunk(
  '/business/management/usageHistory/getUsageHistoryList',
  async (data: IUsageHistoryListParam, thunkAPI) => {
    try {
      await BusinessUsageHistoryApis.getUsageHistoryList(data).then((res) => {
        if (res.data.result_code === 'OK') {
          const data = res.data.result_data
          if (data.records === null) {
            const emptyData = {
              records: [],
              pagination: [],
            }
            thunkAPI.dispatch(setUsageHistoryList(emptyData))
          } else {
            thunkAPI.dispatch(setUsageHistoryList(data))
          }
        }
      })
    } catch (err) {}
  },
)
export const getUsageHistoryInfo = createAsyncThunk(
  '/business/management/usageHistory/getUsageHistoryInfo',
  async (data: string, thunkAPI) => {
    try {
      await BusinessUsageHistoryApis.getUsageHistoryInfo(data).then((res) => {
        if (res.data.result_code === 'OK') {
          const data = res.data.result_data
          thunkAPI.dispatch(setUsageHistoryInfo(data))
        }
      })
    } catch (error: any) {
      console.log(error)
    }
  },
)
