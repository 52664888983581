import { createSlice } from '@reduxjs/toolkit'

export type ModalProps = {
  key?: string
  component?: () => JSX.Element
}

const initialState: ModalProps[] = []

const modalReducer = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setState(state, action) {
      return action.payload
    },
  },
})

export const modalActions = { ...modalReducer.actions }
export default modalReducer.reducer
