import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useAppDispatch, useAppSelector } from '@src/hooks/redux'
import { getBusinessCheck } from '@src/store/modules/business/info'
import * as S from './BusinessSidebar.styled'

const BusinessSidebar = () => {
  const router = useRouter()
  const dispatch = useAppDispatch()
  const SelectNav = router.asPath
  const businessRole = useAppSelector((state) => state.businessInfo.businessRole)

  useEffect(() => {
    if (
      SelectNav.includes('business/join') ||
      SelectNav.includes('business/policy') ||
      SelectNav.includes('business/about') ||
      SelectNav.includes('business/membership') ||
      SelectNav.includes('business/management/settlement/') ||
      SelectNav.includes('business/confirm-email') ||
      SelectNav.includes('business/management/payment/edit')
    ) {
      return
    } else {
      dispatch(getBusinessCheck())
    }
  }, [SelectNav])

  if (
    SelectNav.includes('business/join') ||
    SelectNav.includes('business/policy') ||
    SelectNav.includes('business/about') ||
    SelectNav.includes('business/membership') ||
    SelectNav.includes('business/management/settlement/') ||
    SelectNav.includes('business/confirm-email') ||
    SelectNav.includes('business/management/payment/edit')
  )
    return null

  return (
    <>
      {businessRole ? (
        <S.Wrapper>
          <h1>비즈니스 관리</h1>
          <S.Menu href="/business/management/info" $locate={SelectNav.includes('/business/management/info')}>
            <span>비즈니스 정보</span>
          </S.Menu>
          <S.Menu href="/business/management/member" $locate={SelectNav.includes('/business/management/member')}>
            <span>멤버 관리</span>
          </S.Menu>
          <S.Menu
            href="/business/management/cardMembership"
            $locate={SelectNav.includes('/business/management/cardMembership')}
          >
            <span>멤버십 카드 관리</span>
          </S.Menu>
          <S.Menu
            href="/business/management/usageHistory"
            $locate={SelectNav.includes('/business/management/usageHistory')}
          >
            <span>이용내역</span>
          </S.Menu>
          {businessRole === 'USER' ? null : (
            <>
              <S.Menu
                href="/business/management/settlement"
                $locate={SelectNav.includes('/business/management/settlement')}
              >
                <span>정산내역</span>
              </S.Menu>

              <S.Menu href="/business/management/payment" $locate={SelectNav.includes('/business/management/payment')}>
                <span>결제수단 관리</span>
              </S.Menu>
            </>
          )}
          <S.Menu href="/business/management/alert" $locate={SelectNav.includes('/business/management/alert')}>
            <span>알림 설정</span>
          </S.Menu>
        </S.Wrapper>
      ) : (
        <>
          <S.Wrapper>
            <h1>비즈니스 관리</h1>
            <S.Menu href="/business/management/info" $locate={SelectNav.includes('/business/management/info')}>
              <span>비즈니스 정보</span>
            </S.Menu>
            <S.Menu href="/business/management/member" $locate={SelectNav.includes('/business/management/member')}>
              <span>멤버 관리</span>
            </S.Menu>
            <S.Menu
              href="/business/management/cardMembership"
              $locate={SelectNav.includes('/business/management/cardMembership')}
            >
              <span>멤버십 카드 관리</span>
            </S.Menu>
            <S.Menu
              href="/business/management/usageHistory"
              $locate={SelectNav.includes('/business/management/usageHistory')}
            >
              <span>이용내역</span>
            </S.Menu>
            <S.Menu href="/business/management/alert" $locate={SelectNav.includes('/business/management/alert')}>
              <span>알림 설정</span>
            </S.Menu>
          </S.Wrapper>
        </>
      )}
    </>
  )
}

export default BusinessSidebar
