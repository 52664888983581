import axios from 'axios'
import Router from 'next/router'
import Cookies from 'universal-cookie'
import Swal from 'sweetalert2'
import Config from 'configs/config.export'

axios.defaults.withCredentials = true
const cookies = new Cookies()

const instance = axios.create({
  baseURL: Config().baseUrl,
  timeout: 5000,
  headers: {
    'content-type': 'application/json;charset=UTF-8',
    accept: 'application/json',
  },
})

// Add Header AccessToken
instance.interceptors.request.use((config) => {
  config.headers!['Content-Type'] = 'application/json; charset=utf-8'
  config.headers!['Access-Control-Allow-Origin'] = '*'
  config.headers!['Access-Control-Allow-Credentials'] = true

  if (cookies.get('Wev_accessToken')) {
    const accessToken = cookies.get('Wev_accessToken')
    config.headers!['Authorization'] = `Bearer ${accessToken}`
  }

  if (cookies.get('wev.internationalization.language')) {
    const userLanguage = cookies.get('wev.internationalization.language')
    config.headers['Accept-Language'] = userLanguage
  }

  config.headers!.withCredentials = true
  return config
})

// Access Token | Refresh Token
instance.interceptors.response.use(
  (config) => {
    return config
  },
  async (error) => {
    const { config, response } = error
    const { status } = response
    const originalRequest = config
    const SelectNav = Router.asPath

    if (status === 401) {
      if (error.response.data.result_code === 'INVALID_TOKEN') {
        const refreshToken = await cookies?.get('Wev_refreshToken')

        if (!refreshToken) return

        const { data }: any = await axios
          .post(
            `${Config().baseUrl}auth/reissue`,
            {},
            {
              headers: {
                Authorization: `Bearer ${refreshToken}`,
              },
            },
          )
          .catch((error) => {
            //Refresh Token expired
            if (error.response.data.result_code === 'INVALID_TOKEN') {
              cookies.remove('Wev_accessToken', { path: '/' })
              cookies.remove('Wev_refreshToken', { path: '/' })
              localStorage.clear()

              if (
                SelectNav.includes('overview') ||
                SelectNav === '/' ||
                SelectNav.includes('/#') ||
                SelectNav.includes('wev') ||
                SelectNav.includes('service') ||
                SelectNav.includes('charger') ||
                SelectNav.includes('chargerInstall') ||
                SelectNav.includes('customer')
              ) {
                Swal.fire({
                  icon: 'warning',
                  html: '세션이 만료되었습니다.<br>재로그인이 필요합니다!',
                  showConfirmButton: false,
                  timer: 2000,
                }).then(() => window.location.reload())
              } else {
                const currentURL = Router.asPath
                sessionStorage.setItem('wev.authorization.currentUrl', currentURL)
                setTimeout(function () {
                  sessionStorage.removeItem('wev.authorization.currentUrl')
                }, 5 * 60 * 1000)
                Router.replace('/login')
                Swal.fire({
                  icon: 'warning',
                  html: '세션이 만료되었습니다.<br>재로그인이 필요합니다!',
                  showConfirmButton: false,
                  timer: 2000,
                })
              }
            }
          })

        //=>
        await cookies.set('Wev_accessToken', data.result_data.access_token, {
          path: '/',
          // secure: true,
          // httpOnly: true
        })
        await cookies.set('Wev_refreshToken', data.result_data.refresh_token, {
          path: '/',
          // secure: true,
          // httpOnly: true
        })

        axios.defaults.headers.common.Authorization = `Bearer ${data.result_data.access_token}`
        originalRequest.headers.Authorization = `Bearer ${data.result_data.access_token}`
        return axios(originalRequest)
        //<=
      }
      return Promise.reject(error)
    } else if (status === 504) {
      cookies.remove('Wev_accessToken', { path: '/' })
      cookies.remove('Wev_refreshToken', { path: '/' })
      localStorage.clear()

      if (
        SelectNav.includes('overview') ||
        SelectNav === '/' ||
        SelectNav.includes('/#') ||
        SelectNav.includes('wev') ||
        SelectNav.includes('service') ||
        SelectNav.includes('charger') ||
        SelectNav.includes('chargerInstall') ||
        SelectNav.includes('customer')
      ) {
        Swal.fire({
          icon: 'warning',
          html: '세션이 만료되었습니다.<br>재로그인이 필요합니다!',
          showConfirmButton: false,
          timer: 2000,
        }).then(() => window.location.reload())
      } else {
        const currentURL = Router.asPath
        sessionStorage.setItem('wev.authorization.currentUrl', currentURL)
        setTimeout(function () {
          sessionStorage.removeItem('wev.authorization.currentUrl')
        }, 5 * 60 * 1000)
        Router.replace('/login')
        Swal.fire({
          icon: 'warning',
          html: '세션이 만료되었습니다.<br>재로그인이 필요합니다!',
          showConfirmButton: false,
          timer: 2000,
        })
      }
      return Promise.reject(error)
    } else {
      return Promise.reject(error)
    }
  },
)

export default instance
