import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { userHistoryApis } from '@src/features/user/history/api'

const initialState = {
  checkUserPerClaim: null as CheckUserPerClaim | null,
}
export const userHistorySlice = createSlice({
  name: 'userHistorySlice',
  initialState,
  reducers: {
    setCheckUserPerClaim: (state, action) => {
      state.checkUserPerClaim = action.payload
    },
  },
})
export const { setCheckUserPerClaim } = userHistorySlice.actions

export default userHistorySlice.reducer

// -------------------------------------- //

export const getCheckPerClaim = createAsyncThunk('/user/history/getCheckPerClaim', async (data, thunkAPI) => {
  try {
    await userHistoryApis.getCheckPerClaim().then((res) => {
      const data = res.data.result_data

      if (!data) {
        thunkAPI.dispatch(setCheckUserPerClaim(null))
      } else {
        thunkAPI.dispatch(setCheckUserPerClaim(data))
      }
    })
  } catch (err) {}
})

type CheckUserPerClaim = {
  count: number
  claimCharge: {
    goodsName: string
    createdAt: string
    remainOutStandingAmount: number
    transactionId: string
  }[]

  amount: number
}
