import { IClaimChargeParams } from '@src/type/management/claimCharge'
import instance from '../axios'

export const claimChargeApis = {
  getClaminChargeList: (data: IClaimChargeParams) =>
    instance.get(
      `/management/claim-charge/list?list_num=${data.list}&p=${data.page}&is_member=${data.isMember}` +
        `${data.isMember && typeof data.status === 'number' ? `&status=${data.status}` : ''}`,
    ),
  paymentClaimCharge: (data: { id: number }) => instance.post(`/management/claim-charge/member-calculate`, data),
  refundClaimCharge: (data: { id: number }) => instance.post(`/management/claim-charge/non-member-part-cancel`, data),
}
