import styled from 'styled-components'

export const Sidebar = styled.aside`
  width: 300px;
  padding: 30px 60px;
  border-right: 1px solid ${({ theme }) => theme.wev_color.outline};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  > div {
    > h2 {
      margin-bottom: 30px;
      ${({ theme }) => theme.wev_font.Bold_28}
    }
    > ul {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
    }
    > ul > a {
      width: 100%;
    }
  }
`

export const SidebarItem = styled.li<{ isActiveStatus: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  border-radius: 10px;
  padding-left: 20px;
  width: 100%;
  height: 63px;
  background-color: ${({ isActiveStatus, theme }) =>
    isActiveStatus ? theme.wev_color.sub_btn_bg : theme.wev_color.sub_btn_white};
  color: ${({ isActiveStatus, theme }) => (isActiveStatus ? theme.wev_color.primary : theme.wev_color.hint)};
  > svg {
    fill: ${({ isActiveStatus, theme }) => (isActiveStatus ? theme.wev_color.primary : theme.wev_color.hint)};
  }
  :hover {
    background-color: ${({ theme }) => theme.wev_color.sub_btn_bg};
  }
`

export const LogoutButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  border-radius: 10px;
  padding-left: 20px;
  width: 100%;
  height: 63px;
  background-color: ${({ theme }) => theme.wev_color.sub_btn_white};
  color: ${({ theme }) => theme.wev_color.hint};
  > svg {
    fill: ${({ theme }) => theme.wev_color.hint};
  }
  :hover {
    color: ${({ theme }) => theme.wev_color.primary};
    background-color: ${({ theme }) => theme.wev_color.sub_btn_bg};
    > svg {
      fill: ${({ theme }) => theme.wev_color.primary};
    }
  }
`
