import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { BusinessPaymentApis } from '@src/api/business/payment'
import { IPaymentInfoCard, IPaymentInfoTax } from '@src/type/business'
import Router from 'next/router'

const initialState = {
  businessPaymentInfo: {} as IPaymentInfoCard | IPaymentInfoTax,
}

export const BusinessPaymentSlice = createSlice({
  name: 'businessPayment',
  initialState,
  reducers: {
    setBusinessPaymentInfo: (state, action) => {
      state.businessPaymentInfo = action.payload
    },
  },
})

export const { setBusinessPaymentInfo } = BusinessPaymentSlice.actions

export default BusinessPaymentSlice.reducer

export const getBusinessPayment = createAsyncThunk(
  '/business/menagement/payment/getBusinessPayment',
  async (data, thunkAPI) => {
    try {
      await BusinessPaymentApis.getPaymentInfo().then((res) => {
        if (res.data.result_code === 'OK') {
          const data = res.data.result_data
          thunkAPI.dispatch(setBusinessPaymentInfo(data))
        } else {
          thunkAPI.dispatch(setBusinessPaymentInfo({}))
        }
      })
    } catch (err: any) {}
  },
)
