import { IAuthorizationChargeBoxParams, ISetAuthorizationChargeBoxParams } from '@src/type/management/employee'
import instance from '../axios'

export const employeeApis = {
  getAuthorizationChargeBox: (data: IAuthorizationChargeBoxParams) =>
    instance.get(
      `/management/employee/get-authorization-charge-box-id?list_num=${data.list}&p=${data.page}` +
        `${data.name ? `&name=${data.name}` : ''}` +
        `${data.email ? `&email=${data.email}` : ''}` +
        `${data.chargeBoxId ? `&charge_box_id=${data.chargeBoxId}` : ''}` +
        `${data.memberId ? `&member_id=${data.memberId}` : ''}`,
    ),
  setAuthorizationChargeBox: (data: ISetAuthorizationChargeBoxParams) =>
    instance.post(`/management/employee/set-authorization-charge-box-id`, data),
  deleteAuthorizationChargeBox: (data: string) =>
    instance.delete(`/management/employee/delete-authorization-charge-box-id/${data}`),
}
