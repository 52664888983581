import { combineReducers, AnyAction } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'
import { AppState } from '../Store'
import notice from './customer/notice'
import member from './member'
import inquiry from './inquiry'
import manageMembership from './manageMembership'
import managementMember from './management/memberMain'
import managementCoupon from './management/coupon'
import coupon from './coupon'
import counseling from './counseling'
import selectMember from './management/selectMember'
import billingCardHistory from './management/billingCardHistory'
import businessJoin from './business/join'
import businessInfo from './business/info'
import businessMember from './business/member'
import businessPayment from './business/payment'
import businessMembership from './business/membership'
import businessUsageHistory from './business/usage'
import businessSettlement from './business/settlement'
import withDrawMember from './management/withDrawMember'
import employee from './management/employee'
import modal from './modal'
import loginLog from './management/loginLog'
import claimCharge from './management/claimCharge'
import guestPayment from './management/guestPayment'
import tempPaymentHistory from './management/paymentHistory'
import naverPaymentHistory from './management/naverPaymentHistory'
import kakaoPaymentHistory from './management/kakaoPaymentHistory'
import userInfo from './user/info'
import userMembership from './user/membership'
import userInquiry from './user/inquiry'
import userHistory from './user/history'
import unique from './s/unique'
import commons from './commons'

const rootReducer = combineReducers({
  selectMember,
  billingCardHistory,
  businessJoin,
  businessInfo,
  businessMember,
  businessPayment,
  businessMembership,
  businessUsageHistory,
  businessSettlement,
  managementMember,
  modal,
  employee,
  loginLog,
  notice,
  member,
  claimCharge,
  guestPayment,
  withDrawMember,
  tempPaymentHistory,
  naverPaymentHistory,
  kakaoPaymentHistory,
  inquiry,
  manageMembership,
  managementCoupon,
  coupon,
  counseling,
  userInfo,
  userMembership,
  userHistory,
  userInquiry,
  unique,
  commons,
})

export const reducer = (state: AppState, action: AnyAction) => {
  if (action.type === HYDRATE) {
    return {
      ...state,
      ...action.payload,
    }
  }
  return rootReducer(state, action)
}

export default rootReducer

export type RootState = ReturnType<typeof rootReducer>
