import { wev_color } from './property'
import { wev_font } from './property'
import { device } from './property'
import { clamp } from './property'
export const theme = {
  wev_color,
  wev_font,
  device,
  clamp,
}
