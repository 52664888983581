import instance from '@src/api/axios'
import {
  IAuthMembershipParams,
  ICheckMembershipStatusParams,
  IEditMembershipPWParams,
  IEditMembershipParams,
  IEditVPointPersonalizeParams,
  IIssueMembershipParams,
  IPointHistoryParams,
  IRegisterBillingCardParams,
  IRegisterCoupon,
  IReissueMembershipParams,
  IResetMembershipPWCodeParams,
  IResetMembershipPWEmailParams,
  IResetMembershipPWParams,
} from '../types'

export const userMembershipApis = {
  // vpoint
  getUserVPoint: () => instance.get(`/user/point/get`),
  getPointHistoryList: (data: IPointHistoryParams) =>
    instance.get(
      `/user/point/history-v2?list_num=10&p=${data.page}` +
        `${data.category ? `&category=${data.category}` : ''}` +
        `${data.start ? `&start_date_time=${data.start}` : ''}` +
        `${data.end ? `&end_date_time=${data.end}` : ''}`,
    ),
  registerCoupon: (data: IRegisterCoupon) => instance.post(`/user/point/register-coupon`, data),
  // billingCard
  getUserBillingCard: () => instance.get(`/user/billing/card-info`),
  registerBillingCard: (data: IRegisterBillingCardParams) => instance.post(`/user/billing/billing-key-register`, data),
  // membershipCard
  issueMembership: (data: IIssueMembershipParams) => instance.post(`/user/membership/new`, data),
  getUserMembership: () => instance.get(`/user/membership`),
  editMembership: (data: IEditMembershipParams) => instance.put(`/user/membership`, data),
  checkMembershipStatus: (data: ICheckMembershipStatusParams) => instance.post(`/user/membership/check`, data),
  authMembership: (data: IAuthMembershipParams) => instance.post(`/user/membership/auth`, data),
  resetMembershipPWEmail: (data: IResetMembershipPWEmailParams) =>
    instance.post(`/user/membership/request-card-password-reset`, data),
  resetMembershipPWCode: (data: IResetMembershipPWCodeParams) =>
    instance.post(`/user/membership/auth-card-password-reset-code`, data),
  resetMembershipPW: (data: IResetMembershipPWParams) => instance.post(`/user/membership/reset-card-password`, data),
  editMembershipPW: (data: IEditMembershipPWParams) => instance.put('/user/edit/card-password', data),
  reissueMembership: (data: IReissueMembershipParams) => instance.post(`/user/membership/reissue`, data),
  vpointPersonalize: () => instance.get(`/personalize?data_key=USE_CHARGING_POINT`),
  editVPointPersonalize: (data: IEditVPointPersonalizeParams) => instance.post('/personalize', data),
}
