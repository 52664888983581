import React, { useEffect, useState } from 'react'
import * as S from './ScrollTopBtn.styled'
import { Arrow_Upward } from '@src/assets/icons'
import dynamic from 'next/dynamic'
import { useResize } from '@src/hooks/useResize'

const ModalPortal = dynamic(() => import('../../modal/ModalPortal'), {
  ssr: false,
})

const ScrollTopBtn = () => {
  const [isVisible, setIsVisible] = useState(false)

  const { isMobile } = useResize()
  // 스크롤 이벤트 핸들러
  const handleScroll = () => {
    if (window.scrollY > 300) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  // 컴포넌트가 마운트될 때 스크롤 이벤트 리스너를 등록
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    // 컴포넌트가 언마운트될 때 스크롤 이벤트 리스너를 제거
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <ModalPortal>
      {isVisible && (
        <S.ButtonPosition>
          <S.UpButton type="button" onClick={scrollToTop}>
            <Arrow_Upward style={{ width: isMobile ? '16px' : '20px', height: isMobile ? '16px' : '20px' }} />
          </S.UpButton>
        </S.ButtonPosition>
      )}
    </ModalPortal>
  )
}

export default ScrollTopBtn
