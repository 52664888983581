import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { Keyboard_Arrow_Right as Expand } from '@src/assets/icons'
import { wev_color } from '@src/styles/property'
import Link from 'next/link'
import { MapItem } from './constants'
import { useAppDispatch, useAppSelector } from '@src/hooks/redux'
import { nanoid } from '@reduxjs/toolkit'

interface IProps {
  category: string
  links: MapItem[]
  onClick: VoidFunction
}

const SitemapMobile = (props: IProps) => {
  const newUserInfo = useAppSelector((state) => state.userInfo.userInfoMasking)
  const { category, links, onClick } = props

  return (
    <Accordion
      disableGutters
      sx={{
        color: '#fff',
        boxShadow: 0,
        '.Mui-expanded': {
          color: wev_color.gradation_l,
          textDecoration: 'underline',
          textUnderlineOffset: 6,
          textDecorationThickness: 1.5,
        },
        fontFamily: 'Pretendard, sans-serif',
        fontWeight: 600,
      }}
    >
      <AccordionSummary
        expandIcon={
          <div className="-rotate-90">
            <Expand fill="#fff" />
          </div>
        }
        sx={{
          bgcolor: wev_color.primary,
          borderBottom: `1px solid #FFFFFF40`,
        }}
      >
        {category}
      </AccordionSummary>
      {links.map(({ label, href }, idx) => (
        <>
          <Link key={nanoid()} href={href} onClick={onClick}>
            <AccordionDetails
              sx={{
                bgcolor: '#422ACA',
                px: '1rem',
                pt: !idx ? '1rem' : '0.5rem',
                pb: idx === links.length - 1 ? '1rem' : '0.5rem',
                fontSize: 14,
                fontWeight: 100,
              }}
            >
              {label}
            </AccordionDetails>
          </Link>
          {/* )} */}
        </>
      ))}
    </Accordion>
  )
}

export default SitemapMobile
