import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { userMembershipApis } from '@src/features/user/membership/api'
import { IMembershipData } from '@src/features/user/membership/types'

const initialState = {
  userMembershipList: null as null | IMembershipData[],
  userBillingCard: {} as UserBillingCardType,
  userVPoint: null as number | null,
  clickedMembershipInfo: {} as IMembershipData,
}
export const userMembershipSlice = createSlice({
  name: 'userMembershipSlice',
  initialState,
  reducers: {
    setUserBillingCard: (state, action) => {
      state.userBillingCard = action.payload
    },
    setUserVPoint: (state, action) => {
      state.userVPoint = action.payload
    },

    setUserMembershipList: (state, action) => {
      state.userMembershipList = action.payload
    },
    setClickedMembershipInfo: (state, action) => {
      state.clickedMembershipInfo = action.payload
    },
  },
})
export const { setUserMembershipList, setUserBillingCard, setUserVPoint, setClickedMembershipInfo } =
  userMembershipSlice.actions

export default userMembershipSlice.reducer

// -------------------------------------- //

export const getUserMembership = createAsyncThunk('/user/membership/getUserMembership', async (data, thunkAPI) => {
  try {
    await userMembershipApis.getUserMembership().then((res) => {
      const data = res.data.result_data
      if (!data) {
        thunkAPI.dispatch(setUserMembershipList(null))
      } else {
        thunkAPI.dispatch(setUserMembershipList(data))
      }
    })
  } catch (err) {}
})

export const getUserBillingCard = createAsyncThunk('/user/membership/getUserBillingCard', async (data, thunkAPI) => {
  try {
    await userMembershipApis.getUserBillingCard().then((res) => {
      const data = res.data.result_data
      if (!data) {
        thunkAPI.dispatch(setUserBillingCard(null))
      } else {
        thunkAPI.dispatch(setUserBillingCard(data))
      }
    })
  } catch (err) {}
})

export const getUserVPoint = createAsyncThunk('/user/membership/getUserVPoint', async (data, thunkAPI) => {
  try {
    await userMembershipApis.getUserVPoint().then((res) => {
      const data = res.data.result_data
      thunkAPI.dispatch(setUserVPoint(data))
    })
  } catch (err) {}
})

type UserBillingCardType = { pg_type: number; card_number: string; card_point: number; card_name: string }
