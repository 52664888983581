import { IBillingCardHistoryParams } from '@src/type/management/billingCardHistory'
import instance from '../axios'
import { ICancelPaymentParams, IEditRefundParams, INotUsedUidParams } from '@src/type/management/guestPayment'
import { INaverCancelPaymentParams, INaverPaymentHistoryParams } from '@src/type/management/naverPaymentHistory'
import { IPaymentHistoryParams } from '@src/type/management/paymentHistory'

export const paymentApis = {
  getPaymentHistoryList: (data: IPaymentHistoryParams) =>
    instance.get(
      `/management/payment/history?list_num=${data.list}&p=${data.page}` +
        `${data.start ? `&start_date_time=${data.start}` : ''}` +
        `${data.end ? `&end_date_time=${data.end}` : ''}` +
        `${data.category && data.query ? `&category=${data.category}` : ''}` +
        `${data.query ? `&query=${data.query}` : ''}` +
        `${data.goodsId ? `&goods_id=${data.goodsId}` : ''}`,
    ),
  // goods_id !== 2인 경우에 상세 조회
  getPaymentHistoryListDetail: (id: string) => instance.get(`/management/payment/select-history?id=${id}`),
  // goods_id === 2인 경우에 상세 조회
  getPaymentHistoryDetail: (id: string) => instance.get(`/management/payment/membership-per-history?id=${id}`),
  // 미수금 내역(자식창) 단일 조회
  getPaymentHistoryDetailSingle: (id: string) => instance.get(`/management/payment/history/${id}`),
  getNaverPaymentHistoryList: (data: INaverPaymentHistoryParams) =>
    instance.get(
      `/management/payment/history/naver?list_num=${data.list}&p=${data.page}&start_date_time=${data.start}&end_date_time=${data.end}` +
        `${data.type ? `&approval_type=${data.type}` : ''}`,
    ),
  getBillingCardHistory: (data: IBillingCardHistoryParams) =>
    instance.get(
      `/management/payment/billing-card-history?list_num=${data.list}&p=${data.page}` +
        `${data.name ? `&name=${data.name}` : ''}` +
        `${data.email ? `&email=${data.email}` : ''}` +
        `${data.memberId ? `&member_id=${data.memberId}` : ''}`,
    ),
  getNotUsedUidList: (data: INotUsedUidParams) =>
    instance.get(
      `/management/payment/not-used-uid-list?list_num=${data.list}&p=${data.page}&refund=${data.refund}` +
        `${data.phoneNumber ? `&phoneNumber=${data.phoneNumber}` : ''}` +
        `${data.name ? `&name=${data.name}` : ''}`,
    ),
  refundPayment: (data: { usedShortUrlId: number }) => instance.post(`/management/payment/non-member-refund`, data),
  editRefund: (data: IEditRefundParams) => instance.put('/management/payment/update-refund-status', data),
  cancelPayment: (data: ICancelPaymentParams) => instance.post('/management/payment/cancel', data),
  naverCancelPayment: (data: INaverCancelPaymentParams) => instance.post('/management/payment/cancel/naver', data),
  getKakaoPaymentDetail: (data: string) => instance.get(`/management/payment/history/kakaopay?pg_tid=${data}`),
}
