import getConfigs from './config.common'

const publicUrl = 'https://wev-charger.com/'
const baseUrl = 'https://api.wev-charger.com/v1/'
const mode = 'production'
const recaptcha = '6LeGO0YkAAAAANuTmmPSTceOUIZZ__SQQIYEctcg'

const configProduction = getConfigs({
  publicUrl,
  baseUrl,
  mode,
  recaptcha,
})

export default configProduction
