import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { BusinessMemberApis } from '@src/api/business/member'
import {
  IBusinessMemberInfo,
  IBusinessMemberList,
  IBusinessMemberListParam,
  IBusinessMemberMembership,
  IBusinessMembershipListParam,
  IBusinessMembershipModalList,
} from '@src/type/business'

const initialState = {
  businessMemberList: {} as IBusinessMemberList,
  businessMemberFilter: {
    emailFilter: null,
    keyword: '',
    hasMembership: null,
    page: 1,
    pageSize: 5,
    recordSize: 15,
    roleFilter: null,
  } as IBusinessMemberListParam,
  businessMemberInfo: {} as IBusinessMemberInfo,
  businessMemberMembershipList: [] as IBusinessMemberMembership[],
  businessMembershipModalList: {} as IBusinessMembershipModalList,
  memberHasMembershipList: [] as number[],
}

export const BusinessMemberSlice = createSlice({
  name: 'businessMember',
  initialState,
  reducers: {
    setBusinessMemberList: (state, action) => {
      state.businessMemberList = action.payload
    },
    setBusinessMemberFilter: (state, action) => {
      state.businessMemberFilter = action.payload
    },
    setBusinessMemberInfo: (state, action) => {
      state.businessMemberInfo = action.payload
    },
    setBusinessMemberMembershipList: (state, action) => {
      state.businessMemberMembershipList = action.payload
    },
    setBusinessMembershipModalList: (state, action) => {
      state.businessMembershipModalList = action.payload
    },
    setMemberHasMembershipList: (state, action) => {
      state.memberHasMembershipList = action.payload
    },
  },
})

export const {
  setBusinessMemberList,
  setBusinessMemberFilter,
  setBusinessMemberInfo,
  setBusinessMemberMembershipList,
  setBusinessMembershipModalList,
  setMemberHasMembershipList,
} = BusinessMemberSlice.actions

export default BusinessMemberSlice.reducer

export const getBusinessMemberList = createAsyncThunk(
  '/business/menagement/member/getBusinessMemberList',
  async (data: IBusinessMemberListParam, thunkAPI) => {
    try {
      await BusinessMemberApis.getMemberList(data).then((res) => {
        const businessMemberData = res.data.result_data

        thunkAPI.dispatch(setBusinessMemberList(businessMemberData))
      })
    } catch (err) {}
  },
)
export const getBusinessMemberInfo = createAsyncThunk(
  '/business/menagement/member/getBusinessMemberInfo',
  async (data: string, thunkAPI) => {
    try {
      await BusinessMemberApis.getMemberInfo(data).then((res) => {
        if (res.data.result_code === 'OK') {
          thunkAPI.dispatch(setBusinessMemberInfo(res.data.result_data.member))
          thunkAPI.dispatch(setBusinessMemberMembershipList(res.data?.result_data.membership))
          thunkAPI.dispatch(
            setMemberHasMembershipList(res.data?.result_data.membership.map((el: IBusinessMemberMembership) => el.id)),
          )
        }
      })
    } catch (err) {}
  },
)

export const getBusinessMembershipModalList = createAsyncThunk(
  '/business/menagement/member/getBusinessMembershipList',
  async (data: IBusinessMembershipListParam, thunkAPI) => {
    try {
      await BusinessMemberApis.getMembershipModalList(data).then((res) => {
        if (res.data.result_code === 'OK') {
          thunkAPI.dispatch(setBusinessMembershipModalList(res.data.result_data))
        }
      })
    } catch (err) {}
  },
)
