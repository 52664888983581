import { useResponsive } from '@src/hooks/useResponsive'
import { useRouter } from 'next/router'
import React from 'react'
import UserSubHeader from './userSubHeader/UserSubHeader'
import ManagementSubHeader from './managementSubHeader/ManagementSubHeader'
import BusinessSubHeader from './businessSubHeader/BusinessSubHeader'

const MobileSubHeader = () => {
  const { asPath } = useRouter()
  const { isTablet } = useResponsive(1280)
  if (!isTablet) {
    return null
  } else {
    if (asPath.startsWith('/user')) {
      return <UserSubHeader />
    } else if (asPath.startsWith('/management')) {
      return <ManagementSubHeader />
    } else if (asPath.startsWith('/business') && !asPath.includes('about')) {
      return <BusinessSubHeader />
    } else {
      return null
    }
  }
}

export default MobileSubHeader
