import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { manageMembershipApis } from '@src/api/manageMembership'
import { MemberListPaging } from './member'
import Swal from 'sweetalert2'
import { Router } from 'next/router'
import { IMembershipSearchParam } from '@src/type/manageMembership'

const initialState = {
  membershipList: [],
  membershipSelect: [],
  membershipListPaging: {},
  membershipshipSelect: {},
  totalPage: 0,
  currentPage: 1,
  currentCategory: null,
  searchCategory: null,
  searchKeyword: null,
  maxPageNumberLimit: 5,
  minPageNumberLimit: 0,
  cardNumber: '',
  cardStatus: null,
  cardReason: '',
  cardType: '',
  currentID: '',
  membershipCardID: '',
  messageOK: '',
  membershipCopyList: [],
}

export const membershipSelectData = createAsyncThunk('/membership/select', async (id: number, thunkAPI) => {
  try {
    await manageMembershipApis.memberSelect(id).then((res) => {
      thunkAPI.dispatch(setMemberShipSelect(res.data?.result_data.member))
    })
  } catch (err) {}
})

// ------------------
export const membershipSearchData = createAsyncThunk(
  '/membership/search',
  async (data: IMembershipSearchParam, thunkAPI) => {
    try {
      await manageMembershipApis.membershipSearchList(data).then((res) => {
        thunkAPI.dispatch(setMembershipList(res.data?.result_data.records))
        thunkAPI.dispatch(setTotalPage(res.data?.result_data.total_page))
        const copy = []
        for (let i = 0; i < res.data?.result_data.records?.length; i += 1) {
          copy.push({
            이름: res.data?.result_data.records[i].membership.name,
            이메일: res.data?.result_data.records[i].membership.email,
            생년월일: res.data?.result_data.records[i].membership.birthday,
            연락처: res.data?.result_data.records[i].membership.phone_number,
            카드번호: res.data?.result_data.records[i].membership.card_number,
            대표카드: res.data?.result_data.records[i].membership.is_parent === '0' ? 'X' : 'O',
            카드상태:
              res.data?.result_data.records[i].membership?.card_status === 0
                ? '신청접수'
                : res.data?.result_data.records[i].membership?.card_status === 1
                ? '배송중'
                : res.data?.result_data.records[i].membership?.card_status === 2
                ? '발급완료(이용가능)'
                : res.data?.result_data.records[i].membership?.card_status === 3
                ? '발급지연'
                : res.data?.result_data.records[i].membership?.card_status === 4
                ? '발급반려'
                : res.data?.result_data.records[i].membership?.card_status === 5
                ? '이용정지'
                : res.data?.result_data.records[i].membership?.card_status === 6
                ? '일시정지'
                : res.data?.result_data.records[i].membership?.card_status === 7
                ? '분실신고'
                : res.data?.result_data.records[i].membership?.card_status === 8
                ? '만료됨'
                : res.data?.result_data.records[i].membership?.card_status === 9
                ? '재발급'
                : res.data?.result_data.records[i].membership?.card_status === 10
                ? '회원 이용정지'
                : res.data?.result_data.records[i].membership?.card_status === 11
                ? '미수금 이용정지'
                : null,
            차번호: res.data?.result_data.records[i].membership?.car_number,
            CVC: res.data?.result_data.records[i].membership?.card_cvc,
            카드타입:
              res.data?.result_data.records[i].membership?.card_type === 1 &&
              res.data?.result_data.records[i].membership?.card_status !== 1
                ? 'uni(유니파잉)'
                : res.data?.result_data.records[i].membership?.card_type === 2
                ? 'tmoney(티머니)'
                : res.data?.result_data.records[i].membership?.card_type === 3
                ? 'sscc(서울신교통카드)'
                : res.data?.result_data.records[i].membership?.card_type === 4
                ? 'korail(코레일/레일플러스)'
                : res.data?.result_data.records[i].membership?.card_type === 5 ||
                  res.data?.result_data.records[i].membership?.card_status === 1
                ? 'wev-real-parkingthru(파킹스루)'
                : res.data?.result_data.records[i].membership?.card_type === 6
                ? 'wev-app(앱카드)'
                : null,
            배송지:
              res.data?.result_data.records[i].membership?.card_address +
              ' ' +
              res.data?.result_data.records[i].membership?.card_address_detail,
            '환경부 최신 업데이트': res.data?.result_data.records[i]?.meUpdatedAt
              ? new Date(
                  `${res.data?.result_data.records[i]?.meUpdatedAt?.replace('T', ' ')?.split('.')[0]}+00:00`,
                ).toLocaleString('sv') + '+09:00'
              : '',
            생성일자:
              new Date(
                `${res.data?.result_data.records[i]?.membership?.created_at?.replace('T', ' ')?.split('.')[0]}+00:00`,
              ).toLocaleString('sv') + '+09:00',

            '최근 수정 일자':
              new Date(
                `${res.data?.result_data.records[i]?.membership?.updated_at?.replace('T', ' ')?.split('.')[0]}+00:00`,
              ).toLocaleString('sv') + '+09:00',
            환경부등록: res.data?.result_data.records[i]?.membership?.me_update ? '대상' : '비대상',
          })
        }
        thunkAPI.dispatch(setMemberCopyList(copy))
      })
    } catch (err) {}
  },
)
export const mebershipMeUpdate = createAsyncThunk(
  '/membership/mebershipMeUpdate',
  async (data: { cardNumber: string; status: string }[], thunkAPI) => {
    try {
      await manageMembershipApis.memberMeUpdate(data).then((res) => {
        if (res.data.result_code === 'OK') {
          Swal.fire({
            icon: 'success',
            text: '등록되었습니다!',
            showConfirmButton: false,
            timer: 2000,
          }).then(async () => {
            await manageMembershipApis.membershipList(1).then((res) => {
              thunkAPI.dispatch(setMembershipList(res.data?.result_data.records))
              const copy = []
              for (let i = 0; i < res.data?.result_data.records?.length; i += 1) {
                copy.push({
                  이름: res.data?.result_data.records[i].membership.name,
                  이메일: res.data?.result_data.records[i].membership.email,
                  생년월일: res.data?.result_data.records[i].membership.birthday,
                  연락처: res.data?.result_data.records[i].membership.phone_number,
                  카드번호: res.data?.result_data.records[i].membership.card_number,
                  대표카드: res.data?.result_data.records[i].membership.is_parent === '0' ? 'X' : 'O',
                  카드상태:
                    res.data?.result_data.records[i].membership?.card_status === 0
                      ? '신청접수'
                      : res.data?.result_data.records[i].membership?.card_status === 1
                      ? '배송중'
                      : res.data?.result_data.records[i].membership?.card_status === 2
                      ? '발급완료(이용가능)'
                      : res.data?.result_data.records[i].membership?.card_status === 3
                      ? '발급지연'
                      : res.data?.result_data.records[i].membership?.card_status === 4
                      ? '발급반려'
                      : res.data?.result_data.records[i].membership?.card_status === 5
                      ? '이용정지'
                      : res.data?.result_data.records[i].membership?.card_status === 6
                      ? '일시정지'
                      : res.data?.result_data.records[i].membership?.card_status === 7
                      ? '분실신고'
                      : res.data?.result_data.records[i].membership?.card_status === 8
                      ? '만료됨'
                      : res.data?.result_data.records[i].membership?.card_status === 9
                      ? '재발급'
                      : res.data?.result_data.records[i].membership?.card_status === 10
                      ? '회원 이용정지'
                      : res.data?.result_data.records[i].membership?.card_status === 11
                      ? '미수금 이용정지'
                      : null,
                  차번호: res.data?.result_data.records[i].membership?.car_number,
                  CVC: res.data?.result_data.records[i].membership?.card_cvc,
                  카드타입:
                    res.data?.result_data.records[i].membership?.card_type === 1 &&
                    res.data?.result_data.records[i].membership?.card_status !== 1
                      ? 'uni(유니파잉)'
                      : res.data?.result_data.records[i].membership?.card_type === 2
                      ? 'tmoney(티머니)'
                      : res.data?.result_data.records[i].membership?.card_type === 3
                      ? 'sscc(서울신교통카드)'
                      : res.data?.result_data.records[i].membership?.card_type === 4
                      ? 'korail(코레일/레일플러스)'
                      : res.data?.result_data.records[i].membership?.card_type === 5 ||
                        res.data?.result_data.records[i].membership?.card_status === 1
                      ? 'wev-real-parkingthru(파킹스루)'
                      : res.data?.result_data.records[i].membership?.card_type === 6
                      ? 'wev-app(앱카드)'
                      : null,
                  배송지:
                    res.data?.result_data.records[i].membership?.card_address +
                    ' ' +
                    res.data?.result_data.records[i].membership?.card_address_detail,
                  '환경부 최신 업데이트': res.data?.result_data.records[i]?.meUpdatedAt
                    ? new Date(
                        `${res.data?.result_data.records[i]?.meUpdatedAt?.replace('T', ' ')?.split('.')[0]}+00:00`,
                      ).toLocaleString('sv') + '+09:00'
                    : '',
                  생성일자:
                    new Date(
                      `${
                        res.data?.result_data.records[i]?.membership?.created_at?.replace('T', ' ')?.split('.')[0]
                      }+00:00`,
                    ).toLocaleString('sv') + '+09:00',

                  '최근 수정 일자':
                    new Date(
                      `${
                        res.data?.result_data.records[i]?.membership?.updated_at?.replace('T', ' ')?.split('.')[0]
                      }+00:00`,
                    ).toLocaleString('sv') + '+09:00',
                  환경부등록: res.data?.result_data.records[i]?.membership?.me_update ? '대상' : '비대상',
                })
              }
              thunkAPI.dispatch(setMemberCopyList(copy))
              thunkAPI.dispatch(setTotalPage(res.data?.result_data.total_page))

              const memberListPagingData: MemberListPaging = {
                currentPage: res.data.result_data.current_page,
                endPage: res.data.result_data.end_page,
                nowBlock: res.data.result_data.now_block,
                startPage: res.data.result_data.start_page,
                totalBlock: res.data.result_data.total_block,
                totalPage: res.data.result_data.total_page,
              }

              thunkAPI.dispatch(setMembershipPaging(memberListPagingData))
            })
          })
        }
      })
    } catch (err) {}
  },
)

export const ManageMembershipSlice = createSlice({
  name: 'manageMembership',
  initialState,
  reducers: {
    setMembershipList: (state, action) => {
      state.membershipList = action.payload
    },
    setMemberCopyList: (state, action) => {
      state.membershipCopyList = action.payload
    },
    setMembershipPaging: (state, action) => {
      state.membershipListPaging = action.payload
    },
    setTotalPage: (state, action) => {
      state.totalPage = action.payload
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setMaxPageNumberLimit: (state, action) => {
      state.maxPageNumberLimit = action.payload
    },
    setMinPageNumberLimit: (state, action) => {
      state.minPageNumberLimit = action.payload
    },
    setCardNumber: (state, action) => {
      state.cardNumber = action.payload
    },
    setCardStatus: (state, action) => {
      state.cardStatus = action.payload
    },
    setCardReason: (state, action) => {
      state.cardReason = action.payload
    },
    setCardType: (state, action) => {
      state.cardType = action.payload
    },
    setCurrentID: (state, action) => {
      state.currentID = action.payload
    },
    setMembershipCardID: (state, action) => {
      state.membershipCardID = action.payload
    },
    setMemberShipSelect: (state, action) => {
      state.membershipSelect = action.payload
    },
    setMessageOK: (state, action) => {
      state.messageOK = action.payload
    },
  },
})

export const {
  setMembershipList,
  setMemberCopyList,
  setTotalPage,
  setMembershipPaging,
  setCurrentPage,
  setMaxPageNumberLimit,
  setMinPageNumberLimit,
  setCardNumber,
  setCardStatus,
  setCardReason,
  setCardType,
  setCurrentID,
  setMembershipCardID,
  setMemberShipSelect,
  setMessageOK,
} = ManageMembershipSlice.actions

export default ManageMembershipSlice.reducer
