/* eslint-disable */
import { AppProps } from 'next/app'
import '../styles/global.css'
import '../styles/main.css'
import '@src/styles/tailwind.css'
import '@src/styles/toastify.css'
import '@src/styles/index.css'
import React, { useEffect } from 'react'
import wrapper from '@src/store/Store'
import { useRouter } from 'next/router'
import Cookies from 'universal-cookie'
import Script from 'next/script'
import jwtDecode from 'jwt-decode'
import { ThemeProvider } from 'styled-components'
import Layout from '@src/layouts'
import { theme } from '@src/styles/theme'
import { ToastContainer } from 'react-toastify'
import 'swiper/css/bundle'
import { appWithTranslation } from 'next-i18next'
import RootModal from '@src/components/commons/modal/RootModal'
import * as gtag from '../utils/gtag'
export interface Token {
  roles: string
}

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter()
  const SelectNav = router.asPath

  const cookies = new Cookies()
  let role = ''

  useEffect(() => {
    if (
      (SelectNav.includes('user') && SelectNav !== '/user/my_info' && SelectNav.indexOf('find') === -1) ||
      SelectNav.includes('billing') ||
      SelectNav.includes('membership') ||
      SelectNav.includes('management')
    ) {
      if (SelectNav.includes('management') && cookies.get('Wev_accessToken')) {
        let jwt = jwtDecode<Token>(cookies.get('Wev_accessToken'))
        role = jwt.roles
        if (role === 'USER') {
          router.replace('/')
        }
      } else if (!cookies.get('Wev_accessToken')) {
        const currentURL = router.asPath
        sessionStorage.setItem('wev.authorization.currentUrl', currentURL)
        setTimeout(function () {
          sessionStorage.removeItem('wev.authorization.currentUrl')
        }, 5 * 60 * 1000)
        router.push(`/login`)
      }
    }
  }, [])

  //google analytics
  useEffect(() => {
    const handleRouteChange = (url: any) => {
      // gtag.pageview_1(url)
      gtag.pageview_2(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    router.events.on('hashChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
      router.events.off('hashChangeComplete', handleRouteChange)
    }
  }, [router.events])

  //Naver Log
  useEffect(() => {
    if (!wcs_add) {
      var wcs_add = {}
    }
    wcs_add['wa'] = '1ecfd63cab7b790'
    if (window?.wcs) {
      wcs_do()
    }
  }, [router])

  // 메인 body 스크롤 미노출
  useEffect(() => {
    if (router.pathname === '/') {
      document.body.classList.add('scroll-hide')
    } else {
      document.body.classList.remove('scroll-hide')
    }
  }, [router.pathname])
  useEffect(() => {
    if (router.locale === 'jp') {
      document.body.classList.add('lang-ja')
    } else {
      document.body.classList.remove('lang-ja')
    }
  }, [router.locale])

  return (
    <>
      {/* for dev */}
      <Script
        strategy="beforeInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID_2}`}
      />
      <Script
        id="gtag-init"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID_2}', {
              page_path: window.location.pathname,
            });
          `,
        }}
        async
      />

      <Script src="//wcs.naver.net/wcslog.js" strategy="beforeInteractive" />
      <Script
        type="text/javascript"
        strategy="beforeInteractive"
        src={`https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=${process.env.NEXT_PUBLIC_CLIENT_ID}`}
      ></Script>

      <ToastContainer />
      <ThemeProvider theme={theme}>
        <RootModal />
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </ThemeProvider>
    </>
  )
}

export default wrapper.withRedux(appWithTranslation(App))
