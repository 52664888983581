/* input 형식 : 2023-11-28T02:12:58.000000Z */
/* ouput 형식 : 2023-10-01 14:27:29 */

import { formatMommentToLocal, formatMommentToUtc } from './dateFormat'

export function convertUtcToZone(utcDateTimeString: string, timeZone?: string | undefined) {
  if (!utcDateTimeString) return ''
  const moment = require('moment-timezone')
  const defaultZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const zone = timeZone ? timeZone : defaultZone

  return formatMommentToLocal(moment.tz(utcDateTimeString, zone).format())
}

/* input 형식 : 2024-03-27T16:51:01, 'Asia/Seoul' */
/* ouput 형식 : 2024-03-27T07:51:01.000Z */

export function convertZoneToUtc(zoneDateTimeString: string, timeZone?: string | undefined) {
  if (!zoneDateTimeString) return ''
  const moment = require('moment-timezone')
  const defaultZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const zone = timeZone ? timeZone : defaultZone
  // UTC로 변환된 시간을 포맷화하여 반환
  return formatMommentToUtc(moment.tz(zoneDateTimeString, zone).format())
}
