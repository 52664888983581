import instance from '@src/api/axios'
import { ICreateInquiryParams, IEditInquiryParams } from '../types'

export const userInquiryApis = {
  getUserInquiryList: (data: number) => instance.get(`/user/inquiry?listNum=10&p=${data}`),
  getUserInquiryDetail: (data: number) => instance.get(`/user/inquiry/${data}`),
  createInquiry: (data: ICreateInquiryParams) => instance.post(`/user/inquiry`, data),
  closeInquiry: (data: number) => instance.patch(`/user/inquiry/${data}`, { answer: 2 }),
  editInquiry: (data: IEditInquiryParams) =>
    instance.patch(`/user/inquiry/${data.id}`, {
      title: data.title,
      category: data.category,
      content: data.content,
      removeAttaches: data.removeAttaches,
    }),
}
