import instance from '@src/api/axios'
import {
  IAuthLoginParams,
  IAuthMFALoginParams,
  IAuthSignUp,
  IFindIDParams,
  IFindIDVerifyParams,
  IFindPWByUserInfoParams,
  ILinkKakao,
  IRecoveryMFALogin,
  IResetPWParams,
  ISocialParams,
  IVerifyUnlockAccountCode,
} from '../types'

export const accountApis = {
  logout: () => instance.post('/auth/logout'),
  authLogin: (data: IAuthLoginParams) => instance.post(`/auth/login`, data),
  authMFALogin: (data: IAuthMFALoginParams) => instance.post(`/auth/login/mfa/totp`, data), // recovery code와 동일하게 쓰임 (MFARecovery)
  recoveryMFALogin: (data: IRecoveryMFALogin) => instance.post(`/auth/login/mfa/recovery`, data),
  sendUnlockAccountEmail: (email: string) => instance.post('/auth/unlock-account-email-send', { email: email }),
  authRequest: () => instance.get(`/auth/request`),
  verifyUnlockAccountCode: (data: IVerifyUnlockAccountCode) => instance.post('/auth/unlock-account', data),
  authSignUp: (data: IAuthSignUp) => instance.post('/auth/register', data),
  confirmEmailSend: () => instance.post('/user/confirm-email-send', {}),
  confirmEmail: (data: string) => instance.post(`/user/register-confirm-email/${data}`, {}),
  withdraw: (data: string) => instance.post('/user/withdraw', { password: data }),
  socialWithdraw: (data: ISocialParams) => instance.post('/user/withdraw', data),
  checkPassword: (data: string) => instance.put('/user/edit/confirm-password', { password: data }),
  checkSocialToken: (data: ISocialParams) => instance.put('/user/edit/confirm-password', data),
  editPW: (data: string) => instance.put(`/user/edit/info`, { new_password: data }),
  findID: (data: IFindIDParams) => instance.post(`/auth/find-id`, data),
  findIDVerify: (data: IFindIDVerifyParams) => instance.post(`/auth/find-id/verify`, data),
  findPWByVerify: (data: string) => instance.get(`/auth/find-password?trade_id=${data}`),
  findPWByUserInfo: (data: IFindPWByUserInfoParams) => instance.post(`/auth/find-password`, data),
  checkResetPWLink: (data: string) => instance.post(`/auth/change-password/${data}`, {}),
  resetPW: (data: IResetPWParams) => instance.post(`/auth/change-password/${data.query}`, { password: data.password }),
  linkKakao: (data: ILinkKakao) => instance.post('/social/kakao/link-existing-account', data),
}
