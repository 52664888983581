import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import * as S from './BusinessSubHeader.styled'

const BusinessSubHeader = () => {
  const router = useRouter()
  const BUSINESS_MENU = [
    { label: '비즈니스 정보', href: '/business/management/info' },
    { label: '멤버 관리', href: '/business/management/member' },
    { label: '멤버십 카드 관리', href: '/business/management/cardMembership' },
    { label: '이용내역', href: '/business/management/usageHistory' },
    { label: '정산내역', href: '/business/management/settlement' },
    { label: '결제수단 관리', href: '/business/management/payment' },
    { label: '알림 설정', href: '/business/management/alert' },
  ]
  return (
    <S.SubHeader>
      <ul>
        {BUSINESS_MENU.map((el) => (
          <S.Item isActiveStatus={router.asPath.startsWith(el.href as string)}>
            <Link href={el.href}>{el.label}</Link>
          </S.Item>
        ))}
      </ul>
    </S.SubHeader>
  )
}

export default BusinessSubHeader
