import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { paymentApis } from '@src/api/management/payment'
import {
  IPaymentHistoryParams,
  IPaymentHistoryList,
  IPaymentHistoryDetailParams,
  ISelectPaymentDetail,
} from '@src/type/management/paymentHistory'

const initialState = {
  paymentList: {} as IPaymentHistoryList,
  paymentListFilter: {
    page: 1,
    list: 15,
    start: '',
    end: '',
    goodsId: 2,
    category: 'tid',
    query: undefined,
  },
  paymentHistoryDetail: [] as ISelectPaymentDetail[],
}
export const paymentHistorySlice = createSlice({
  name: 'paymentHistory',
  initialState,
  reducers: {
    setPaymentHistoryDetail: (state, action) => {
      state.paymentHistoryDetail = action.payload
    },
    setPaymentListFilter: (state, action) => {
      state.paymentListFilter = action.payload
    },
    setPaymentList: (state, action) => {
      state.paymentList = action.payload
    },
  },
})
export const { setPaymentHistoryDetail, setPaymentListFilter, setPaymentList } = paymentHistorySlice.actions

export default paymentHistorySlice.reducer

// -------------------------------------- //

export const getPaymentHistoryList = createAsyncThunk(
  '/management/paymentHistory/getPaymentHistoryList',
  async (data: IPaymentHistoryParams, thunkAPI) => {
    try {
      await paymentApis.getPaymentHistoryList(data).then((res) => {
        const data = res.data.result_data
        thunkAPI.dispatch(setPaymentList(data))
      })
    } catch (err) {}
  },
)

export const getPaymentHistoryDetail = createAsyncThunk(
  '/management/paymentHistory/getPaymentHistoryDetail',
  async (data: IPaymentHistoryDetailParams, thunkAPI) => {
    try {
      if (data.goodsId === 2) {
        await paymentApis.getPaymentHistoryDetail(data.id).then((res) => {
          const data = res.data.result_data
          if (data) {
            thunkAPI.dispatch(setPaymentHistoryDetail(data))
          } else {
            thunkAPI.dispatch(setPaymentHistoryDetail([]))
          }
        })
      } else {
        await paymentApis.getPaymentHistoryListDetail(data.id).then((res) => {
          const data = res.data.result_data
          thunkAPI.dispatch(setPaymentHistoryDetail(data))
        })
      }
    } catch (err) {}
  },
)

export const getPaymentHistoryDetailSingle = createAsyncThunk(
  '/management/paymentHistory/getPaymentHistoryDetailSingle',
  async (data: string, thunkAPI) => {
    try {
      await paymentApis.getPaymentHistoryDetailSingle(data).then((res) => {
        const data = res.data.result_data
        if (data) {
          thunkAPI.dispatch(setPaymentHistoryDetail([data]))
        } else {
          thunkAPI.dispatch(setPaymentHistoryDetail([]))
        }
      })
    } catch (err) {}
  },
)
