import { setIsRightSidebarOpen } from '@src/store/modules/commons'
import { wev_color } from '@src/styles/property'
import { routeBgColorCheck } from '@src/utils/routeBgColorCheck'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from './redux'
import { useResponsive } from './useResponsive'

const useRightSidebar = () => {
  const initialIconColor = useRef('')
  const [iconColor, setIconColor] = useState('#ffffff')
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [isPrimarySearchOpen, setIsPrimarySearchOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { asPath } = useRouter()
  const { isTablet } = useResponsive(1280)
  const { isMobile } = useResponsive(768)
  const dispatch = useAppDispatch()
  const isHoverHeader = useAppSelector((state) => state.commons.isHoverHeader)
  const scrollIconColor = useAppSelector((state) => state.commons.scrollSidebarIconColor)
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  const handleClickLanguageSettings = (e: null | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e === null) {
      setAnchorEl(null)
      return
    }
    setAnchorEl(e.currentTarget)
  }

  const handleClickPrimarySearch = (isOpen: boolean) => {
    setIsPrimarySearchOpen(isOpen)
  }

  const closeSidebar = () => {
    setIsSidebarOpen(false)
  }

  useEffect(() => {
    if (isPrimarySearchOpen || (isHoverHeader && !isTablet)) {
      setIconColor(wev_color.text)
      return
    }
    if (!isMobile) {
      if (['/wev/partnership', '/', '/wev'].includes(asPath)) {
        setIconColor(scrollIconColor)
        return
      }
      setIconColor(initialIconColor.current)
    }
  }, [isHoverHeader, isPrimarySearchOpen, scrollIconColor])

  useEffect(() => {
    const changeIconColor = () => {
      if (['/wev/partnership', '/', '/wev'].includes(asPath) && !isMobile) {
        setIconColor(scrollIconColor)
        return
      }
      const textColor = isMobile ? '#1E1E1E' : routeBgColorCheck(asPath)
      setIconColor(textColor)
      initialIconColor.current = textColor
    }
    changeIconColor()
  }, [isMobile, asPath])

  useEffect(() => {
    if (isSidebarOpen) {
      dispatch(setIsRightSidebarOpen(true))
      if (document.body.style.overflow !== 'hidden') {
        document.body.style.overflow = 'hidden'
      }
    } else {
      dispatch(setIsRightSidebarOpen(false))
      document.body.style.overflow = 'auto'
    }
  }, [isSidebarOpen])
  return {
    isSidebarOpen,
    isPrimarySearchOpen,
    iconColor,
    isTablet,
    anchorEl,
    isHoverHeader,
    toggleSidebar,
    handleClickLanguageSettings,
    handleClickPrimarySearch,
    closeSidebar,
    setIconColor,
  }
}

export default useRightSidebar
