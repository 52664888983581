/* input 형식 : Tue Mar 19 2024 13:52:49 GMT+0900 (한국 표준시) */
/* ouput 형식 : 20240301000000 (yyyyMMddHHmmss) */

export const naverApiFormatDate = (date: Date | string) => {
  const newDate = new Date(date)
  const year = newDate.getFullYear()
  const month = String(newDate.getMonth() + 1).padStart(2, '0')
  const day = String(newDate.getDate()).padStart(2, '0')
  const hours = String(newDate.getHours()).padStart(2, '0')
  const minutes = String(newDate.getMinutes()).padStart(2, '0')
  const seconds = String(newDate.getSeconds()).padStart(2, '0')

  return `${year}${month}${day}${hours}${minutes}${seconds}`
}

/* input 형식 : 20240301000000 (yyyyMMddHHmmss) */
/* ouput 형식 : 2024-03-01 00:00:00 (yyyy-MM-dd HH:mm:ss) */

export function naverListFormatDateTime(dateTime: string) {
  const year = dateTime.slice(0, 4)
  const month = dateTime.slice(4, 6)
  const day = dateTime.slice(6, 8)
  const hour = dateTime.slice(8, 10)
  const minute = dateTime.slice(10, 12)
  const second = dateTime.slice(12, 14)

  return `${year}-${month}-${day} ${hour}:${minute}:${second}`
}

/* input 형식 : Tue Mar 19 2024 13:52:49 GMT+0900 (한국 표준시) */
/* ouput 형식 : 2024-03-01T00:00:00 (yyyy-MM-ddTHH:mm:ss) */

export const dateInputFormatDate = (date: Date) => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const seconds = String(date.getSeconds()).padStart(2, '0')

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
}

/* input 형식 : Tue Mar 19 2024 13:52:49 GMT+0900 (한국 표준시) */
/* ouput 형식 : 2024-03-01 00:00:00 (yyyy-MM-ddTHH:mm:ss) */

export const datePrintFormatDate = (date: Date) => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const seconds = String(date.getSeconds()).padStart(2, '0')

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

/* 
momment로 구한 local 시간을 화면에 출력하기 위한 형태로 수정
input 형식 :2023-07-30T22:49:16-08:00
ouput 형식 :2023-07-30 22:49:16
 */
export const formatMommentToLocal = (timeStr: any) => {
  let [datePart, timePart] = timeStr.split('T')
  let [timeWithoutTimeZone, timeZone] = timePart.split(/[Z+-]/)

  return `${datePart} ${timeWithoutTimeZone}`
}

/* 
momment로 구한 utc 시간을 서버에 보내기 위한 형태로 수정
input 형식 :2023-07-30T22:49:16-08:00
ouput 형식 :2024-03-27T13:47:39.000Z
 */

export const formatMommentToUtc = (timeStr: any) => {
  let timeZoneOffsetMinutes = 0
  let isNegativeOffset = false
  let [datePart, timePart] = timeStr.split('T')
  let [timeWithoutTimeZone, timeZone] = timePart.split(/[+-]/)
  let [year, month, day] = datePart.split('-')
  let [hour, minute, second] = timeWithoutTimeZone.split(':')

  if (timePart.includes('+') || timePart.includes('-')) {
    timeZoneOffsetMinutes = parseInt(timeZone) * 60
    isNegativeOffset = timePart.includes('-')
  }

  let localTime = new Date(Date.UTC(year, month - 1, day, hour, minute, second)).getTime()
  let uctTime

  if (isNegativeOffset) {
    uctTime = new Date(localTime + timeZoneOffsetMinutes * 60000)
  } else {
    uctTime = new Date(localTime - timeZoneOffsetMinutes * 60000)
  }

  return uctTime.toISOString()
}

/* 
utcToLocal 결과를 input에 넣기 위한 형태로 수정
input 형식 :2023-07-30 22:49:16
ouput 형식 :2023-07-30T22:49:16
 */
export const formatInputMommentToLocal = (timeStr: any) => {
  let [datePart, timePart] = timeStr.split(' ')

  return `${datePart}T${timePart}`
}
