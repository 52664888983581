import { inquiryAnswerData } from '@src/components/manageInquiry/detail'
import instance from './axios'

export const inquiryApis = {
  //관리자
  inquiryAllList: (page: number) => instance.get(`management/inquiry/list?p=${page}`),
  inquiryAnswer: (data: inquiryAnswerData) =>
    instance.post(`management/inquiry/answer?id=${data.id}`, { content: data.content }),
  inquirySearch: (data: any) => instance.get(`management/inquiry/list?name=${data.name}&p=${data.page}`),
  inquiryCategoryList: (data: any) => instance.get(`management/inquiry/list?answer=${data.answer}&p=${data.page}`),
  inquirySearchCategoryList: (data: any) =>
    instance.get(`management/inquiry/list?name=${data.name}&answer=${data.answer}&p=${data.page}`),
}
