import { useResponsive } from '@src/hooks/useResponsive'
import AuthMenu from '@src/layouts/header/AuthMenu'
import checkAccessToken from '@src/utils/auth/checkAccessToken'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { GrLanguage } from 'react-icons/gr'
import LanguageSettings from '../languageSettings/LanguageSettings'
import SitemapMobile from './SitemapMobile'
import {
  WEV_SITEMAP_CATEGORY_KEYS_EN,
  WEV_SITEMAP_CATEGORY_KEYS_JP,
  WEV_SITEMAP_CATEGORY_KEYS_KR,
  WEV_SITEMAP_EN,
  WEV_SITEMAP_JP,
  WEV_SITEMAP_KR,
} from './constants'
import { useAppSelector } from '@src/hooks/redux'
import * as S from './SiteMap.styled'

const SiteMap = ({ handleClickSitemapItem }: { handleClickSitemapItem: VoidFunction }) => {
  const { isTablet } = useResponsive(1280)
  const router = useRouter()
  const locale = router.locale || 'ko'
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const newUserInfo = useAppSelector((state) => state.userInfo.userInfoMasking)
  const hasAccessToken = checkAccessToken()

  const getLocaleData = (language: string) => {
    switch (language) {
      case 'en':
        return {
          data: WEV_SITEMAP_EN,
          data_key: WEV_SITEMAP_CATEGORY_KEYS_EN,
        }
      case 'jp':
        return {
          data: WEV_SITEMAP_JP,
          data_key: WEV_SITEMAP_CATEGORY_KEYS_JP,
        }
      default: // 한국어(kr)를 기본으로 설정
        return {
          data: WEV_SITEMAP_KR,
          data_key: WEV_SITEMAP_CATEGORY_KEYS_KR,
        }
    }
  }
  const localeData = getLocaleData(locale)
  return (
    <S.Layout>
      <h1>WEV SITEMAP</h1>
      <div id="sitemapContents">
        {Object.keys(localeData.data)
          .filter((category) => (isTablet && hasAccessToken ? category : category !== 'myPage'))
          .map((category) =>
            isTablet ? (
              <SitemapMobile
                key={`sitemap-${category}`}
                category={localeData.data_key[category]}
                links={localeData.data[category]}
                onClick={handleClickSitemapItem}
              />
            ) : (
              <S.SitemapCategory key={`sitemap-${category}`}>
                <h2>{localeData.data_key[category]}</h2>
                {localeData.data[category].map((item) => (
                  <li key={`sitemap-${category}-${item.label}`}>
                    <Link
                      href={
                        item.href === '/business/about' && newUserInfo.isCorporateMember
                          ? '/business/management/info'
                          : item.href
                      }
                      onClick={handleClickSitemapItem}
                    >
                      {item.label}
                    </Link>
                  </li>
                ))}
              </S.SitemapCategory>
            ),
          )}
      </div>

      {isTablet ? (
        <div id="mobileAction">
          <AuthMenu isUserLoggedIn={hasAccessToken} handleClickSitemapItem={handleClickSitemapItem} />
          <button onClick={(e) => setAnchorEl(e.currentTarget)}>
            <GrLanguage fontSize="1.5rem" />
          </button>
          <LanguageSettings open={Boolean(anchorEl)} anchorEl={anchorEl} handleClose={() => setAnchorEl(null)} />
        </div>
      ) : null}
    </S.Layout>
  )
}

export default SiteMap
