import Cookies from 'universal-cookie'

/* 
accessToken 보유 여부를 확인하는 함수
return type : boolean
*/

const checkAccessToken = (): boolean => {
  const cookies = new Cookies()
  const accessToken = cookies.get('Wev_accessToken')
  return !!accessToken
}

export default checkAccessToken
