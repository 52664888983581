import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import instance from '@src/api/axios'
import { counselingApis } from '@src/api/counselingApi'

export interface CounselingList {
  applicant_classification: string
  counsel_category_id: string
  answer: string
  phone_number: string
  name: string
  page: number
}

export interface CounselingListPaging {
  currentPage: number
  endPage: number
  nowBlock: number
  startPage: string
  totalBlock: number
  totalPage: number
}

const initialState = {
  counselingList: [],
  counselingListPaging: {},
  currentAllPage: null,
  currentAnswer: null,
  progressCounslingCount: 0,
  progressCounslingCountV2: 0,
}

export const getCounselingList = createAsyncThunk('/counseling/list', async (data: CounselingList, thunkAPI) => {
  try {
    await counselingApis.getCounseling(data).then((res) => {
      thunkAPI.dispatch(setCounselingList(res.data?.result_data.records))

      const counselingListPagingData: CounselingListPaging = {
        currentPage: res.data.result_data.current_page,
        endPage: res.data.result_data.end_page,
        nowBlock: res.data.result_data.now_block,
        startPage: res.data.result_data.start_page,
        totalBlock: res.data.result_data.total_block,
        totalPage: res.data.result_data.total_page,
      }

      thunkAPI.dispatch(setCounselingPaging(counselingListPagingData))
    })
  } catch (err) {}
})
interface IPrgressCounslingCountParam {
  page: number
  answer: number
}
export const getProgressCounslingCount = createAsyncThunk(
  '/counseling/getProgressCounslingCount',
  async (data: IPrgressCounslingCountParam, thunkAPI) => {
    try {
      await counselingApis.getCounseling(data).then((res) => {
        const data = res.data.result_data.records
        thunkAPI.dispatch(setProgressCounslingCount(data.length))
      })
    } catch (err) {}
  },
)
export const getProgressCounslingCountV2 = createAsyncThunk(
  '/counseling/getProgressCounslingCountV2',
  async (data, thunkAPI) => {
    try {
      await instance.get(`/management/counsel?p=1&answer=0`).then((res) => {
        const data = res.data.resultData.records
        thunkAPI.dispatch(setProgressCounslingCountV2(data.length))
      })
    } catch (err) {}
  },
)

export const CounselingSlice = createSlice({
  name: 'counseling',
  initialState,
  reducers: {
    setCounselingList: (state, action) => {
      state.counselingList = action.payload
    },
    setCounselingPaging: (state, action) => {
      state.counselingListPaging = action.payload
    },
    setCurrentAllPage: (state, action) => {
      state.currentAllPage = action.payload
    },
    setAnswer: (state, action) => {
      state.currentAnswer = action.payload
    },
    setProgressCounslingCount: (state, action) => {
      state.progressCounslingCount = action.payload
    },
    setProgressCounslingCountV2: (state, action) => {
      state.progressCounslingCountV2 = action.payload
    },
  },
})

export const {
  setCounselingList,
  setCounselingPaging,
  setCurrentAllPage,
  setAnswer,
  setProgressCounslingCount,
  setProgressCounslingCountV2,
} = CounselingSlice.actions

export default CounselingSlice.reducer
