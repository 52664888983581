// 날짜 요일을 리턴하는 함수
function getDayOfWeek(dateString: Date): string {
  const inputDate = new Date(dateString)
  const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토']
  const dayOfWeek = daysOfWeek[inputDate.getDay()]

  return dayOfWeek
}

// 오늘 날짜 알려주는 함수
export function getTodayDate() {
  return new Date()
}

// 오늘 날짜에서 -n 일 전의 날짜를 구하는 함수
export function subtractDateCalculator(before: number) {
  const today: Date = new Date()
  const previousDate: Date = new Date(today.getTime() - before * 24 * 60 * 60 * 1000)
  return previousDate
}

// 오늘 날짜에서 +n 일 후의 날짜를 구하는 함수
export function addDateCalculater(after: number) {
  const today: Date = new Date()
  const nextWeek: Date = new Date(today.getTime() + after * 24 * 60 * 60 * 1000)
  return nextWeek
}

export function formatDateAddDate(inputDate: Date): string {
  const dateObj = new Date(inputDate)

  const yearStr = dateObj.getFullYear().toString()
  const monthStr = (dateObj.getMonth() + 1).toString().padStart(2, '0')
  const dayStr = dateObj.getDate().toString().padStart(2, '0')

  const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토']
  const dayOfWeek = daysOfWeek[dateObj.getDay()]

  return `${yearStr}/${monthStr}/${dayStr}(${dayOfWeek})`
}

// 입력된 날짜에서 n 일 후의 날짜를 구하는 함수
export const calculateDaysLater = (inputDate: Date | string, addDay: number) => {
  const newInputDate = new Date(inputDate)
  const result: Date = new Date(newInputDate.getTime() + addDay * 24 * 60 * 60 * 1000)
  return result
  // inputDateObj.setDate(inputDateObj.getDate() + addDay)
  // return inputDateObj
}

export const expectationDelivery = (after: number) => {
  const addDate = addDateCalculater(after)
  const dayOfWeek = getDayOfWeek(addDate)
  if (dayOfWeek === '토') {
    return formatDateAddDate(calculateDaysLater(addDate, 2))
  } else if (dayOfWeek === '일') {
    return formatDateAddDate(calculateDaysLater(addDate, 1))
  } else {
    return formatDateAddDate(addDate)
  }
}

function padZero(number: number) {
  return (number < 10 ? '0' : '') + number
}
function formattedDate(inputDate: Date) {
  let date = new Date(inputDate)
  const new_date =
    date.getFullYear() +
    '-' +
    padZero(date.getMonth() + 1) +
    '-' +
    padZero(date.getDate()) +
    ' ' +
    padZero(date.getHours()) +
    ':' +
    padZero(date.getMinutes()) +
    ':' +
    padZero(date.getSeconds())
  return new_date
}
function utcFormattedDate(inputDate: Date) {
  let date = new Date(inputDate)
  const new_date =
    date.getFullYear() +
    '-' +
    padZero(date.getMonth() + 1) +
    '-' +
    padZero(date.getDate()) +
    'T' +
    padZero(date.getHours()) +
    ':' +
    padZero(date.getMinutes()) +
    ':' +
    padZero(date.getSeconds())
  return new_date
}

// 초를 n시간n분n초 형태로 반환
export function converSecToTime(seconds: number) {
  if (isNaN(seconds) || seconds < 0) {
    return '00시간00시00분'
  }

  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = Math.floor(seconds % 60)

  const formatNumber = (num: number) => (num < 10 ? `0${num}` : num)

  return `${formatNumber(hours)}시간${formatNumber(minutes)}분${formatNumber(remainingSeconds)}초`
}

// n 개월 전의 날짜를 구하는 함수
export const getMonthsAgo = (number: number) => {
  const today = new Date()
  today.setMonth(today.getMonth() - number)
  return today.toISOString().split('T')[0]
}
// n 주 전의 날짜를 구하는 함수
export const getWeeksAgo = (number: number) => {
  const today = new Date()
  today.setDate(today.getDate() - number * 7) // 주를 일로 변환하여 빼줌
  return today.toISOString().split('T')[0]
}

// mm월 dd일 형태로 반환
export const formatDateString = (dateString: string) => {
  const date = new Date(dateString)
  const monthString = (date.getMonth() + 1).toString().padStart(2, '0')
  const dayString = date.getDate().toString().padStart(2, '0')
  const formattedDate = `${monthString}월 ${dayString}일`

  return formattedDate
}

// 다음 달 mm을 반환
export const getNextMonth = (inputDate: string) => {
  const date = new Date(inputDate)
  const nextMonth = (date.getMonth() + 2) % 12 || 12
  const formattedNextMonth = nextMonth.toString().padStart(2, '0')

  return formattedNextMonth
}

// 이전 달 mm을 반환
export const getPreviousMonth = (inputDate: string) => {
  const date = new Date(inputDate)
  const previousMonth = ((date.getMonth() - 1 + 12) % 12) + 1
  const formattedPreviousMonth = previousMonth.toString().padStart(2, '0')

  return formattedPreviousMonth
}

// 현재 달의 마지막 날 date을 반환
export const getLastDayOfMonth = (inputDate: string) => {
  const date = new Date(inputDate)
  const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0)
  const formattedLastDay = `${lastDayOfMonth.getFullYear()}-${(lastDayOfMonth.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${lastDayOfMonth.getDate().toString().padStart(2, '0')}`

  return formattedLastDay
}

// 현재 달의 시작 을 yyyy-mm-dd 행태로 반환
export const getFirstDayOfMonth = (inputDate: string) => {
  const date = new Date(inputDate)
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1)
  const formattedFirstDay = `${firstDayOfMonth.getFullYear()}-${(firstDayOfMonth.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${firstDayOfMonth.getDate().toString().padStart(2, '0')}`

  return formattedFirstDay
}

// 현재 달의 마지막 날 date을 반환
export const getLastDayOfMonthDateType = (inputDate: string) => {
  const date = new Date(inputDate)
  const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0)
  const formattedLastDay = `${lastDayOfMonth.getFullYear()}-${
    lastDayOfMonth.getMonth() + 1
  }-${lastDayOfMonth.getDate()}`

  return formattedLastDay
}

export const getYearArray = () => {
  const date = new Date()
  const lastYear = date.getFullYear()
  const result = []
  for (let i = 0; i + 2023 <= lastYear; i++) {
    const yearObj = { id: i, value: 2023 + i, text: `${2023 + i}년` }
    result.push(yearObj)
  }
  return result
}
