import { CouponDownload, CouponList } from '@src/pages/management/coupon/list'
import { CreateCoupon } from '@src/store/modules/coupon'
import instance from './axios'

export const couponApis = {
  couponCreate: (data: CreateCoupon) => instance.post('/management/coupon/issue', data),
  couponAllList: (data: CouponList) =>
    data.available && data.expired_at
      ? instance.get(`/management/coupon/list?p=${data.page}&available=${data.available}&expired_at=${data.expired_at}`)
      : !data.available && data.expired_at
      ? instance.get(`/management/coupon/list?p=${data.page}&expired_at=${data.expired_at}`)
      : data.available && !data.expired_at
      ? instance.get(`/management/coupon/list?p=${data.page}&available=${data.available}`)
      : instance.get(`/management/coupon/list?p=${data.page}`),
  couponDownload: (data: CouponDownload) =>
    instance.get(
      `/management/coupon/download-coupon-file?expired_at=${data.expired_at}&coupon_count=${data.coupon_count}`,
    ),
}
