import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import * as S from './TermSidebar.styled'
const TermSidebar = () => {
  const { asPath } = useRouter()
  return (
    <S.Sidebar>
      <div>
        <h2>WEV 약관</h2>
        <ul>
          <Link href="/terms/use_policy">
            <S.SidebarItem isActiveStatus={asPath.startsWith('/terms/use_policy')}>
              <p>이용약관</p>
            </S.SidebarItem>
          </Link>
          <Link href="/terms/privacy">
            <S.SidebarItem isActiveStatus={asPath.startsWith('/terms/privacy')}>
              <p>개인정보처리방침</p>
            </S.SidebarItem>
          </Link>
          <Link href="/terms/marketing">
            <S.SidebarItem isActiveStatus={asPath.startsWith('/terms/marketing')}>
              <p>마케팅 정보 수신</p>
            </S.SidebarItem>
          </Link>
          <Link href="/terms/usage_privacy">
            <S.SidebarItem isActiveStatus={asPath.startsWith('/terms/usage_privacy')}>
              <p>
                개인정보 수집 및<br />
                서비스 활용
              </p>
            </S.SidebarItem>
          </Link>
          <Link href="/terms/guest_payment_policy">
            <S.SidebarItem isActiveStatus={asPath.startsWith('/terms/guest_payment_policy')}>
              <p>
                비회원 개인정보
                <br />
                수집 이용 동의
              </p>
            </S.SidebarItem>
          </Link>
        </ul>
      </div>
    </S.Sidebar>
  )
}

export default TermSidebar
