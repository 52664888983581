import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { paymentApis } from '@src/api/management/payment'
import { INaverPaymentHistoryList, INaverPaymentHistoryParams } from '@src/type/management/naverPaymentHistory'
import { getTodayDate, subtractDateCalculator } from '@src/utils/dateCalculater'
import { naverApiFormatDate } from '@src/utils/dateFormat'
import Swal from 'sweetalert2'

const initialState = {
  naverPaymentHistoryList: {} as INaverPaymentHistoryList,
  naverPaymentHistoryListFilter: {
    page: 1,
    list: 15,
    start: naverApiFormatDate(subtractDateCalculator(31)),
    end: naverApiFormatDate(getTodayDate()),
    type: 'ALL',
  } as INaverPaymentHistoryParams,
}
export const naverPaymentHistorySlice = createSlice({
  name: 'naverPaymentHistory',
  initialState,
  reducers: {
    setNaverPaymentHistoryList: (state, action) => {
      state.naverPaymentHistoryList = action.payload
    },
    setNaverPaymentHistoryListFilter: (state, action) => {
      state.naverPaymentHistoryListFilter = action.payload
    },
  },
})
export const { setNaverPaymentHistoryList, setNaverPaymentHistoryListFilter } = naverPaymentHistorySlice.actions

export default naverPaymentHistorySlice.reducer

// -------------------------------------- //

export const getNaverPaymentHistoryList = createAsyncThunk(
  '/management/naverPaymentHistory/getNaverPaymentHistoryList',
  async (data: INaverPaymentHistoryParams, thunkAPI) => {
    try {
      await paymentApis.getNaverPaymentHistoryList(data).then((res) => {
        const data = res.data.result_data
        thunkAPI.dispatch(setNaverPaymentHistoryList(data))
      })
    } catch (err: any) {
      Swal.fire({
        icon: 'warning',
        text: `${err.response.data?.result_message}`,
      })
    }
  },
)
