import Link from 'next/link'
import { setIsHoverHeader } from '@src/store/modules/commons'
import { useEffect, useState } from 'react'
import { useResponsive } from '@src/hooks/useResponsive'
import { useTranslation } from 'next-i18next'
import { useAppDispatch, useAppSelector } from '@src/hooks/redux'
import { checkStaffAuth } from '@src/utils/auth/checkAuthorized'
import { useRouter } from 'next/router'
import checkAccessToken from '@src/utils/auth/checkAccessToken'
import { accountApis } from '@src/features/account/api'
import secureLocalStorage from 'react-secure-storage'
import Cookies from 'universal-cookie'
import { toast } from 'react-toastify'
import { Toast_Warning } from '@src/assets/icons'
import { routeBgColorCheck } from '@src/utils/routeBgColorCheck'
import * as S from './Header.styled'

interface Action {
  label: string
  href: string
}

const AuthMenu = ({
  isUserLoggedIn,
  handleClickSitemapItem,
}: {
  isUserLoggedIn: boolean
  handleClickSitemapItem?: VoidFunction
}) => {
  const router = useRouter()
  const cookies = new Cookies()
  const { t } = useTranslation('layout')
  const { isTablet } = useResponsive(1280)
  const { asPath } = useRouter()
  const isHoverHeader = useAppSelector((state) => state.commons.isHoverHeader)
  const [primaryAction, setPrimaryAction] = useState<Action | null>(null)
  const [secondaryAction, setSecondaryAction] = useState<Action | null>(null)
  const newUserInfo = useAppSelector((state) => state.userInfo.userInfoMasking)
  const hasAccessToken = checkAccessToken()
  const dispatch = useAppDispatch()
  const locale = router.locale || 'ko'

  const logout = async () => {
    try {
      await accountApis.logout().then((res) => {
        if (res.data.result_code === 'OK') {
          router.replace('/login')
          secureLocalStorage.clear()
          cookies.remove('Wev_accessToken', { path: '/' })
          cookies.remove('Wev_refreshToken', { path: '/' })
        }
      })
    } catch (err: any) {
      toast.dark(err.response.data.result_message || '오류가 발생했습니다. 잠시 후 다시 시도해주세요.', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        hideProgressBar: true,
        closeButton: false,
        icon: <Toast_Warning />,
      })
    }
  }

  useEffect(() => {
    if (isUserLoggedIn) {
      setPrimaryAction(null)
      setSecondaryAction({ label: `${t('header.마이페이지')}`, href: '/user/my_info' })
    } else {
      setPrimaryAction({ label: `${t('header.로그인')}`, href: '/login' })
      setSecondaryAction({ label: `${t('header.회원가입')}`, href: '/sign_up' })
    }
  }, [locale, hasAccessToken])

  return (
    <S.AuthMenu
      id="auth_menu"
      isTablet={isTablet}
      className="flex items-center"
      $textColor={routeBgColorCheck(router.asPath)}
      $isHeaderHovered={Boolean(isHoverHeader)}
    >
      {isTablet && hasAccessToken ? (
        <button
          onClick={() => {
            handleClickSitemapItem && handleClickSitemapItem()
            logout()
          }}
        >
          {t('header.로그아웃')}
        </button>
      ) : (
        <>
          {primaryAction ? (
            <>
              <Link
                href={primaryAction.href}
                onClick={() => {
                  handleClickSitemapItem && handleClickSitemapItem()
                  dispatch(setIsHoverHeader(false))
                }}
              >
                {primaryAction.label}
              </Link>
              <span>&nbsp;/&nbsp;</span>
            </>
          ) : null}
          {secondaryAction ? (
            <>
              <Link
                href={secondaryAction.href}
                onClick={() => {
                  handleClickSitemapItem && handleClickSitemapItem()
                  dispatch(setIsHoverHeader(false))
                }}
              >
                {secondaryAction.label}
              </Link>
            </>
          ) : null}
        </>
      )}
      {checkStaffAuth(newUserInfo.role) && asPath !== '/' ? (
        <Link
          href={'/management/main'}
          className="py-[5px] rounded ml-[10px]"
          onClick={() => {
            handleClickSitemapItem && handleClickSitemapItem()
            dispatch(setIsHoverHeader(false))
          }}
        >
          [{newUserInfo.role}]
        </Link>
      ) : null}
    </S.AuthMenu>
  )
}

export default AuthMenu
