import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { noticeApis } from '@src/api/management/notice'
import useAuthorization from '@src/hooks/useAuthorized'
import {
  INoticeClosedParam,
  INoticeDetail,
  INoticeEditParam,
  INoticeList,
  INoticeListParam,
  INoticeSubCategory,
} from '@src/type/customer/notice'
import axios from 'axios'
import Config from 'configs/config.export'
import Router from 'next/router'
import Swal from 'sweetalert2'
import Cookies from 'universal-cookie'

const initialState = {
  noticeSubCategory: [] as INoticeSubCategory[],
  noticeList: {} as INoticeList,
  noticeListFilter: {
    page: 1,
    category: 0,
    searchCategory: undefined,
    query: '',
  } as INoticeListParam,
  noticeDetail: {} as INoticeDetail,
}
export const noticeSlice = createSlice({
  name: 'noticeSlice',
  initialState,
  reducers: {
    setNoticeSubCategory: (state, action) => {
      state.noticeSubCategory = action.payload
    },
    setNoticeList: (state, action) => {
      state.noticeList = action.payload
    },
    setNoticeListFilter: (state, action) => {
      state.noticeListFilter = action.payload
    },
    setNoticeDetail: (state, action) => {
      state.noticeDetail = action.payload
    },
  },
})
export const { setNoticeSubCategory, setNoticeList, setNoticeListFilter, setNoticeDetail } = noticeSlice.actions

export default noticeSlice.reducer

// -------------------------------------- //

export const getNoticeSubCategory = createAsyncThunk('/customer/notice/getNoticeSubCategory', async (_, thunkAPI) => {
  try {
    await noticeApis.getNoticeSubCategory().then((res) => {
      const data = res.data.result_data
      thunkAPI.dispatch(setNoticeSubCategory(data))
    })
  } catch (err) {}
})

export const getNoticeList = createAsyncThunk(
  '/customer/notice/getNoticeList',
  async (data: INoticeListParam, thunkAPI) => {
    try {
      await noticeApis.getNoticeList(data).then((res) => {
        const data = res.data.result_data
        thunkAPI.dispatch(setNoticeList(data))
      })
    } catch (err) {}
  },
)

export const getNoticeUserList = createAsyncThunk(
  '/customer/notice/getNoticeUserList',
  async (data: INoticeListParam, thunkAPI) => {
    try {
      await axios
        .get(
          `${Config().baseUrl}management/notice/list?p=${data.page}` +
            `${data.category ? `&board_sub_category_id=${data.category}` : ''}` +
            `${!data.searchCategory ? '' : `&category=${data.searchCategory}`}` +
            `${data.query ? `&query=${data.query}` : ''}`,
        )
        .then((res) => {
          const data = res.data.result_data
          thunkAPI.dispatch(setNoticeList(data))
        })
    } catch (err) {}
  },
)

export const getNoticeDetail = createAsyncThunk('/customer/notice/getNoticeDetail', async (data: number, thunkAPI) => {
  try {
    await noticeApis.getNoticeDetail(data).then((res) => {
      const data = res.data.result_data
      thunkAPI.dispatch(setNoticeDetail(data))
    })
  } catch (err) {}
})
export const getNoticeUserDetail = createAsyncThunk(
  '/customer/notice/getNoticeUserDetail',
  async (data: number, thunkAPI) => {
    try {
      await axios.get(`${Config().baseUrl}management/notice/select-list?id=${data}`).then((res) => {
        const data = res.data.result_data
        thunkAPI.dispatch(setNoticeDetail(data))
      })
    } catch (err) {}
  },
)

//공지사항 비공개 전환
export const noticeClosed = createAsyncThunk(
  '/customer/notice/noticeClosed',
  async (data: INoticeClosedParam, thunkAPI) => {
    try {
      await noticeApis.noticeClosed(data).then(() => {
        Router.back()
        Swal.fire({
          icon: 'success',
          text: '해당 게시글의 공개 여부가 변경되었습니다!',
          showConfirmButton: false,
          timer: 2000,
        })
      })
    } catch (err) {
      // Sentry.captureException(err)
    }
  },
)
