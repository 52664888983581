import styled from 'styled-components'

export const Layout = styled.aside<{
  isHeaderHovered: boolean
  isTablet: boolean
  isSidebarOpen: boolean
  isMain: boolean
}>`
  border-left: ${({ theme, isMain, isHeaderHovered }) => (isMain || isHeaderHovered ? 'unset' : `1px solid #ededed;`)};
  padding: 1.25rem 0;
  width: ${({ isTablet }) => (isTablet ? '3.5rem' : '4rem')};
  height: ${({ isTablet }) => (isTablet ? '54px' : '100%')};
  position: fixed;
  top: 0;
  right: 0;
  z-index: 980;
  #rightSidebarContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  @media ${({ theme }) => theme.device.pc} {
    border-left: 0;
    padding: ${({ isSidebarOpen }) => (isSidebarOpen ? '' : '20px 30px 0px 0px')};
  }
  @media ${({ theme }) => theme.device.mobile} {
    padding: ${({ isSidebarOpen }) => (isSidebarOpen ? '' : '15px 30px 0px 0px')};
    width: 2.5rem;
  }
`
export const BackDrop = styled.div`
  position: absolute;
  top: 0;
  left: 0%;
  width: 100vw;
  height: 100vh;
  background: #000000bf;
  z-index: 70;
`

export const RightSidebarContent = styled.div<{ isOpen: boolean }>`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  background-color: ${({ theme }) => theme.wev_color.primary};
  @media ${({ theme }) => theme.device.pc} {
    max-width: 70vw;
  }
`
