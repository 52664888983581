import styled from 'styled-components'

export const Layout = styled.nav`
  color: #fff;
  width: 100%;
  max-width: 60rem;
  display: flex;
  flex-direction: column;
  color: #fff;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${({ theme }) => theme.device.pc} {
    top: 80px;
    left: 0;
    transform: unset;
  }

  & > * {
    margin-bottom: 2rem;
  }
  & > h1 {
    ${({ theme }) => theme.wev_font.Bold_48};
  }
  #sitemapContents {
    display: flex;
    width: 100%;
    gap: 20px;
    text-align: center;
    white-space: nowrap;
    justify-content: space-between;
    @media ${({ theme }) => theme.device.pc} {
      gap: 0px;
      text-align: left;
    }
  }
  #mobileAction {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    & path {
      stroke: white;
    }
  }
  @media ${({ theme }) => theme.device.pc} {
    margin: 0;
    justify-content: space-between;
    & > h1 {
      display: none;
    }
    #sitemapContents {
      flex-direction: column;
      margin-top: 4rem;
      @media ${({ theme }) => theme.device.pc} {
        margin-top: 0;
      }
    }
    & > * {
      margin-bottom: 0;
    }
    padding-top: 0;
  }
`

export const SitemapCategory = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > * {
    margin-bottom: 1rem;
  }
  * > a:hover {
    text-decoration: underline;
    text-decoration-thickness: 1.5px;
    text-underline-offset: 6px;
  }
  & > h2 {
    ${({ theme }) => theme.wev_font.Bold_24};
    margin-bottom: 1.5rem;
  }
`
