import React from 'react'
import {
  Assignment_F,
  Battery_Charging_Full_F,
  Credit_Card_F,
  Database_F,
  Move_Item,
  Notifications_F,
  Person_F,
  Support_Agent_F,
  Toast_Warning,
} from '@src/assets/icons'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { accountApis } from '@src/features/account/api'
import secureLocalStorage from 'react-secure-storage'
import Cookies from 'universal-cookie'
import { toast } from 'react-toastify'
import * as S from './UserSidebar.styled'

const UserSidebar = () => {
  const router = useRouter()
  const cookies = new Cookies()
  const { asPath } = useRouter()

  const logout = async () => {
    try {
      await accountApis.logout().then((res) => {
        if (res.data.result_code === 'OK') {
          router.replace('/')
          secureLocalStorage.clear()
          cookies.remove('Wev_accessToken', { path: '/' })
          cookies.remove('Wev_refreshToken', { path: '/' })
        }
      })
    } catch (err: any) {
      toast.dark(err.response.data.result_message || '오류가 발생했습니다. 잠시 후 다시 시도해주세요.', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        hideProgressBar: true,
        closeButton: false,
        icon: <Toast_Warning />,
      })
    }
  }
  return (
    <S.Sidebar>
      <div>
        <h2>마이페이지</h2>
        <ul>
          {/* <Link href="/user/userInfo">
          <S.SidebarItem isActiveStatus={asPath.startsWith('/user/userInfo')}>
            <Bar_Chart_4_Bars_F />
            <p>홈</p>
          </S.SidebarItem>
        </Link> */}
          <Link href="/user/my_info">
            <S.SidebarItem
              isActiveStatus={asPath.startsWith('/user/my_info') || asPath.startsWith('/user/login_history')}
            >
              <Person_F />
              <p>내 정보</p>
            </S.SidebarItem>
          </Link>
          <Link href="/user/membership">
            <S.SidebarItem isActiveStatus={asPath.startsWith('/user/membership')}>
              <Credit_Card_F />
              <p>멤버십</p>
            </S.SidebarItem>
          </Link>

          <Link href="/user/charge_history">
            <S.SidebarItem isActiveStatus={asPath.startsWith('/user/charge_history')}>
              <Battery_Charging_Full_F />
              <p>충전내역</p>
            </S.SidebarItem>
          </Link>
          <Link href="/user/payment_history">
            <S.SidebarItem isActiveStatus={asPath.startsWith('/user/payment_history')}>
              <Database_F />
              <p>결제내역</p>
            </S.SidebarItem>
          </Link>
          <Link href="/user/inquiry">
            <S.SidebarItem isActiveStatus={asPath.startsWith('/user/inquiry')}>
              <Support_Agent_F />
              <p>1:1문의</p>
            </S.SidebarItem>
          </Link>
          <Link href="/user/notification">
            <S.SidebarItem isActiveStatus={asPath.startsWith('/user/notification')}>
              <Notifications_F />
              <p>알림 설정</p>
            </S.SidebarItem>
          </Link>
          <Link href="/user/terms">
            <S.SidebarItem isActiveStatus={asPath.startsWith('/user/terms')}>
              <Assignment_F />
              <p>약관 관리</p>
            </S.SidebarItem>
          </Link>
        </ul>
      </div>
      <S.LogoutButton onClick={logout}>
        <Move_Item />
        <div>로그아웃</div>
      </S.LogoutButton>
    </S.Sidebar>
  )
}

export default UserSidebar
