import { IAuthorizationChargeBoxParams, ISetAuthorizationChargeBoxParams } from '@src/type/management/employee'
import instance from '../axios'
import { ILoginLogParams } from '@src/type/management/loginLog'

export const managementApis = {
  getLoginLog: (data: ILoginLogParams) =>
    instance.get(
      `/management/member/login-log?list_num=${data.list}&p=${data.page}` +
        `${data.name ? `&name=${data.name}` : ''}` +
        `${data.email ? `&email=${data.email}` : ''}` +
        `${data.result === 0 ? `&result=0` : !data.result ? '' : `&result=${data.result}`}`,
    ),
}
