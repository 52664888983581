import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { inquiryApis } from '@src/api/inquiryApi'
import { AnswerData, AnswerMemberCategory, SearchData } from '@src/pages/management/inquiry'
// import * as Sentry from '@sentry/nextjs'

export interface InquiryListPaging {
  currentPage: number
  endPage: number
  nowBlock: number
  startPage: number
  totalBlock: number
  totalPage: number
}

const initialState = {
  InquiryList: [],
  InquiryAllList: [],
  InquiryPaging: {},
  InquiryAllPaging: {},
  currentPage: null,
  currentAllPage: null,
  InquiryData: [],
  InquiryAllData: [],
  SearchKeyword: '',
  progressInquiryCount: 0,
}

//(사용자) 1:1 문의 리스트 GET
export const InquiryListData = createAsyncThunk('/inquiry/list', async (PageNumber: number, thunkAPI) => {
  try {
    await inquiryApis.inquiryList(PageNumber).then((res) => {
      thunkAPI.dispatch(setInquiryList(res.data?.result_data.records))

      const InquiryListPagingData: InquiryListPaging = {
        currentPage: res.data.result_data.current_page,
        endPage: res.data.result_data.end_page,
        nowBlock: res.data.result_data.now_block,
        startPage: res.data.result_data.start_page,
        totalBlock: res.data.result_data.total_block,
        totalPage: res.data.result_data.total_page,
      }
      thunkAPI.dispatch(setInquiryPaging(InquiryListPagingData))
    })
  } catch (err) {
    // Sentry.captureException(err)
  }
})

//(관리자) 1:1 문의 전체 리스트 GET
export const InquiryAllListData = createAsyncThunk('/inquiry/allList', async (PageNumber: number, thunkAPI) => {
  try {
    await inquiryApis.inquiryAllList(PageNumber).then((res) => {
      thunkAPI.dispatch(setInquiryAllList(res.data?.result_data.records))

      const InquiryAllListPagingData: InquiryListPaging = {
        currentPage: res.data.result_data.current_page,
        endPage: res.data.result_data.end_page,
        nowBlock: res.data.result_data.now_block,
        startPage: res.data.result_data.start_page,
        totalBlock: res.data.result_data.total_block,
        totalPage: res.data.result_data.total_page,
      }
      thunkAPI.dispatch(setInquiryAllPaging(InquiryAllListPagingData))
    })
  } catch (err) {
    // Sentry.captureException(err)
  }
})

//(관리자) 1:1 문의 회원 별 리스트 GET (검색)
export const InquiryMemberListData = createAsyncThunk('/inquiry/memberList', async (data: SearchData, thunkAPI) => {
  try {
    await inquiryApis.inquirySearch(data).then((res) => {
      thunkAPI.dispatch(setInquiryAllList(res.data?.result_data.records))

      const InquiryAllListPagingData: InquiryListPaging = {
        currentPage: res.data.result_data.current_page,
        endPage: res.data.result_data.end_page,
        nowBlock: res.data.result_data.now_block,
        startPage: res.data.result_data.start_page,
        totalBlock: res.data.result_data.total_block,
        totalPage: res.data.result_data.total_page,
      }
      thunkAPI.dispatch(setInquiryAllPaging(InquiryAllListPagingData))
    })
  } catch (err) {
    // Sentry.captureException(err)
  }
})

//(관리자) 1:1 문의 답변 상태 별 리스트 GET (카테고리)
export const InquiryAnswerListData = createAsyncThunk('/inquiry/answerList', async (data: AnswerData, thunkAPI) => {
  try {
    await inquiryApis.inquiryCategoryList(data).then((res) => {
      thunkAPI.dispatch(setInquiryAllList(res.data?.result_data.records))

      const InquiryAllListPagingData: InquiryListPaging = {
        currentPage: res.data.result_data.current_page,
        endPage: res.data.result_data.end_page,
        nowBlock: res.data.result_data.now_block,
        startPage: res.data.result_data.start_page,
        totalBlock: res.data.result_data.total_block,
        totalPage: res.data.result_data.total_page,
      }
      thunkAPI.dispatch(setInquiryAllPaging(InquiryAllListPagingData))
    })
  } catch (err) {
    // Sentry.captureException(err)
  }
})

//(관리자) 1:1 문의 답변 상태 및 회원 별 리스트 GET (검색 & 카테고리)
export const InquiryAnswerMemberListData = createAsyncThunk(
  '/inquiry/answerMemberList',
  async (data: AnswerMemberCategory, thunkAPI) => {
    try {
      await inquiryApis.inquirySearchCategoryList(data).then((res) => {
        thunkAPI.dispatch(setInquiryAllList(res.data?.result_data.records))

        const InquiryAllListPagingData: InquiryListPaging = {
          currentPage: res.data.result_data.current_page,
          endPage: res.data.result_data.end_page,
          nowBlock: res.data.result_data.now_block,
          startPage: res.data.result_data.start_page,
          totalBlock: res.data.result_data.total_block,
          totalPage: res.data.result_data.total_page,
        }
        thunkAPI.dispatch(setInquiryAllPaging(InquiryAllListPagingData))
      })
    } catch (err) {
      // Sentry.captureException(err)
    }
  },
)

export const getProgressInquiryCount = createAsyncThunk(
  '/counseling/getProgressInquiryCount',
  async (data: AnswerData, thunkAPI) => {
    try {
      await inquiryApis.inquiryCategoryList(data).then((res) => {
        const data = res.data.result_data.records
        thunkAPI.dispatch(setProgressInquiryCount(data.length))
      })
    } catch (err) {}
  },
)

export const InquirySlice = createSlice({
  name: 'inquiry',
  initialState,
  reducers: {
    setInquiryList: (state, action) => {
      state.InquiryList = action.payload
    },
    setInquiryPaging: (state, action) => {
      state.InquiryPaging = action.payload
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setInquiryData: (state, action) => {
      // state.InquiryData = [...action.payload]
      state.InquiryData.push(action.payload)
    },
    //관리자
    setInquiryAllList: (state, action) => {
      state.InquiryAllList = action.payload
    },
    setInquiryAllPaging: (state, action) => {
      state.InquiryAllPaging = action.payload
    },
    setCurrentAllPage: (state, action) => {
      state.currentAllPage = action.payload
    },
    setInquiryAllData: (state, action) => {
      state.InquiryAllData.push(action.payload)
    },
    setSearchKeyword: (state, action) => {
      state.SearchKeyword = action.payload
    },
    setCurrentCategory: (state, action) => {
      state.SearchKeyword = action.payload
    },
    setProgressInquiryCount: (state, action) => {
      state.progressInquiryCount = action.payload
    },
  },
})

export const {
  setInquiryList,
  setInquiryPaging,
  setCurrentPage,
  setInquiryData,
  setInquiryAllList,
  setInquiryAllPaging,
  setCurrentAllPage,
  setInquiryAllData,
  setSearchKeyword,
  setCurrentCategory,
  setProgressInquiryCount,
} = InquirySlice.actions
export default InquirySlice.reducer
