import { Container } from '@mui/material'
import { Arrow_Right_Alt } from '@src/assets/icons'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import parse from 'html-react-parser'
import * as S from './Inquiry.styled'

const Inquiry = () => {
  const { t } = useTranslation('layout')
  return (
    <S.Layout>
      <Container maxWidth="lg">
        <p className="text-center">{parse(t('footer_sub.title'))}</p>

        <Link className="space-x-2" href="/wev/install_consult">
          <span className="text-center">{parse(t('footer_sub.button'))}</span>
          <Arrow_Right_Alt fill="white" />
        </Link>
      </Container>
    </S.Layout>
  )
}

export default Inquiry
