import Link from 'next/link'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  padding: 30px;
  min-width: 195px;
  width: 195px;
  background-color: ${({ theme }) => theme.wev_color.menu_bg};
  > h1 {
    margin-bottom: 10px;
    ${({ theme }) => theme.wev_font.Bold_18}
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

export const Menu = styled(Link)<{ $locate: boolean }>`
  > span {
    ${({ theme, $locate }) => ($locate ? theme.wev_font.sBold_14 : theme.wev_font.Regular_14)}
    color: ${({ theme, $locate }) => ($locate ? theme.wev_color.primary : theme.wev_color.sub_menu_text)}
  }
`
