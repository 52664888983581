import {
  IBusinessMembershipListParam,
  IEditBusinessMembershipParam,
  IEditMembershipMemberParam,
  IEditMembershipParam,
  IRegisterBusinessMembershipCardParam,
  IResetPasswordParam,
} from '@src/type/business/businessMembership'
import instance from '../axios'

export const BusinessMembershipApis = {
  getPaymentMethod: () => instance.get(`/corporate/payment/method`),
  getMembershipList: (data: IBusinessMembershipListParam) => instance.post(`/corporate/membership/list`, data),
  registerMembershipCard: (data: IRegisterBusinessMembershipCardParam) => instance.post(`/corporate/membership`, data),
  getMembershipInfo: (id: string) => instance.get(`/corporate/membership/${id}`),
  editMembership: (data: IEditBusinessMembershipParam) => instance.patch(`/corporate/membership`, data),
  postResetCode: (id: string) => instance.post(`/corporate/membership/card-password/${id}`),
  postAuthCode: (data: IResetPasswordParam) => instance.post(`/corporate/membership/card-password/code`, data),
  editMembershipStatus: (data: IEditMembershipParam) =>
    instance.patch(`/corporate/membership/status/${data.id}/${data.cardStatus}`),
  editMembershipMember: (data: IEditMembershipMemberParam) => instance.post(`/corporate/membership/member`, data),
  deleteMembershipCard: (id: string) => instance.delete(`/corporate/membership/${id}`),
  deleteMembershipMember: (data: IEditMembershipMemberParam) =>
    instance.post(`/corporate/membership/member/delete`, data),
  addMembershipMember: (data: IEditMembershipMemberParam) => instance.post(`/corporate/membership/member/save`, data),
}
