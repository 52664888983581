import { useAppSelector } from '@src/hooks/redux'
import React from 'react'
import Modal from './Modal'
import dynamic from 'next/dynamic'
const ModalPortal = dynamic(() => import('./ModalPortal'), {
  ssr: false,
})
const RootModal = () => {
  const modals = useAppSelector((state) => state.modal)

  return (
    <ModalPortal>
      {modals.map((modal) => (
        <Modal key={modal.key} {...modal}></Modal>
      ))}
    </ModalPortal>
  )
}

export default RootModal
