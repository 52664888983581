import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { claimChargeApis } from '@src/api/management/claimCharge'
import { IClaimChargList, IClaimChargeParams } from '@src/type/management/claimCharge'

const initialState = {
  claimChargeList: {} as IClaimChargList,
}
export const claimChargeSlice = createSlice({
  name: 'claimCharge',
  initialState,
  reducers: {
    setClaimChargeList: (state, action) => {
      state.claimChargeList = action.payload
    },
  },
})
export const { setClaimChargeList } = claimChargeSlice.actions

export default claimChargeSlice.reducer

// -------------------------------------- //

export const getClaimChargeList = createAsyncThunk(
  '/management/claimChargeList/getClaimChargeList',
  async (data: IClaimChargeParams, thunkAPI) => {
    try {
      await claimChargeApis.getClaminChargeList(data).then((res) => {
        const data = res.data.result_data
        thunkAPI.dispatch(setClaimChargeList(data))
      })
    } catch (err) {}
  },
)
