/* 
회원 권한을 확인하는 함수
return type : boolean
*/
const checkUserRole = (userRole: string, allowedRoles: string[]) => allowedRoles.includes(userRole)

export const checkStaffAuth = (userRole: string) =>
  checkUserRole(userRole, ['STAFF', 'MANAGER', 'DIRECTOR', 'ADMINISTRATOR'])
export const checkManagerAuth = (userRole: string) => checkUserRole(userRole, ['MANAGER', 'DIRECTOR', 'ADMINISTRATOR'])
export const checkDirectorAuth = (userRole: string) => checkUserRole(userRole, ['DIRECTOR', 'ADMINISTRATOR'])
export const checkAdminAuth = (userRole: string) => checkUserRole(userRole, ['ADMINISTRATOR'])
export const checkUserAuth = (userRole: string) => checkUserRole(userRole, ['USER'])
