export declare namespace ICommonConfig {
  export type Mode = 'local' | 'development' | 'production'

  export interface Params {
    publicUrl: string
    baseUrl: string
    mode: Mode
    recaptcha: string
  }
}

export default function getConfigs(params: ICommonConfig.Params) {
  const { publicUrl, baseUrl, mode, recaptcha } = params

  return {
    publicUrl,
    baseUrl,
    mode,
    recaptcha,
  }
}
