import React from 'react'
import NewFooter from './newFooter/NewFooter'
import isFooterHidePage from '@src/utils/layout/isFooterHide'

const Footer = () => {
  const isHide = isFooterHidePage()
  if (isHide) {
    return null
  } else {
    return <NewFooter />
  }
}

export default Footer
