import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { paymentApis } from '@src/api/management/payment'
import { IBillingCardHistoryList, IBillingCardHistoryParams } from '@src/type/management/billingCardHistory'

const initialState = {
  billingCardHistoryList: {} as IBillingCardHistoryList,
}
export const billingCardHistorySlice = createSlice({
  name: 'billingCardHistroy',
  initialState,
  reducers: {
    setBillingCardHistoryList: (state, action) => {
      state.billingCardHistoryList = action.payload
    },
  },
})
export const { setBillingCardHistoryList } = billingCardHistorySlice.actions

export default billingCardHistorySlice.reducer

// -------------------------------------- //

export const getBillingCardHistoryList = createAsyncThunk(
  '/management/billingCardHistroy/getBillingCardHistory',
  async (data: IBillingCardHistoryParams, thunkAPI) => {
    try {
      await paymentApis.getBillingCardHistory(data).then((res) => {
        const data = res.data.result_data
        thunkAPI.dispatch(setBillingCardHistoryList(data))
      })
    } catch (err) {}
  },
)
