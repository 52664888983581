import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isHoverHeader: null,
  isRightSidebarOpen: false,
  scrollSidebarIconColor: '#fff',
}
export const commonsSlice = createSlice({
  name: 'commonsSlice',
  initialState,
  reducers: {
    setIsHoverHeader: (state, action) => {
      state.isHoverHeader = action.payload
    },
    setIsRightSidebarOpen: (state, action) => {
      state.isRightSidebarOpen = action.payload
    },
    setScrollSidebarIconColor: (state, action) => {
      state.scrollSidebarIconColor = action.payload
    },
  },
})
export const { setIsHoverHeader, setIsRightSidebarOpen, setScrollSidebarIconColor } = commonsSlice.actions
export default commonsSlice.reducer
