import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { managementApis } from '@src/api/management/managementCommon'
import { ILoginLogList, ILoginLogParams } from '@src/type/management/loginLog'

const initialState = {
  loginLogList: {} as ILoginLogList,
  loginLogListFilter: {
    page: 1,
    list: 15,
  } as ILoginLogParams,
}
export const loginLogSlice = createSlice({
  name: 'loginLog',
  initialState,
  reducers: {
    setLoginLogList: (state, action) => {
      state.loginLogList = action.payload
    },
    setLoginLogListFilter: (state, action) => {
      state.loginLogListFilter = action.payload
    },
  },
})
export const { setLoginLogList, setLoginLogListFilter } = loginLogSlice.actions

export default loginLogSlice.reducer

// -------------------------------------- //

export const getLoginLogList = createAsyncThunk(
  '/management/loginLog/getLoginLogList',
  async (data: ILoginLogParams, thunkAPI) => {
    try {
      await managementApis.getLoginLog(data).then((res) => {
        const data = res.data.result_data
        thunkAPI.dispatch(setLoginLogList(data))
      })
    } catch (err) {}
  },
)
