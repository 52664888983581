import instance from '../axios'
import { INoticeAddParam, INoticeClosedParam, INoticeEditParam, INoticeListParam } from '@src/type/customer/notice'

export const noticeApis = {
  getNoticeSubCategory: () => instance.get('/management/notice/sub-category'),
  // 조건에 맞춰 공지사항 리스트를 조회하는 api
  getNoticeList: (data: INoticeListParam) =>
    instance.get(
      `/management/notice/list?p=${data.page}` +
        `${data.category ? `&board_sub_category_id=${data.category}` : ''}` +
        `${!data.searchCategory ? '' : `&category=${data.searchCategory}`}` +
        `${data.query ? `&query=${data.query}` : ''}`,
    ),
  getNoticeDetail: (id: number) => instance.get(`/management/notice/select-list?id=${id}`),
  noticeAdd: (data: INoticeAddParam) => instance.post('/management/notice/add', data),
  noticeEdit: (data: INoticeEditParam) => instance.put('/management/notice/edit', data),
  noticeClosed: (data: INoticeClosedParam) => instance.put('/management/notice/edit', data),
}
