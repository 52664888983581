import { ICreateCardData, IMemberSearchParam } from '@src/type/memberType'
import instance from './axios'

export const memberApis = {
  memberList: (data: IMemberSearchParam) =>
    instance.get(`/management/member/list?p=${data.page}&list_num=${data.list}`),
  memberCategoryPaging: (page: number, role: string, list: string) =>
    instance.get(`/management/member/list?p=${page}&role=${role}&list_num=${list}`),
  memberSuspendPaging: (page: number, suspend: number, list: string) =>
    instance.get(`/management/member/list?p=${page}&suspend=${suspend}&list_num=${list}`),
  memberSearchCategoryList: (data: IMemberSearchParam) =>
    instance.get(
      `/management/member/list?query=${data.query}&category=${data.category}&role=${data.role}&p=${data.page}&list_num=${data.list}`,
    ),
  memberSearchSuspendList: (data: IMemberSearchParam) =>
    instance.get(
      `/management/member/list?query=${data.query}&category=${data.category}&suspend=${data.suspend}&p=${data.page}&list_num=${data.list}`,
    ),

  // 조건에 맞춰 사용자 리스트를 조회하는 api
  memberSearchList: (data: IMemberSearchParam) =>
    instance.get(
      `/management/member/list?p=${data.page}&list_num=${data.list}` +
        `${data.query ? `&query=${data.query}` : ''}` +
        `${data.policy1 ? `&policy1=${data.policy1}` : ''}` +
        `${data.policy2 ? `&policy2=${data.policy2}` : ''}` +
        `${data.category ? `&category=${data.category}` : ''}` +
        `${data.role ? `&role=${data.role}` : ''}` +
        `${data.suspend ? `&suspend=${data.suspend}` : ''}`,
    ),

  /* 정리 : 등급 수정 이용 정지 개선 시 any 삭제*/
  memberRating: (data: { modifyData: any }) => instance.put('/management/member/set-role', data.modifyData),
  memberSelect: (id: number) => instance.get(`/management/member/select?id=${id}`),
  membership: (data: any) => instance.put('/management/membership/setting', data),
  membershipSelect: (id: number) => instance.get(`/management/membership/select?id=${id}`),
  memberPersonalize: (data: { suspendedData: any }) =>
    instance.put('/management/member/set-suspend', data.suspendedData),
  memberSetPassword: (data: any) => instance.post('/management/member/set-password', data),
  createMembershipCard: (data: ICreateCardData) => instance.post('/management/membership/new', data),
}
