import { usePathname } from 'next/navigation'
import { useRouter } from 'next/router'

/* 
Footer가 숨겨지는 페이지인지 판단하는 함수 
return type : boolean
*/

const HIDE_PAGE = ['/business/management/payment/edit', '/user/security/activate', '/user/security/deactivate']

const isFooterHidePage = () => {
  const router = useRouter()
  const currentPath = router.asPath

  if (
    HIDE_PAGE.includes(currentPath) ||
    currentPath.includes('/business/membership') ||
    currentPath.includes('/payment/result') ||
    currentPath.includes('/business/join') ||
    currentPath.includes('/business/policy') ||
    currentPath.includes('/sign_up') ||
    currentPath.startsWith('/app') ||
    currentPath.includes('/management/paymentDetail') ||
    currentPath.includes('/account') ||
    currentPath.includes('/billing_card/result') ||
    currentPath.includes('/change-password') ||
    currentPath.includes('/confirm-email')
  )
    return true
  else {
    return false
  }
}

export default isFooterHidePage
