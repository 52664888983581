import { useAppSelector } from '@src/hooks/redux'
import { RootState } from '@src/store/modules'
import { getProgressCounslingCount, getProgressCounslingCountV2 } from '@src/store/modules/counseling'
import { getProgressInquiryCount } from '@src/store/modules/inquiry'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'

export const ManageSidebar = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  const router = useRouter()
  const SelectNav = router.asPath
  const [member, setMember] = useState(false)
  const [membership, setMembership] = useState(false)
  const [coupon, setCoupon] = useState(false)
  const newUserInfo = useAppSelector((state) => state.userInfo.userInfoMasking)
  const currentID = useSelector((state: RootState) => state.member.currentID)
  const memberCardID = useSelector((state: RootState) => state.member.memberCardID)
  const progressCounslingCount = useSelector((state: RootState) => state.counseling.progressCounslingCount)
  const progressCounslingCountV2 = useSelector((state: RootState) => state.counseling.progressCounslingCountV2)
  const progressInquiryCount = useSelector((state: RootState) => state.inquiry.progressInquiryCount)

  useEffect(() => {
    if (SelectNav === '/management/manageMembership') {
      setMembership(true)
      localStorage.setItem('wev.management.tab.membership', 'true')
    } else if (SelectNav.includes('coupon')) {
      setCoupon(true)
      localStorage.setItem('wev.management.tab.coupon', 'true')
    } else {
      setMember(true)
      localStorage.setItem('wev.management.tab.member', 'true')
    }
  }, [SelectNav])

  // 관리자가 화성화 시킨 탭 기억
  useEffect(() => {
    const memberTab = localStorage.getItem('wev.management.tab.member')
    const membershipTab = localStorage.getItem('wev.management.tab.membership')
    const couponTab = localStorage.getItem('wev.management.tab.coupon')
    setMember(memberTab === 'true' ? true : false)
    setMembership(membershipTab === 'true' ? true : false)
    setCoupon(couponTab === 'true' ? true : false)
    // 문의 관리, 상담 관리 관련 정보 fetch (후에 분리 필요)
    const data = {
      page: 1,
      answer: 0,
    }
    dispatch(getProgressCounslingCount(data))
    dispatch(getProgressCounslingCountV2())
    dispatch(getProgressInquiryCount(data))
  }, [])

  return newUserInfo.email_verify === 0 ? (
    <></>
  ) : newUserInfo.email_verify === 1 ? (
    <div className="hidden lg:flex">
      <main className="w-[18rem] bg-gray-50 mx-auto px-6">
        <aside className="self-start sticky top-28">
          <div className="py-6 items-center w-full">
            <span className="flex items-center text-gray-900 text-2xl font-semibold justify-center">EL ELECTRIC</span>
          </div>

          <nav className="text-white text-base font-semibold pt-3 w-full flex flex-col">
            <div
              onClick={() => {
                setMember((prev) => !prev)
                localStorage.setItem('wev.management.tab.member', String(!member))
              }}
              className="w-full text-gray-800 text-base font-semibold opacity-75 py-4 rounded-lg px-2 inline-flex items-center justify-between hover:cursor-pointer"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path
                  fillRule="evenodd"
                  d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
                  clipRule="evenodd"
                />
              </svg>
              사용자 관리
              {member ? (
                <svg
                  className="ml-2 w-4 h-4"
                  aria-hidden="true"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ transform: 'scaleY(-1)' }}
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                </svg>
              ) : (
                <svg
                  className="ml-2 w-4 h-4"
                  aria-hidden="true"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                </svg>
              )}
            </div>
            {member === true ? (
              <>
                <Link href="/management/main">
                  <span
                    className={
                      'text-sm flex items-center justify-end text-gray-500 opacity-75 hover:opacity-100 pt-1 px-6 hover:cursor-pointer' +
                      (SelectNav === '/management/main' ||
                      SelectNav === `/management/selectMember/${currentID}` ||
                      SelectNav === `/management/modify/${memberCardID}`
                        ? ' text-purple-600'
                        : ' ')
                    }
                  >
                    회원 조회
                  </span>
                </Link>

                <Link href="/management/paymentHistory">
                  <span
                    className={
                      'text-sm flex items-center justify-end text-gray-500 opacity-75 hover:opacity-100 pt-1 px-6 hover:cursor-pointer' +
                      (SelectNav.includes('/kakaoPaymentHistory') ||
                      SelectNav === '/management/paymentHistory' ||
                      SelectNav.includes(`/management/paymentHistory/`)
                        ? ' text-purple-600'
                        : ' ')
                    }
                  >
                    거래 내역 조회
                  </span>
                </Link>
                <Link href="/management/naverPaymentHistory">
                  <span
                    className={
                      'text-sm flex items-center justify-end text-gray-500 opacity-75 hover:opacity-100 pt-1 px-6 hover:cursor-pointer' +
                      (SelectNav === '/management/naverPaymentHistory' ||
                      SelectNav.includes(`/management/naverPaymentHistory/`)
                        ? ' text-purple-600'
                        : ' ')
                    }
                  >
                    네이버페이 결제 내역 조회
                  </span>
                </Link>
                <Link href="/management/claimCharge">
                  <span
                    className={
                      'text-sm flex items-center justify-end text-gray-500 opacity-75 hover:opacity-100 pt-1 px-6 hover:cursor-pointer' +
                      (SelectNav === '/management/claimCharge' ? ' text-purple-600' : ' ')
                    }
                  >
                    미수금 조회
                  </span>
                </Link>
                <Link href="/management/guestPayment">
                  <span
                    className={
                      'text-sm flex items-center justify-end text-gray-500 opacity-75 hover:opacity-100 pt-1 px-6 hover:cursor-pointer' +
                      (SelectNav === '/management/guestPayment' ? ' text-purple-600' : ' ')
                    }
                  >
                    미충전 결제금 조회
                  </span>
                </Link>
                <Link href="/management/billingCardHistory">
                  <span
                    className={
                      'text-sm flex items-center justify-end text-gray-500 opacity-75 hover:opacity-100 pt-1 px-6 hover:cursor-pointer' +
                      (SelectNav === '/management/billingCardHistory' ? ' text-purple-600' : ' ')
                    }
                  >
                    카드 등록 내역 조회
                  </span>
                </Link>
                <Link href="/management/employee">
                  <span
                    className={
                      'text-sm flex items-center justify-end text-gray-500 opacity-75 hover:opacity-100 pt-1 px-6 hover:cursor-pointer' +
                      (SelectNav === '/management/employee' ? ' text-purple-600' : ' ')
                    }
                  >
                    임직원 요금 감면
                  </span>
                </Link>
                <Link href="/management/loginLog">
                  <span
                    className={
                      'text-sm flex items-center justify-end text-gray-500 opacity-75 hover:opacity-100 pt-1 px-6 hover:cursor-pointer' +
                      (SelectNav === '/management/loginLog' ? ' text-purple-600' : ' ')
                    }
                  >
                    로그인 기록 조회
                  </span>
                </Link>
                <Link href="/management/withDrawMember">
                  <span
                    className={
                      'text-sm flex items-center justify-end text-gray-500 opacity-75 hover:opacity-100 pt-1 px-6 hover:cursor-pointer' +
                      (SelectNav === '/management/withDrawMember' ? ' text-purple-600' : ' ')
                    }
                  >
                    회원 탈퇴 조회
                  </span>
                </Link>
              </>
            ) : (
              <></>
            )}

            <div
              onClick={() => {
                setMembership((prev) => !prev)
                localStorage.setItem('wev.management.tab.membership', String(!membership))
              }}
              className="w-full text-gray-800 text-base font-semibold opacity-75 py-4 rounded-lg px-2 inline-flex items-center justify-between hover:cursor-pointer mt-3"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 text-gray-800"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                />
              </svg>
              멤버십 관리
              {membership ? (
                <svg
                  className="ml-2 w-4 h-4"
                  aria-hidden="true"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ transform: 'scaleY(-1)' }}
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                </svg>
              ) : (
                <svg
                  className="ml-2 w-4 h-4"
                  aria-hidden="true"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                </svg>
              )}
            </div>
            {membership === true ? (
              <>
                <Link href="/management/manageMembership">
                  <span
                    className={
                      'text-sm flex items-center justify-end text-gray-500 opacity-75 hover:opacity-100 pt-1 px-6 hover:cursor-pointer' +
                      (SelectNav === '/management/manageMembership' ||
                      SelectNav === `/management/modify/manageMembership/${currentID}`
                        ? ' text-purple-600'
                        : ' ')
                    }
                  >
                    멤버십 조회
                  </span>
                </Link>
              </>
            ) : (
              <></>
            )}

            <div
              onClick={() => {
                setCoupon((prev) => !prev)
                localStorage.setItem('wev.management.tab.coupon', String(!coupon))
              }}
              className="w-full text-gray-800 text-base font-semibold opacity-75 py-4 rounded-lg px-2 inline-flex items-center justify-between hover:cursor-pointer mt-3"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 text-gray-800"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
                />
              </svg>
              쿠폰 관리
              {coupon ? (
                <svg
                  className="ml-2 w-4 h-4"
                  aria-hidden="true"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ transform: 'scaleY(-1)' }}
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                </svg>
              ) : (
                <svg
                  className="ml-2 w-4 h-4"
                  aria-hidden="true"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                </svg>
              )}
            </div>
            {coupon === true ? (
              <>
                <Link href="/management/coupon/create">
                  <span
                    className={
                      'text-sm flex items-center justify-end text-gray-500 opacity-75 hover:opacity-100 pt-1 px-6 hover:cursor-pointer' +
                      (SelectNav === '/management/coupon/create' ? ' text-purple-600' : ' ')
                    }
                  >
                    쿠폰 발행
                  </span>
                </Link>
                <Link href="/management/coupon/list">
                  <span
                    className={
                      'text-sm flex items-center justify-end text-gray-500 opacity-75 hover:opacity-100 pt-1 px-6 hover:cursor-pointer' +
                      (SelectNav === '/management/coupon/list' ? ' text-purple-600' : ' ')
                    }
                  >
                    쿠폰 조회
                  </span>
                </Link>
                <Link href="/management/coupon/couponHistory">
                  <span
                    className={
                      'text-sm flex items-center justify-end text-gray-500 opacity-75 hover:opacity-100 pt-1 px-6 hover:cursor-pointer' +
                      (SelectNav === '/management/coupon/couponHistory' ? ' text-purple-600' : ' ')
                    }
                  >
                    쿠폰 사용 내역 조회
                  </span>
                </Link>
                <Link href="/management/coupon/pointHistory">
                  <span
                    className={
                      'text-sm flex items-center justify-end text-gray-500 opacity-75 hover:opacity-100 pt-1 px-6 hover:cursor-pointer' +
                      (SelectNav === '/management/coupon/pointHistory' ? ' text-purple-600' : ' ')
                    }
                  >
                    포인트 사용 내역 조회
                  </span>
                </Link>
              </>
            ) : (
              <></>
            )}

            <div
              onClick={() => router.push('/management/inquiry')}
              className={
                'w-full text-gray-800 text-base font-semibold opacity-75 py-4 rounded-lg px-2 inline-flex items-center hover:cursor-pointer mt-3' +
                (SelectNav === '/management/inquiry' ? ' text-purple-600' : ' ')
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 text-gray-800 mr-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                />
              </svg>
              문의 관리{' '}
              {progressInquiryCount > 0 ? (
                <div className="w-[7px] h-[7px] rounded-full bg-red-600 ml-3 -translate-x-2.5 -translate-y-2"></div>
              ) : (
                <></>
              )}
            </div>

            <div
              onClick={() => router.push('/management/counseling_v2')}
              className={
                'w-full text-gray-800 text-base font-semibold opacity-75 py-4 rounded-lg px-2 inline-flex items-center hover:cursor-pointer mt-3' +
                (SelectNav === '/management/counseling_v2' ? ' text-purple-600' : ' ')
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 text-gray-800 mr-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z"
                />
              </svg>
              상담 관리 V2{' '}
              {progressCounslingCountV2 > 0 ? (
                <div className="w-[7px] h-[7px] rounded-full bg-red-600 ml-3 -translate-x-2.5 -translate-y-2"></div>
              ) : (
                <></>
              )}
            </div>

            <div
              onClick={() => router.push('/management/counseling')}
              className={
                'w-full text-gray-800 text-base font-semibold opacity-75 py-4 rounded-lg px-2 inline-flex items-center hover:cursor-pointer mt-3' +
                (SelectNav === '/management/counseling' ? ' text-purple-600' : ' ')
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 text-gray-800 mr-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z"
                />
              </svg>
              상담 관리{' '}
              {progressCounslingCount > 0 ? (
                <div className="w-[7px] h-[7px] rounded-full bg-red-600 ml-3 -translate-x-2.5 -translate-y-2"></div>
              ) : (
                <></>
              )}
            </div>

            <div
              onClick={() => router.push('/management/board/new')}
              className={
                'w-full text-gray-800 text-base font-semibold opacity-75 py-4 rounded-lg px-2 inline-flex items-center hover:cursor-pointer mt-3' +
                (SelectNav === '/management/certification' ? ' text-purple-600' : ' ')
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#303944"
                stroke="#303944"
                strokeWidth="6"
                className="mr-6"
              >
                <path d="M212.31-140q-29.83 0-51.07-21.24Q140-182.48 140-212.31v-535.38q0-29.83 21.24-51.07Q182.48-820 212.31-820h176.23q8.31-29.23 33.96-48.46t57.5-19.23q33.08 0 58.42 19.23 25.35 19.23 33.66 48.46h175.61q29.83 0 51.07 21.24Q820-777.52 820-747.69v535.38q0 29.83-21.24 51.07Q777.52-140 747.69-140H212.31Zm0-60h535.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46v-535.38q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H660v68.46q0 15.37-10.35 25.76-10.35 10.39-25.65 10.39H335.99q-15.3 0-25.64-10.39Q300-676.17 300-691.54V-760h-87.69q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v535.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85Zm267.76-555.38q15.39 0 25.74-10.42 10.34-10.41 10.34-25.81 0-15.39-10.41-25.74-10.41-10.34-25.81-10.34-15.39 0-25.74 10.41-10.34 10.42-10.34 25.81 0 15.39 10.41 25.74 10.41 10.35 25.81 10.35Z" />
              </svg>
              게시글 등록
            </div>

            <div
              onClick={() => router.push('/management/certification')}
              className={
                'w-full text-gray-800 text-base font-semibold opacity-75 py-4 rounded-lg px-2 inline-flex items-center hover:cursor-pointer mt-3' +
                (SelectNav === '/management/certification' ? ' text-purple-600' : ' ')
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 text-gray-800 mr-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                />
              </svg>
              인증서 관리
            </div>
          </nav>
        </aside>
      </main>
    </div>
  ) : (
    <></>
  )
}
