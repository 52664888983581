import styled from 'styled-components'

export const ButtonPosition = styled.div`
  position: fixed;
  bottom: 80px;
  right: 6rem;
  z-index: 200;
  @media ${({ theme }) => theme.device.pc} {
    right: 4rem;
  }
  @media ${({ theme }) => theme.device.mobile} {
    right: 20px;
    bottom: 30px;
  }
`
export const UpButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 90%;
  border: 1px solid ${({ theme }) => theme.wev_color.outline};
  background-color: white;
  width: 54px;
  height: 54px;

  :hover {
    border: 1px solid ${({ theme }) => theme.wev_color.text};
    box-shadow: 0px 0px 4px 0px #00000026;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 36px;
    height: 36px;
  }
`
