export const GA_TRACKING_ID_1 = process.env.NEXT_PUBLIC_ANALYTICS_1
export const GA_TRACKING_ID_2 = process.env.NEXT_PUBLIC_ANALYTICS_2

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
// export const pageview_1 = (url) => {
//   if (typeof window.gtag !== 'undefined') {
//     window.gtag('config', GA_TRACKING_ID_1, {
//       page_path: url,
//     })
//   }
// }

//for dev
export const pageview_2 = (url) => {
  if (typeof window.gtag !== 'undefined') {
    window.gtag('config', GA_TRACKING_ID_2, {
      page_path: url,
    })
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  })
}
