const deviceSizes = {
  mobile: '768px',
  // tablet: '768px',
  pc: '1280px',
}

export const device = {
  mobile: `screen and (max-width: ${deviceSizes.mobile})`,
  // tablet: `screen and (max-width: ${deviceSizes.tablet})`,
  pc: `screen and (max-width: ${deviceSizes.pc})`,
}
