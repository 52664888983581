import { Breakpoint, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

export const useResponsive = (breakpoint: Breakpoint | number) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(
    typeof breakpoint === 'number' ? `(max-width: ${breakpoint}px)` : theme.breakpoints.down(breakpoint),
  )
  const isTablet = useMediaQuery(
    typeof breakpoint === 'number' ? `(max-width: ${breakpoint}px)` : theme.breakpoints.down(breakpoint),
  )

  return { isMobile, isTablet }
}
