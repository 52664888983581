import React from 'react'
import styled from 'styled-components'

interface IProps {
  close?: () => void
  component?: () => JSX.Element
}
const Modal = ({ component, close }: IProps) => {
  return (
    <div>
      <Overlay>
        <ModalWrapper>{component && component()}</ModalWrapper>
      </Overlay>
    </div>
  )
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0000004d;
  z-index: 9999;
`

const ModalWrapper = styled.div`
  padding: 30px;
  width: 500px;
  height: fit-content;
  border-radius: 15px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 40px 3px #00000026;
  @media ${({ theme }) => theme.device.mobile} {
    width: calc(100% - 40px);
    padding: 20px;
  }
`

export default Modal
