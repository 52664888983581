import React, { useEffect, useState } from 'react'
import Image from 'next/legacy/image'
import { useRouter } from 'next/router'
import { useAppDispatch, useAppSelector } from '@src/hooks/redux'
import Link from 'next/link'
import { useResponsive } from '@src/hooks/useResponsive'
import { setIsHoverHeader } from '@src/store/modules/commons'
import { useTranslation } from 'next-i18next'
import AuthMenu from './AuthMenu'
import checkAccessToken from '@src/utils/auth/checkAccessToken'
import ActiveHeader from './activeHeader/ActiveHeader'
import { routeBgColorCheck } from '@src/utils/routeBgColorCheck'
import { getUserMaskingInfo } from '@src/store/modules/user/info'
import * as S from './Header.styled'

const Header = () => {
  const router = useRouter()
  const isHoverHeader = useAppSelector((state) => state.commons.isHoverHeader)
  const { isTablet } = useResponsive(1280)
  const { isMobile } = useResponsive(768)
  const hasAccessToken = checkAccessToken()
  const dispatch = useAppDispatch()
  const { t } = useTranslation('layout')

  useEffect(() => {
    if (hasAccessToken) {
      if (
        router.asPath === '/login' ||
        router.asPath.includes('/business') ||
        (router.asPath.indexOf('/sign_up') !== -1 && !router.asPath.includes('emailConfirm')) ||
        (!hasAccessToken && !router.asPath.includes('emailConfirm')) ||
        (router.asPath.includes('management') && router.asPath.includes('/paymentDetail'))
      ) {
        return
      } else {
        dispatch(getUserMaskingInfo())
      }
    }
  }, [router.asPath])

  useEffect(() => {
    if (isHoverHeader) {
      if (document.body.style.overflow !== 'hidden') {
        document.body.style.overflow = 'hidden'
      }
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [isHoverHeader])

  return (
    <header
      className={`w-[100%] ${isMobile ? 'h-[54px] absolute top-0' : ''} 
    ${router.asPath === '/' ? ' bg-transparent' : ' bg-[#ffffff]'}`}
    >
      <S.HeaderLayout
        $isHeaderHovered={Boolean(isHoverHeader)}
        $borderColor={router.pathname === '/' ? 'transparent' : '#ededed'}
      >
        <div className="header_content_box">
          {isHoverHeader && !isTablet ? (
            <Link href={'/'}>
              <Image
                src="/images/wev/wev_logo_gradient.png"
                alt="El Electric Logo"
                width={isMobile ? 80 : 146}
                height={isMobile ? 20 : 37}
              />
            </Link>
          ) : (!isMobile && router.asPath === '/wev') || (!isMobile && router.asPath === '/wev/partnership') ? (
            <Link href={'/'}>
              <Image src="/images/wev/wev_logo_white.png" alt="El Electric Logo" width={146} height={37} />
            </Link>
          ) : (
            <Link href={'/'}>
              <Image
                src="/images/wev/wev_logo_gradient.png"
                alt="El Electric Logo"
                width={isMobile ? 80 : 146}
                height={isMobile ? 20 : 37}
              />
            </Link>
          )}
          {!isTablet && (
            <S.HeaderNav $isHeaderHovered={Boolean(isHoverHeader)} $textColor={routeBgColorCheck(router.asPath)}>
              <ul id="nav_menu">
                <S.NavItem
                  $isHeaderHovered={isHoverHeader === 'wev'}
                  onMouseEnter={() => dispatch(setIsHoverHeader('true'))}
                  onMouseLeave={() => dispatch(setIsHoverHeader(null))}
                >
                  {t('header.WEV')}
                </S.NavItem>

                <S.NavItem
                  $isHeaderHovered={isHoverHeader === 'service'}
                  onMouseEnter={() => dispatch(setIsHoverHeader('true'))}
                  onMouseLeave={() => dispatch(setIsHoverHeader(null))}
                >
                  {t('header.WEV')} {t('header.서비스')}
                </S.NavItem>

                <S.NavItem
                  $isHeaderHovered={isHoverHeader === 'product'}
                  onMouseEnter={() => dispatch(setIsHoverHeader('true'))}
                  onMouseLeave={() => dispatch(setIsHoverHeader(null))}
                >
                  {t('header.제품')}
                </S.NavItem>

                <S.NavItem
                  $isHeaderHovered={isHoverHeader === 'consult'}
                  onMouseEnter={() => dispatch(setIsHoverHeader('true'))}
                  onMouseLeave={() => dispatch(setIsHoverHeader(null))}
                >
                  {t('header.설치 신청')}
                </S.NavItem>

                <S.NavItem
                  $isHeaderHovered={isHoverHeader === 'partnership'}
                  onMouseEnter={() => dispatch(setIsHoverHeader('true'))}
                  onMouseLeave={() => dispatch(setIsHoverHeader(null))}
                >
                  {t('header.파트너십')}
                </S.NavItem>

                <S.NavItem
                  $isHeaderHovered={isHoverHeader === 'news'}
                  onMouseEnter={() => dispatch(setIsHoverHeader('true'))}
                  onMouseLeave={() => dispatch(setIsHoverHeader(null))}
                >
                  {t('header.WEV')} {t('header.소식')}
                </S.NavItem>
              </ul>
            </S.HeaderNav>
          )}
          {isTablet ? null : <AuthMenu isUserLoggedIn={hasAccessToken} />}
        </div>
      </S.HeaderLayout>
      <ActiveHeader />
    </header>
  )
}

export default Header
