import { useRouter } from 'next/router'
import { useResponsive } from '@src/hooks/useResponsive'
import isSideBarHidePage from '@src/utils/layout/isSideBarHide'
import { ManageSidebar } from './manageSidebar/ManageSidebar'
import UserSidebar from './userSidebar/UserSidebar'
import BusinessSidebar from './businessSidebar/BusinessSidebar'
import TermSidebar from './termSidebar/TermSidebar'
import { useAppSelector } from '@src/hooks/redux'
import checkAccessToken from '@src/utils/auth/checkAccessToken'

const SideBar = () => {
  const { asPath } = useRouter()
  const { isTablet } = useResponsive(1280)
  const hasAccessToken = checkAccessToken()
  const isHide = isSideBarHidePage()

  const renderSidebar = () => {
    if (hasAccessToken && asPath.startsWith('/user')) {
      return <UserSidebar />
    } else if (hasAccessToken && asPath.startsWith('/business')) {
      return <BusinessSidebar />
    } else if (hasAccessToken && asPath.startsWith('/management')) {
      return <ManageSidebar />
    } else if (asPath.startsWith('/terms') && !asPath.includes('mobile')) {
      return <TermSidebar />
    } else {
      return null
    }
  }

  if (isHide || isTablet) {
    return null
  }

  return <>{renderSidebar()}</>
}

export default SideBar
