import { Grow, Slide } from '@mui/material'
import { CgClose } from 'react-icons/cg'
import { IoIosMenu } from 'react-icons/io'
import { Language } from '@src/assets/icons'
import useRightSidebar from '@src/hooks/useRightSidebar'
import LanguageSettings from './languageSettings/LanguageSettings'
import PrimarySearch from './primarySearch/PrimarySearch'
import SiteMap from './siteMap/SiteMap'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useResponsive } from '@src/hooks/useResponsive'
import * as S from './RightSidebar.styled'

const RightSidebar = () => {
  const { asPath } = useRouter()
  const {
    isSidebarOpen,
    isPrimarySearchOpen,
    iconColor,
    isTablet,
    anchorEl,
    isHoverHeader,
    toggleSidebar,
    handleClickLanguageSettings,
    handleClickPrimarySearch,
    closeSidebar,
  } = useRightSidebar()
  const [showSiteMap, setShowSiteMap] = useState(false)
  const { isMobile } = useResponsive(768)
  useEffect(() => {
    if (isSidebarOpen) {
      const timer = setTimeout(() => {
        setShowSiteMap(true)
      }, 300)
      return () => clearTimeout(timer)
    } else {
      setShowSiteMap(false)
    }
  }, [isSidebarOpen])

  return (
    <>
      {isSidebarOpen && <S.BackDrop />}
      <Slide direction="left" in={isSidebarOpen} mountOnEnter unmountOnExit>
        <S.RightSidebarContent isOpen={isSidebarOpen}>
          {isTablet ? (
            <div className="w-full h-full relative overflow-y-auto">
              <button onClick={toggleSidebar} className="absolute right-3 top-5 w-[24px] h-[24px]">
                <CgClose color="white" fontSize={'1.2rem'} />
              </button>
              <SiteMap handleClickSitemapItem={closeSidebar} />
            </div>
          ) : (
            <Grow in={showSiteMap} timeout={800}>
              <div className="w-full h-full relative">
                <button onClick={toggleSidebar} className="absolute right-20 top-20">
                  <CgClose color="white" fontSize={'2rem'} />
                </button>
                <SiteMap handleClickSitemapItem={closeSidebar} />
              </div>
            </Grow>
          )}
        </S.RightSidebarContent>
      </Slide>

      {/* {isPrimarySearchOpen ? <PrimarySearch onClose={() => handleClickPrimarySearch(false)} /> : null} */}
      <S.Layout
        isHeaderHovered={Boolean(isHoverHeader)}
        isTablet={isTablet}
        isSidebarOpen={isSidebarOpen}
        isMain={asPath === '/' || asPath.startsWith('/#')}
      >
        <div id="rightSidebarContent">
          <button id="menuIcon" onClick={toggleSidebar}>
            <IoIosMenu color={iconColor} fontSize={isMobile ? '28px' : isTablet ? '2rem' : '2.3rem'} />
          </button>
          {isTablet ? null : (
            <>
              {/* <button onClick={() => handleClickPrimarySearch(true)}>
                <Search fill={iconColor} width={40} height={40} />
              </button> */}
              <button onClick={handleClickLanguageSettings}>
                <Language fill={iconColor} width={40} height={40} />
              </button>
            </>
          )}
          <LanguageSettings
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            handleClose={() => handleClickLanguageSettings(null)}
          />
        </div>
      </S.Layout>
    </>
  )
}

export default RightSidebar
