import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { BusinessSettlementApis } from '@src/api/business/settlement'
import {
  ISettlementList,
  ISettlementMain,
  ISettlementListParams,
  ISettlementChargeTax,
  ISettlementChargeCard,
  ISettlementMembershipCard,
  ISettlementMembershipTax,
  ISettlementFilter,
} from '@src/type/business/businessSettlement'

const initialState = {
  businessSettlementMain: {} as ISettlementMain,
  businessSettlementList: {} as ISettlementList,
  businessSettlementInfo: {} as
    | ISettlementChargeCard
    | ISettlementChargeTax
    | ISettlementMembershipCard
    | ISettlementMembershipTax,
  businessSettlementFilter: {
    page: 1,
    recordSize: 15,
    pageSize: 5,
    keyword: '',
    status: null,
    startDate: '',
    endDate: '',
    minChargingAmount: 0,
    maxChargingAmount: 0,
  } as ISettlementFilter,
}

export const BusinessSettlementSlice = createSlice({
  name: 'businessSettlement',
  initialState,
  reducers: {
    setBusinessSettlementMain: (state, action) => {
      state.businessSettlementMain = action.payload
    },
    setBusinessSettlementList: (state, action) => {
      state.businessSettlementList = action.payload
    },
    setBusinessSettlementInfo: (state, action) => {
      state.businessSettlementInfo = action.payload
    },
    setBusinessSettlementFilter: (state, action) => {
      state.businessSettlementFilter = action.payload
    },
  },
})

export const {
  setBusinessSettlementMain,
  setBusinessSettlementList,
  setBusinessSettlementInfo,
  setBusinessSettlementFilter,
} = BusinessSettlementSlice.actions

export default BusinessSettlementSlice.reducer

export const getSettlementMain = createAsyncThunk(
  '/business/menagement/settlement/getSettlementMain',
  async (data, thunkAPI) => {
    try {
      await BusinessSettlementApis.getSettlementMain().then((res) => {
        if (res.data.result_code === 'OK') {
          const data = {
            thisMonth: res.data.result_data.thisMonth,
            lastMonth: res.data.result_data.lastMonth,
          }
          thunkAPI.dispatch(setBusinessSettlementMain(data))
        }
      })
    } catch (err) {}
  },
)

export const getSettlementList = createAsyncThunk(
  '/business/menagement/settlement/getSettlementList',
  async (data: ISettlementListParams, thunkAPI) => {
    try {
      await BusinessSettlementApis.getSettlementList(data).then((res) => {
        if (res.data.result_code === 'OK') {
          const resultData = res.data.result_data
          thunkAPI.dispatch(setBusinessSettlementList(resultData))
        }
      })
    } catch (err) {}
  },
)

export const getSettlementInfo = createAsyncThunk(
  '/business/menagement/settlement/getSettlementInfo',
  async (data: string, thunkAPI) => {
    try {
      await BusinessSettlementApis.getSettlementInfo(data).then((res) => {
        if (res.data.result_code === 'OK') {
          const resultData = res.data.result_data
          thunkAPI.dispatch(setBusinessSettlementInfo(resultData))
        }
      })
    } catch (err) {}
  },
)
