import {
  IAddMemeberParam,
  IBusinessMemberListParam,
  IBusinessMembershipListParam,
  IEditBusinessMembershipParam,
  IEditMemeberParam,
  IRegisterOneMemeberParam,
} from '@src/type/business'
import instance from '../axios'

export const BusinessMemberApis = {
  getMemberList: (data: IBusinessMemberListParam) => instance.post(`/corporate/member/list`, data),
  registerOneMember: (data: IRegisterOneMemeberParam) => instance.post(`/corporate/member`, data),
  deleteMembers: (data: number[]) => instance.post(`/corporate/member/delete`, data),
  getMemberInfo: (id: string) => instance.get(`/corporate/member/${id}`),
  deleteMember: (id: string) => instance.delete(`/corporate/member/${id}`),
  editMember: (data: IEditMemeberParam) => instance.patch(`/corporate/member`, data),
  setMemberShip: (data: IAddMemeberParam) => instance.post(`/corporate/member/membership`, data),
  confirmMailSend: (id: string) => instance.get(`/corporate/member/email/${id}`),
  confirmInvite: (uid: string) => instance.patch(`/corporate/auth/invite/${uid}`),
  getMembershipModalList: (data: IBusinessMembershipListParam) =>
    instance.post(`/corporate/member/membership/modal`, data),
  editMembership: (data: IEditBusinessMembershipParam) => instance.post(`/corporate/member/membership`, data),
  editMemberAdmin: (data: string) => instance.put(`/corporate/member/${data}`),
}
