import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { couponApis } from '@src/api/manageCouponApi'
import { CouponList } from '@src/pages/management/coupon/list'

export interface CreateCoupon {
  type?: number
  code?: string
  couponPoint?: string
  expiredAt?: string
  couponIssueNumber?: string
  useAfterPeriod: string | undefined
  codeType?: number
  codeLength?: number
}

const initialState = {
  couponList: [],
  couponListPaging: {},
  couponTotalAmount: 0,
  currentAllPage: null,
}

export interface CouponListPaging {
  currentPage: number
  endPage: number
  nowBlock: number
  startPage: string
  totalBlock: number
  totalPage: number
}

export const getCouponList = createAsyncThunk('/coupon/list', async (data: CouponList, thunkAPI) => {
  try {
    await couponApis.couponAllList(data).then((res) => {
      thunkAPI.dispatch(setCouponList(res.data?.result_data.records))
      thunkAPI.dispatch(setTotalSize(res.data?.result_data.total_size))

      const couponListPagingData: CouponListPaging = {
        currentPage: res.data.result_data.current_page,
        endPage: res.data.result_data.end_page,
        nowBlock: res.data.result_data.now_block,
        startPage: res.data.result_data.start_page,
        totalBlock: res.data.result_data.total_block,
        totalPage: res.data.result_data.total_page,
      }

      thunkAPI.dispatch(setCouponPaging(couponListPagingData))
    })
  } catch (err) {}
})

export const CouponSlice = createSlice({
  name: 'coupon',
  initialState,
  reducers: {
    setCouponList: (state, action) => {
      state.couponList = action.payload
    },
    setTotalSize: (state, action) => {
      state.couponTotalAmount = action.payload
    },
    setCouponPaging: (state, action) => {
      state.couponListPaging = action.payload
    },
    setCurrentAllPage: (state, action) => {
      state.currentAllPage = action.payload
    },
  },
})

export const { setCouponList, setTotalSize, setCouponPaging, setCurrentAllPage } = CouponSlice.actions

export default CouponSlice.reducer
